import React from 'react';
import { dataUIWrapper } from '../../../../../../Common/DataUIWrapper/dataUIWrapper.js';
import { NewAgeFormBuilder } from '../../../../../../Common/ApplicationFormBuilder/FormBuilder/NewAgeFormBuilder.js';
import { NewAgeFormConfig } from '../../formJSON/newAgeConfig.js';
import { PageHeader } from '../../../../../../Common/Components/PageHeader/PageHeader.js';
import CreateDispatchers from '../../Utils/dispatchers/CreateDispatchers.js';
import { RMTabs } from '../../../../../../Common/Components/WrappedComponents/Tabs/RMTabs.js';
import governanceTabsData from '../../Utils/commonData/governanceTabsData.js';
import getPersonResponsible from '../../Utils/fetchers/getPersonResponsible.js';
import { ListGrid } from '../../../../../../Common/Components/ListGrid/ListGrid.js';
import getMasterList from '../../Utils/dispatchers/getMasterList.js';
import { AppDownloadIcon } from '../../../../../../Common/Icons/AppDownloadIcon.js';
import downloadFile from '../../Utils/dispatchers/downloadFile.js';
import downloadBlob from '../../../../../../Common/Utils/downloadBlob.js';
import deleteDispatchers from '../../Utils/dispatchers/deleteDispatchers.js';
import { dateValueParser } from '../../../../../../Common/ApplicationFormBuilder/Parsers/dateValueParser.js';



const GovernanceCreate = function(props){

	const [value, setValue] = React.useState({});
	const [componentExts, setComponentExts] = React.useState({});
	const [tabSelected, setTabSelected] = React.useState(governanceTabsData[0]);
	const [fileListData, setFileListData] = React.useState([]);

	const {dispatchRequest, responsible} = props;

	const getFiles = React.useCallback(function(module){
		dispatchRequest('getMasterList',{module: module}).then(function(list){
			setFileListData(list.data);
		})
	},[])

	const _handleSave = React.useCallback(function(_value){
		dispatchRequest('create', {data: _value} ).then(function(){
			getFiles(tabSelected.value);
		});
	},[dispatchRequest, tabSelected]);

	React.useEffect(function(){
		if(tabSelected){
			var itemTypes = tabSelected.itemTypes;
			setComponentExts((_ogExts)=>{
				return ({
					..._ogExts,
					'item_type': {
						data: itemTypes
					}
				});
			});

			setValue({
				module: tabSelected.value
			})

			getFiles(tabSelected.value);
		}
	},[tabSelected, getFiles]);

	React.useEffect(function(){
		if(responsible){
			setComponentExts((_ogExts)=>{
				return ({
					..._ogExts,
					'person_responsible': {
						data: responsible
					}
				});
			})
		}
	},[responsible]);

	const handleDelete = React.useCallback(function(row){
		dispatchRequest('delete', {
            data: {
				"config_name": 'governance',
				"module": tabSelected.value,
				"itemname": row.itemname,
				"item_type": row.item_type
			}
		} ).then(function(){
			getFiles(tabSelected.value);
		});
	},[dispatchRequest, tabSelected]);

	const _handleDownload = React.useCallback(function(props){
		const {row} = props;
		dispatchRequest('download', {
            responseType: 'blob',
			data: {
				"config_name": 'governance',
				"module": tabSelected.value,
				"itemname": row.itemname,
				"item_type": row.item_type
			}
		} ).then(function(res){
			downloadBlob(row.itemname, res.data, downloadFile.url);
		});
	},[dispatchRequest, tabSelected]);

	const customIconRenderer = React.useCallback(function(props){
		return (<AppDownloadIcon onClick={()=>_handleDownload(props)}></AppDownloadIcon>)
	},[])


	return (<PageHeader
					title={'Create Governance'}
					previousLinks={[]}
					currentPage={'Create'}
				>
				<div className="app-flex app-full-overflow new-age-form-grid-auto-height">
					<RMTabs data={governanceTabsData} selected={tabSelected} onSelect={setTabSelected}/>
					
					<NewAgeFormBuilder 
						config={NewAgeFormConfig} 
						value={value}
						onChange={setValue}
						dispatchRequest={props.dispatchRequest}
						onSave={_handleSave}
						componentExts={componentExts}
						onUpdateComponentExts={setComponentExts}
					/>
					<div className="app-grid-container">
						<ListGrid
							data={fileListData}
							nameField={"itemname"}
							nameTitle={"Document Name"}
							editList={[]}
							deleteList={["Rejected"]}
							onDelete={handleDelete}
							customIconRenderer={customIconRenderer}
							viewUrl=""
							editUrl=""
							columns={[
								{ field: 'item_type', headerName: 'Item Type', width: 280, index: 1},
								{ field: 'iteminfo', headerName: 'Description', width: 280, index: 2},
								{ field: 'due_date', headerName: 'Due Date', width: 280, index: 4,
									valueFormatter: (params) => {
										return dateValueParser(params.value,{});
									}
								},
								{ field: 'person_responsible', headerName: 'Person Responsible', width: 280, index: 5},
								{ field: 'approved_user', headerName: 'Approval User', width: 280, index: 6},
								{ field: 'approved_user_comment', headerName: 'Approval Comments', width: 500, index: 7},
							]}
							purgeColumns={[12,11,9,8]}
						/>
					</div>
				</div>
			</PageHeader>);
}



export default dataUIWrapper(GovernanceCreate, {
								dispatchers:{
									create: CreateDispatchers,
									getMasterList: getMasterList,
									download: downloadFile,
									delete: deleteDispatchers
								},
								featchers: [
									getPersonResponsible
								]
							});