import ApprovalUserObject from './ApprovalUserObject.js';

const userDetailsConfig = [
	[

		{
			objectKey: 'created_user',
			widget: 'FormTextInput',
			editableModes: [],
			text: 'Created User'
		},	
		{
			objectKey: 'created_time',
			widget: 'FormTextInput',
			editableModes: [],
			text: 'Created Time',
			viewValueParser: {
				parser: 'dateValueParser'
			}
		},
		{
			objectKey: 'created_user_ip_address',
			widget: 'FormTextInput',
			editableModes: [],
			text: 'IP Address'
		},
		
	],
	[
		{
			objectKey: 'updated_user',
			widget: 'FormTextInput',
			editableModes: [],
			text: 'Updated User'
		},	
		{
			objectKey: 'updated_time',
			widget: 'FormTextInput',
			editableModes: [],
			text: 'Updated Time',
			viewValueParser: {
				parser: 'dateValueParser'
			}
		}
	]
]

const ModelApprovalConfig = [
	[
		{
			objectKey: 'approval_history',
			widget: 'FormGridComponent',
			widgetProps: {
				enableFooter: true,
				columns: [
                    {
                        field: 'approval_status',
                        headerName: 'Approval Status',
                        width: 200
                    },
					{
                        field: 'updated_user',
                        headerName: 'Updated User',
                        width: 200
                    },
					{
                        field: 'approved_user_comment',
                        headerName: 'Approval Comments',
                        width: 500,
                        minWidth: 500,
                        flex: 1,
                    },
					{
                        field: 'history_date',
                        headerName: 'Updated Time',
                        width: 300,
                    }
				]
			}
		}
	]
]

var ApprovalHistoryObject = [
	{
		...ApprovalUserObject,
		viewModes: ['approve'],
	},
	{
		viewModes: ['view','edit'],
		items : [
			{
				header: 'User Details',
				componentConfig: userDetailsConfig
			} 
		]
	},
	{
		viewModes: ['view','edit','approve'],
		items : [
		
			{
				header: 'Approval History',
				componentConfig: ModelApprovalConfig
			},    
		]
	}
];

export default ApprovalHistoryObject;


var getHistoryObjectPerMode = function(modes){

	return ApprovalHistoryObject.map(function(_obj){

		var _modesList = [];
	
		modes.forEach(function(_m){
			if(_obj.viewModes.indexOf(_m)>-1){
				_modesList.push(_m)
			}
		})
	
		return {
			..._obj,
			viewModes: _modesList
		}
	});
}

export {getHistoryObjectPerMode}