import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

var emptyArray = [];

const RMTabs = function(props){

    var {data=emptyArray, selected, onSelect} = props;

    const idSelected = React.useMemo(function(){
        return data.indexOf(selected);
    },[data, selected])
    const tabs = React.useMemo(function(){
        return data.map(function(e){
            return (<Tab label={e.text}/>);
        })
    },[data]);

    const _handleSelect = React.useCallback(function(e, value){
        onSelect(data[value]);
    },[onSelect, data])

    return (<div className="rm-tabs-wrapper">
                <Tabs value={idSelected} onChange={_handleSelect}>
                    {tabs}
                </Tabs>
            </div>)

}


export {RMTabs}