import React from 'react';
import { Link} from "react-router-dom";
import Button from '@material-ui/core/Button';

const PageHeaderButton = function(props){
    
    const {config} = props;

    if(!config){
        return null;
    }
    
    const {url, label} = config;
    return (<div className="ul-page-header-button">
                <Link to={url} >
                    <Button variant="contained" color="primary" className="heading-button" >
                        {label}				
                    </Button>
                </Link>
            </div>)
}

export {PageHeaderButton}