import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { newAgeFormConfig } from '../Configs/NewAgeFormConfig';

const FormCheckbox = function(props){

    const {id, value=false,  onChange,  trueValue=true, falseValue=false} = props;

    const _value= React.useMemo(function(){
        return value === trueValue;
    },[trueValue, value])

    const _handleChange = React.useCallback(function(event){
        
        onChange({
            value: _value?falseValue:trueValue
        });

    },[_value, onChange, falseValue, trueValue]);


    return (<Checkbox
                id={id}
                color="primary"
                checked={_value}
                onChange={_handleChange}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
            />);
}


newAgeFormConfig.pluginComponent('FormCheckbox', FormCheckbox);