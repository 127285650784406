import APIConfig from "../../APIConfig";

export default {
    url: APIConfig.getPersonResponsible,
    method: 'get',
    postProcessor: function(dataObj, result){
        if(result){
            dataObj.responsible = result.risksupervisor;
        }
    },
}