import React from 'react';
import { newAgeFormConfig } from "../Configs/NewAgeFormConfig";
import TextField from '@material-ui/core/TextField';

var getPrecisionTwo = function(str){
	return ("00"+str).slice(-2);
}

const _processDate = function(value){
    if(!value){
        return value;
    }
    var connector = 'T'
    
    var val = new Date(value);
    return val.getFullYear()+'-'+getPrecisionTwo(val.getMonth()+1)+'-'+getPrecisionTwo(val.getDate())+connector+getPrecisionTwo(val.getHours())+':'+getPrecisionTwo(val.getMinutes());
}


const FormDateTimeInput = function(props){

    const {id, value='', error, onChange, updateOnBlur, ...extProps} = props;

    const [internalValue, setInternalValue] = React.useState();

    const _handleChange = React.useCallback(function(event){
        var _value = event.target.value;
        
        if(updateOnBlur){
            setInternalValue(_value)
        }else{
            onChange({
                value: _value
            });
        }        

    },[onChange, updateOnBlur]);

    const _handleBlur = React.useCallback(function(){
        
        if(updateOnBlur){
            onChange({
                value: internalValue
            });
        }       

    },[internalValue, onChange, updateOnBlur]);

    const _value = React.useMemo(function(){
        return _processDate(value)
    },[value]);

    React.useEffect(function(){
        setInternalValue(_value);
    },[_value])

    return (<TextField
                id={id}
                value={updateOnBlur?internalValue:_value}
                onChange={_handleChange}
                onBlur={_handleBlur}
                {...extProps}
                type="datetime-local"
                variant="filled"
                error={!!error}
                helperText={error}
            ></TextField>);


}


newAgeFormConfig.pluginComponent('FormDateTimeInput', FormDateTimeInput);