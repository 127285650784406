import React from 'react';
import ComputationCreate from './Routes/Create/ComputationCreate';
import ComputationView from './Routes/View/ComputationView';
import ComputationList from './Routes/List/ComputationList';
import { Route} from 'react-router-dom';	


function ComputationRoutes() {
	
	
	return (<React.Fragment>
                <Route path="/ecl/computations/list">
                    <ComputationList />
                </Route>
               
                <Route path="/ecl/computations/create">
                    <ComputationCreate />
                </Route>
                
                <Route path="/ecl/computations/view/:id">
                    <ComputationView />
                </Route>
              
            </React.Fragment>);
}

export default ComputationRoutes;
