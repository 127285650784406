import { newAgeFormConfig } from "../Configs/NewAgeFormConfig";
import React from 'react';

const SwitchGroupValueParser = function(value, options, props){

    const {onValue="True", offValue="False"} = options;
    const {data=[], textField='', valueField=''} = props;

    const _items = data.map(function(_data){

        var _value = value[_data[valueField]]?onValue:offValue;

        return (<div key={_data[valueField]} className="app-flex-col-nogrow app-form-switch-group-item">
                    <div class="form-label">{_data[textField]}</div>
                    <div class="form-input">
                        {_value}
                    </div>
                </div>)
    })


    return (<div className="app-switch-group-view">
                {_items}
            </div>)
}




newAgeFormConfig.addValueParser('switchGroupValues', SwitchGroupValueParser);