import React from 'react';
import { dateValueParser } from '../../../ApplicationFormBuilder/Parsers/dateValueParser.js';
import { FullPageCardGrid } from '../../FullPageCardGrid/FullPageCardGrid.js';



const columns =  [
      { field: 'audit_module', headerName: 'Module',  width: 200},
	  { field: 'item_name', headerName: 'Type',  width: 200},
	  { field: 'change_type', headerName: 'Change Type',  width: 200},
	  { field: 'change_status', headerName: 'Change Status', width: 200},
	  { field: 'change_message', headerName: 'Change Message', width: 500, flex: 1, minWidth: 500},
	  { field: 'updated_by', headerName: 'Updated By',  width: 200},  
	  { field: 'updated_time', headerName: 'Updated Time', width: 200, type: 'date',
			valueFormatter: (params) => {
				return dateValueParser(params.value,{});
			}},  
	  { field: 'created_user_ip_address', headerName: 'IP Address',  width: 200}
	  
];

const AuditGrid = function(props){

    const {data=[]} = props;

   
    return (<FullPageCardGrid rows={data} columns={columns}/>);    

}

export {AuditGrid}