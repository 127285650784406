import React from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import  {  useHistory } from 'react-router-dom';
import {notificationObj} from '../../Global/NotificationHandler.js';
import FileList from './FileList.js';
import { dataUIWrapper } from '../../Common/DataUIWrapper/dataUIWrapper.js';

function download(filename, text, url) {
	const href = URL.createObjectURL(text);

    // create "a" HTLM element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', filename); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
}

const MainComponent = dataUIWrapper(function(globalProps){
	debugger;
	const [name, setName] = React.useState('');
	const [file, setFile] = React.useState({});

	const history = useHistory();
	const uploadFiles = function(){

	
		if(!file.files){
			notificationObj.showNotification('error','Upload file');
			return;
		}

		if(!name){
			notificationObj.showNotification('error','Add description');
			return;
		}
		
		var form_data = new FormData();

		form_data.append('item_desc', name);
		form_data.append('config_name', globalProps.modelName);
		form_data.append('module', globalProps.module);
		form_data.append('File1', file.files);

		globalProps.dispatchRequest('create', {
			data: form_data,  
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		} ).then(function(){
			setName('');
			setFile({});
			globalProps.refetch();
		});
	}

	const goBack = function(){
		history.push(globalProps.homeUrl);
	}

	const deleteFile = function(row){
		globalProps.dispatchRequest('delete', {
			data: {
				"config_name": globalProps.modelName,
				"module":globalProps.module,
				"itemname": row.itemname
			}
		} ).then(function(){
			globalProps.refetch();
		});
	}

	const downloadFile = function(row){
		globalProps.dispatchRequest('download', {
            responseType: 'blob',
			data: {
				"config_name": globalProps.modelName,
				"module":globalProps.module,
				"itemname": row.itemname
			}
		} ).then(function(res){
			debugger;
			download(row.itemname, res.data, globalProps.downloadUrl);
		});
	}
	

	return (
		<div className="right-section-contents">
			<Typography variant="h4" className="app-header-title  ecl-header-title">
				Documents
				<Breadcrumbs aria-label="breadcrumb">
					<Link color="inherit" href={globalProps.homeUrl}>
					{globalProps.homeText}
					</Link>
					<Typography color="textPrimary">Documents</Typography>
				</Breadcrumbs>
			</Typography>

			<Card className="main-ui-card">
				<Typography variant="h6" className="app-sub-title ecl-header-title">
					Configuration Name - {globalProps.modelName}
				</Typography>
				<div className="form-builder-form-wrapper">
					<div className="form-label">Document Upload</div>
					<div className="form-input long-input">
						<TextField type="file" value={file.value || ''} onInput={(e)=>{setFile({value: e.target.value, files: e.target.files[0]});}} variant="filled"/>
					</div>
				</div>
				<div className="form-builder-form-wrapper">
					<div className="form-label">Description</div>
					<div className="form-input long-input">
						<TextField type="text" value={name}  multiline={true} onInput={(e)=>{setName(e.target.value)}} variant="filled"/>
					</div>
				</div>
				
				<div>
					<Button variant="contained" color="primary" onClick={uploadFiles}>
						Upload
					</Button>
				</div>
			</Card>


			<Card className="main-ui-card">
				<Typography variant="h6" className="app-sub-title ecl-header-title">
					Uploaded Documents
				</Typography>
				
                <div className="dss-grid-container">
					<FileList
						handleDelete = {deleteFile}
						handleDownload={downloadFile}
						view={globalProps.view}
						rows={globalProps.masterRecord || []} />
				</div>
			</Card>
		   

			<div className="button-bar">
				<Button variant="contained" color="primary" onClick={goBack}>
					Done
				</Button>
			</div>
		</div>
	);
})

function FilesUploadComponent(globalProps) {

	const modelName = React.useMemo(function(){
		return window.location.pathname.split('/').slice(-1)[0];
	},[]);

	const fetchingData =  [{
        url: globalProps.fetchingUrl,
        method: 'get',
        key: 'masterRecord',
        preprocessor: function(obj){
            obj.url = obj.url + modelName+'/'+globalProps.module+'/';
            return obj;
        }
    }]

	const DispatchingData = {
		create: {
			method: 'post',
			url:  globalProps.saveUrl,
		},
		delete: {
			method: 'put',
			url: globalProps.deleteUrl
		},
		download: {
			method: 'put',
			url: globalProps.downloadUrl
		}
	}



	return <MainComponent fetchingData={fetchingData} {...globalProps} modelName={modelName} dispatcher={DispatchingData}></MainComponent>
	
}

export default FilesUploadComponent;