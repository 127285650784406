export default [
    [
        {
            objectKey: 'Strategies',
            widget: 'FormMultiDimentionalGrid',
            widgetProps: {
                rowField:'strategiesList', 
                columnField:'risksList',
                mainColumnField:'statement',
                mainColumnTitle:'Strategy Statement',
                incrementalColumnField:'risk_',
                incrementalColumnTitle:'Risks',
                rowModalTitle:'Select Strategies',
                columnModalTitle:'Select Risks',
                rowTextField: 'strategy',
                columnTextField:'category_name'
            },
            explicitAfterUpdate: true, 
            explicitUpdateModes: ['view','edit','approve'],
            onAfterUpdate: function(options){

                var objectives = window.structuredClone(options.context.componentValue.RASObjective || []),
                    objectivesColumn=[
                        { 	
                            field: 'missiongoal', 
                            headerName: 'Mission Goal', 
                            width: 600,
                            minWidth: 600,
                            flex: 1
                        }
                    ],
                    statements = {}

                options.value.forEach(function(_v){
                    statements[_v.statement] = true
                    objectives.forEach(function(_obj){
                        if(typeof _obj[_v.statement] === 'undefined'){
                            _obj[_v.statement]=1;
                        }
                    });
                    objectivesColumn.push({
                        field: _v.statement, 
                        headerName: _v.statement, 
                        width: 300,
                        minWidth: 300,
                        flex: 1,
                        editable: {
                            isEditable: function(){
                                return true;
                            },
                            widget: 'FormTextInput',
                            widgetProps: {
                                type: 'number'
                            }
                        }
                    });
                });
            
                var strategirs_vor_value = window.structuredClone(options.context.componentValue.strategy_vor || []);

                strategirs_vor_value = strategirs_vor_value.filter(function(_vor){
                    if(statements[_vor.statement]){
                        delete statements[_vor.statement]
                        return true;
                    }
                    return false;
                });

                Object.keys(statements).forEach(function(_st){
                    strategirs_vor_value.push({
                        statement: _st,
                        roi: 0
                    })
                })

                options.context.onUpdateExts({
                    RASObjective:{
                        columns: objectivesColumn
                    }
                })

                options.context.onUpdateValue({
                    strategy_vor: strategirs_vor_value,
                    RASObjective: objectives
                })


            },
            validation: [
                {
                    type:'custom',
                    fn: function(value){
                        value = value || [];
                        var message;
                        value.forEach(function(_row){
                            if(Object.keys(_row).length<2){
                                message = 'Please select atlease One Risk';
                            }
                        });
                        return message;
                    }
                }
            ]
        }

    ]
]