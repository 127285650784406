import React from 'react';
import { dataUIWrapper } from '../../../../../../Common/DataUIWrapper/dataUIWrapper.js';
import { PageHeader } from '../../../../../../Common/Components/PageHeader/PageHeader.js';
import { ListGrid } from '../../../../../../Common/Components/ListGrid/ListGrid.js';
import { RMTabs } from '../../../../../../Common/Components/WrappedComponents/Tabs/RMTabs.js';
import governanceTabsData from '../../Utils/commonData/governanceTabsData.js';
import getMasterList from '../../Utils/dispatchers/getMasterList.js';
import { AppDownloadIcon } from '../../../../../../Common/Icons/AppDownloadIcon.js';
import downloadBlob from '../../../../../../Common/Utils/downloadBlob.js';
import downloadFile from '../../Utils/dispatchers/downloadFile.js';
import { dateValueParser } from '../../../../../../Common/ApplicationFormBuilder/Parsers/dateValueParser.js';
import { AppApproveIcon } from '../../../../../../Common/Icons/AppApproveIcon.js';
import { AppRejectIcon } from '../../../../../../Common/Icons/AppRejectIcon.js';
import ApproveDispatcher from '../../Utils/dispatchers/ApproveDispatcher.js';
import Modal from '@mui/material/Modal';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@mui/material/Box';
import { RMCard } from '../../../../../../Common/Components/WrappedComponents/RMCards/RMCards.js';

const GovernanceApprovalList = function(props){

	const [tabSelected, setTabSelected] = React.useState(governanceTabsData[0]);
	const [fileListData, setFileListData] = React.useState([]);
	const [objectStatus, setObjectStatus] = React.useState({});
	const [approval_comments, setApprovalComments] = React.useState('');
	const {dispatchRequest} = props;

	const _handleDownload = React.useCallback(function(props){
		const {row} = props;
		dispatchRequest('download', {
            responseType: 'blob',
			data: {
				"config_name": 'governance',
				"module": tabSelected.value,
				"itemname": row.itemname,
				"item_type": row.item_type
			}
		} ).then(function(res){
			downloadBlob(row.itemname, res.data, downloadFile.url);
		});
	},[dispatchRequest, tabSelected]);

	const _handleApproval = React.useCallback(function(options){
		setObjectStatus({
			type: 'Approved',
			header: 'Approval Comments',
			props: options
		});
		setApprovalComments('');
	},[]);

	const _handleRejection = React.useCallback(function(options){
		setObjectStatus({
			type: 'Rejected',
			header: 'Rejection Comments',
			props: options
		});
		setApprovalComments('');
	},[]);

	const customIconRenderer = React.useCallback(function(props){
		var buttons =[]
		if(props.status==='Pending'){
			buttons.push(<AppApproveIcon key={'approve'} onClick={()=>_handleApproval(props)}></AppApproveIcon>);
			buttons.push(<AppRejectIcon key={'reject'} onClick={()=>_handleRejection(props)}></AppRejectIcon>);
		}
		buttons.push(<AppDownloadIcon key={'download'} onClick={()=>_handleDownload(props)}></AppDownloadIcon>)

		return buttons;
	},[])

	const getFiles = React.useCallback(function(module){
		dispatchRequest('getMasterList',{module: module}).then(function(list){
			setTimeout(function(){
				setFileListData(list.data);	
			},100);
		})
	},[])

	React.useEffect(function(){
		if(tabSelected){
			getFiles(tabSelected.value);
		}
	},[tabSelected, getFiles]);

	const _handleApprove = React.useCallback(function(){
		
		var row = objectStatus.props.row;
		debugger;
		dispatchRequest('approve', {
			data: {
				"config_name": 'governance',
				"module": tabSelected.value,
				"itemname": row.itemname,
				"item_type": row.item_type,
				"approval_status" : objectStatus.type,
				"approved_user_comment": approval_comments
			}
		} ).then(function(){
			getFiles(tabSelected.value)
		});
		setObjectStatus({});
	},[objectStatus, tabSelected, approval_comments])


	return (<PageHeader
				title={'Governance Approval List'}
				currentPage={'Approval List'}
			>
				<div className="app-flex app-full-overflow">
					<RMTabs data={governanceTabsData} selected={tabSelected} onSelect={setTabSelected}/>
					<ListGrid
						data={fileListData}
						nameField={"itemname"}
						nameTitle={"Document Name"}
						editList={[]}
						viewUrl=""
						editUrl=""
						customIconRenderer={customIconRenderer}
						columns={[
							{ field: 'item_type', headerName: 'Item Type', width: 280, index: 1},
							{ field: 'iteminfo', headerName: 'Description', width: 280, index: 2},
							{ field: 'due_date', headerName: 'Due Date', width: 280, index: 4,
								valueFormatter: (params) => {
									return dateValueParser(params.value,{});
								}
							},
							{ field: 'person_responsible', headerName: 'Person Responsible', width: 280, index: 5},
							{ field: 'approved_user', headerName: 'Approval User', width: 280, index: 6},
							{ field: 'approved_user_comment', headerName: 'Approval Comments', width: 500, index: 7},
						]}
						purgeColumns={[12,11,9,8]}
					/>
				</div>
				<Modal
					open={!!objectStatus.type}
				>   
					<Box className="app-modal-box">
						<RMCard header={objectStatus.header} className="app-flex app-modal-card">
							<TextField type="text" value={approval_comments} className="approval-comments-input" multiline={true} onInput={(e)=>{setApprovalComments(e.target.value)}} variant="filled"/>
							<div className="button-bar">
								<Button variant="contained"onClick={_handleApprove}>
									Done
								</Button>
								<Button variant="contained"onClick={()=>{setObjectStatus({})}}>
									Cancel
								</Button>
							</div>
						</RMCard>
					</Box>
				</Modal>
			</PageHeader>);
}

export default dataUIWrapper(GovernanceApprovalList, {
								dispatchers:{
									getMasterList: getMasterList,
									download: downloadFile,
									approve: ApproveDispatcher
								},
								featchers: []
							});