var appNavConfig = {};

var pageRegex = [{
    regex: /\/admin|user-profile\/?.*/,
    app: 'admin'
}];
var homePages = {};
var navData = {}

appNavConfig.plugin = function(name, obj){
    navData[name] = obj.nav;
    homePages[name] = obj.homePage;
    obj.regex.forEach(function(_regexp){
        pageRegex.push({
            regex: _regexp,
            app: name
        })
    });

}

appNavConfig.getHomepage = function(name){
    return homePages[name] || '/';
}

const emptyArray = [];

appNavConfig.getNavData = function(name){
    return navData[name] || emptyArray;
}

appNavConfig.getCurrentSuite = function(pathName){

    for(var i=0;i<pageRegex.length;++i){
        var obj = pageRegex[i];
        if(pathName.match(obj.regex)){
            return obj.app;
        }
    }

}

export default appNavConfig;