import { newAgeFormConfig } from "../Configs/NewAgeFormConfig";


const dateValueParser = function(value, options={}){

    const {} = options;

    if(!value){
        return '';
    }
    var _date = new Date(value);
    return _date.toLocaleString("en-GB", {
        month: "short",
        year: "numeric", 
        day: "2-digit",
        hour:"2-digit",
        minute:"2-digit",
        second:"2-digit",
    });
}

newAgeFormConfig.addValueParser('dateValueParser', dateValueParser);

export {dateValueParser}