
import getDivisions from './allDataFetchers/getDivisions.js';
import getOwnerLocation from './allDataFetchers/getOwnerLocation.js';
import getRiskCategories from './allDataFetchers/getRiskCategories.js';
import getRiskImpacts from './allDataFetchers/getRiskImpacts.js';
import getRiskTestParams from './allDataFetchers/getRiskTestParams.js';
import getRiskControlledStatus from './allDataFetchers/getRiskControlledStatus.js';
import getRiskTreatementStatuses from './allDataFetchers/getRiskTreatementStatuses.js';
import getRiskTypes from './allDataFetchers/getRiskTypes.js';
import getEmployeeList from './allDataFetchers/getEmployeeList.js';
import getResidualRiskActions from './allDataFetchers/getResidualRiskActions.js';
import getResidualRiskTreatements from './allDataFetchers/getResidualRiskTreatements.js';

export default [
    getDivisions,
    getOwnerLocation,
    getRiskCategories,
    getRiskImpacts,
    getRiskTestParams,
    getRiskControlledStatus,
    getRiskTreatementStatuses,
    getRiskTypes,
    getEmployeeList,
    getResidualRiskActions,
    getResidualRiskTreatements
]