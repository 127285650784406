//var mainUrl=window.location.protocol+'//'+window.location.hostname+':8000';
var mainUrl=window.location.protocol+'//'+window.location.hostname;

var config = {
	login: mainUrl+'/rest-auth/login/',
	otpverify:mainUrl+'/logintokenverification/',
	qrScanner:'/generatetoken/',
	//ECL Start
	allMasterRecords: mainUrl+'/getMasterRecords/all/',
	getMasterRecords: mainUrl+'/getMasterRecord/',
	createMasterRecord: mainUrl+'/createMasterEntry/',
	deleteMasterRecord: mainUrl+'/deleteMasterRecord/',
	approveListRecord: mainUrl+'/getMasterRecords/allaction/',
	approveMasterRecord: mainUrl+'/updateMasterRecordApproval/',
	logout: mainUrl+'/logout/',
	//Model Definition
	approvedModels: mainUrl+'/getMasterRecords/approved/',
	approvedDefinitions: mainUrl+'/getDefinitionRecords/approved/',
	allModelDefinitionRecords: mainUrl+'/getDefinitionRecords/all/',
	getModelDefinitionRecord:mainUrl+'/getDefinitionRecord/',
	createModelDefinitionRecord: mainUrl+'/createDefinitionEntry/',
	deleteModelDefinitionRecord: mainUrl+'/deleteDefinitionRecord/',
	approveListModelDefinitionRecord: mainUrl+'/getDefinitionRecords/allaction/',
	approveModelDefinitionRecord: mainUrl+'/updateDefinitionApproval/',
	//deleteModelDefinitionRecord:mainUrl+'/deleteDefinitionRecord/',
	//approveListModelDefinitionRecord:mainUrl+'/getDefinitionRecords/allaction/',
	//approveModelDefinitionRecord:mainUrl+'/updateDefinitionApproval/',
	// Audit Trail
	allAuditTrailRecords: mainUrl+'/ratinggetAuditTrails/all/',
	//Scenario Definition
	allScenarioDefinitionRecords: mainUrl+'/getScenarioDefinitionRecords/all/',
	getScenarioDefinitionRecord: mainUrl+'/getScenarioDefinitionRecord/',
	createScenarioDefinitionRecord: mainUrl+'/createScenarioDefinitionEntry/',
	deleteSceanrioDefinitionRecord: mainUrl+'/deleteScenarioRecord/',
	approveListScenarioDefinitionRecord: mainUrl+'/getScenarioDefinitionRecords/allaction/',
	approveScenarioDefinitionRecord: mainUrl+'/updateScenarioDefinitionApproval/',
	approvedScenarios: mainUrl+'/getScenarioDefinitionRecords/approved/',
	//approveListScenarioDefinitionRecord:mainUrl+'/getScenarioDefinitionRecords/allaction/',
	//approveScenarioDefinitionRecord:mainUrl+'/updateScenarioDefinitionApproval/',
	//approvedScenarios:mainUrl+'/getScenarioDefinitionRecords/approved/',
	// ECL Computation
	allCongigurationRecords: mainUrl+'/getECLComputationRecords/all/',
	getConfigurationRecord: mainUrl+'/getECLComputationRecord/',
	createConfigurationRecord: mainUrl+'/uploadCSVforScenario/',
	downloadComputation: mainUrl+'/geteclconfigurationReportECLAggregate/',
	// Reporting
	modelWiseReporting: mainUrl+'/getmodelwisereport/',
	//userprofile
	getuserinfo: mainUrl+'/getUserInfo/',
	//getuserinfo:mainUrl+'/getUserInfo/',
	getCSV:  mainUrl+'/getsamplecsv/',
	//dashboard
	getallReports: mainUrl+'/geteclallReportECLAggregate/',

	dss: {
		getOrgMisssionGoals: mainUrl + '/getOrgMisssionGoals/',
		getStrategies:mainUrl + '/getStrategies/',
		getRASConfigs: mainUrl + '/getRASConfigs/',
		getERMConfigs: mainUrl + '/getERMConfigs/all/',
		getRAStatements: mainUrl + '/getRAStatements/',
		createDssConf: mainUrl + '/createDssConf/',
		getRiskCategorys: mainUrl+'/getRiskCategorys/',
		getDssConfs: mainUrl+'/getDssConfs/',
		getAllDssConfsResult: mainUrl+'/getDssConfsResult/all/',
		getApprovalDssConfsResult: mainUrl+'/getDssConfsResult/allaction/',
		approve: mainUrl+'/updateDSSResultApproval/',
		deleteDssConf :mainUrl +  '/deleteDssConf/',
		getDSSRiskCostVsReturn :mainUrl +  '/getDSSRiskCostVsReturn/', 
	},
	erm: {
		createErmMasterEntry: mainUrl+'/createErmMasterEntry/',
		updateErmMasterRecordApproval: mainUrl+'/updateErmMasterRecordApproval/',
		getErmMasterRecords: mainUrl+'/getErmMasterRecords/all/',
		getErmMasterRecordsApproval: mainUrl+'/getErmMasterRecords/allaction/',
		getErmMasterRecord: mainUrl+'/getErmMasterRecord/',
		deleteErmMasterRecord: mainUrl+'/deleteErmMasterRecord/',
		getErmAuditTrails: mainUrl+'/getErmAuditTrails/all/',		//all
		calculateErmRisk: mainUrl+'/calculateErmRisk/',		// S3 - INHERENT  RISK - POST
		getDivisions: mainUrl+'/getDivisions/',
		getRiskDepartments: mainUrl+'/getRiskDepartments/',
		getRiskOwnersSuperVisorLocations: mainUrl+'/getRiskOwnersSuperVisorLocations/',
		getRiskCategorys: mainUrl+'/getRiskCategorys/',									//S2
		getRiskSubCategory: mainUrl+'/getRiskCategorys/',					//S2-SUB
		getRiskIndicator: mainUrl+'/getRiskCategorys/',	//WIP
		
		getRiskImpact: mainUrl+'/getRiskImpact/',						//s3
		getRiskTestparameters: mainUrl+'/getRiskTestparameters/',		//s3- likelihood, severity
		getRiskControlledStatus: mainUrl+'/getRiskControlledStatus/',	//s3 - Control Status
		
		getRiskPrincipleImpactAdeqacyControls: mainUrl+'/getRiskPrincipleImpactAdeqacyControls/',	//S4  - ADEQUITYPRINCIPAL
		getRiskTreatementsandRiskStatuses: mainUrl+'/getRiskTreatementsandRiskStatuses/',			// S5 - TREATMENT STATEMENT
		// S5 - users Dropdown
		getRiskTypes: mainUrl+'/getRiskTypes/',						//s7
		getEmployeeList: mainUrl+'/getEmployeeList/',						//s7
		getResidualRiskActions: mainUrl+'/getResidualRiskActions/',						//s7
		getResidualRiskTreatements: mainUrl+'/getResidualRiskTreatements/',						//s7
		// S6 -> Submit -> Window -> Show Results -> ok -> createErmMasterEntry -> Main Listing Page

		// AuditTrails 
		getDashboardReport: mainUrl+'/getDashboard/',
		getDivisionWiseReport: mainUrl+'/getDivisionWiseReport/', 
		getConfigurationwiseReport: mainUrl+'/getConfigurationwiseReport/',// --report
		getDivisionWIseStatusReport: mainUrl+'/getDivisionWIseStatusReport/', 
		getRiskFactorCountReport: mainUrl+'/getRiskFactorCountReport/',
		calculateErmMitiGationRisk: mainUrl+'/calculateErmMitiGationRisk/',
		getRiskCategoryCountReport: mainUrl+'/getRiskCategoryCountReport/' ,//--dashboard
		getDashboardReportByRiskValues: mainUrl + '/getDashboardReportByRiskValues/', //--dashboard
		getReportsByValues: mainUrl + '/getReportsByValues/', //Put request --dashboard
		getReportRiskRegistersNames: mainUrl + '/getReportRiskRegistersNames/', //--dashboard
		getDashboardRiskDepartments: mainUrl + '/getDashboardRiskDepartments/', //--dashboard
		getDashboardRiskSubCategories: mainUrl + '/getDashboardRiskSubCategories/' //--dashboard
	},
	rating:{
		// Rating master model
		ratingallMasterRecords: mainUrl+'/ratinggetMasterRecords/all/',
		ratinggetMasterRecords: mainUrl+'/ratinggetMasterRecord/',
		ratingcreateMasterRecord: mainUrl+'/ratingcreateMasterEntry/',
		ratingdeleteMasterRecord: mainUrl+'/ratingdeleteMasterRecord/',
		ratingapproveListRecord: mainUrl+'/ratinggetMasterRecords/allaction/',
		ratingapproveMasterRecord: mainUrl+'/ratingupdateMasterRecordApproval/',
		// Rating Model Definition
		ratingapprovedModels: mainUrl+'/ratinggetMasterRecords/approved/',
		ratingapprovedDefinitions: mainUrl+'/ratinggetDefinitionRecords/approved/',
		ratingallModelDefinitionRecords: mainUrl+'/ratinggetDefinitionRecords/all/',
		ratinggetModelDefinitionRecord:mainUrl+'/ratinggetDefinitionRecord/',
		ratingcreateModelDefinitionRecord: mainUrl+'/ratingcreateDefinitionEntry/',
		ratingdeleteModelDefinitionRecord: mainUrl+'/ratingdeleteDefinitionRecord/',
		ratingapproveListModelDefinitionRecord: mainUrl+'/ratinggetDefinitionRecords/allaction/',
		ratingapproveModelDefinitionRecord: mainUrl+'/ratingupdateDefinitionApproval/',
		// CSV
		ratinggetCSV:  mainUrl+'/ratinggetsamplecsv/',

	},
	uploadFiles: mainUrl+'/uploadFile/',
	getFiles: mainUrl + '/getListofFiles/',
	deleteFile: mainUrl + '/deleteFile/',
	adminURL: mainUrl+ '/tenant-admin/',
	downloadFile: mainUrl + '/downloadFile/',
	approveGovernanceDocs: mainUrl + '/approveGovernanceDocs/',
	
}

export default config;


