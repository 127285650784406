import React from 'react';
import { Route} from 'react-router-dom';	
import { LandingPage } from './Routes/LandingPage';

function LandingPageRouter() {
	
	return (<React.Fragment>
                <Route path="/irien/home">
                    <LandingPage />
                </Route>
            </React.Fragment>);
}

export default LandingPageRouter;
