import { newAgeFormConfig } from "../NewAgeFormConfig";


var required = function(value, config){

    const {message} = config;

    if((!value && value!==0) || (typeof value ==='string' && !value.trim()) || (Array.isArray(value) && !value.length)){
        return message;
    }
}

newAgeFormConfig.addValidation('required', required);


newAgeFormConfig.addValidation('gridRequiredFields', function(value, config){

    const {gridFields} = config;
    var _message;
    (value || []).forEach(function(_row){

        gridFields.forEach(function(_field){
            var value = _row[_field.field]
            if(!_message){
                _message = required(value, _field)
            }
        })
       
    });

    return _message

});

newAgeFormConfig.addValidation('custom', function(value, config){
    const {fn} = config;

    return fn(value, config);
});


var approvalValidation = function(value, config){

    const {message} = config;

    if(value!== 'Rejected' && value!== 'Approved'){
        return message;
    }
}

newAgeFormConfig.addValidation('approvalValidation', approvalValidation);


var minMax = function(value, config){
    const {minMessage, maxMessage, min, max} = config;
    
    var _value = Number(value); 
    if(_value<min){
        return minMessage;
    }else if(_value>max){
        return maxMessage;
    }
}


newAgeFormConfig.addValidation('minMax', minMax);