import React from 'react';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { PageHeaderButton } from './PageHeaderButton';
import { useHistory } from 'react-router-dom';

const PageHeader = function(props){

    const {title, previousLinks=[], currentPage, children, buttonConfig, className=''} = props;

    const history = useHistory();

    const goToHref = React.useCallback(function(link){
        history.push(link);
    },[])

    return (<div className={"app-flex app-right-section "+className}>
                <div className="app-flex-col-nogrow app-flex-center app-padd-r">
                    <div className="app-full-container">
                        <Typography variant="h4" className="app-header-title  ecl-header-title page-header-title">
                            {title}
                            <Breadcrumbs aria-label="breadcrumb">
                                {previousLinks.map(function(_link){
                                    return (<Link 
                                                key={_link.href}
                                                color="inherit" 
                                                href={"#"}
                                                onClick={()=>goToHref(_link.href)}
                                            >
                                                    {_link.text}
                                            </Link>)
                                })}
                                <Typography color="textPrimary">{currentPage}</Typography>
                            </Breadcrumbs>
                        </Typography>
                    </div>
                    <PageHeaderButton config={buttonConfig}/>
                </div>
                <div className="app-full-container">
                    {children}
                </div>
            </div>)
}

export {PageHeader}