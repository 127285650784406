var parseServerObject = function(result){

    var data = result[0];

    data.ConfigurationName = data.configuration_name;


    return result[0];
}


export {parseServerObject}