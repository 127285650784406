import { newAgeFormConfig } from "../Configs/NewAgeFormConfig";


const checkboxValueParser = function(value, options){

    const {componentTrueValue=true, componentFalseValue=false, trueValue, falseValue} = options;

    if(value===componentTrueValue){
        return trueValue || componentTrueValue;
    }else if(value===componentFalseValue){
        return falseValue || componentFalseValue;
    }else{
        return value;
    }

}




newAgeFormConfig.addValueParser('checkboxValue', checkboxValueParser);