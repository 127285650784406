import { wrapObjectInFormData } from "../../../../../Common/Utils/wrapObjectInFormData";

const NewAgeFormConfig = {
	layoutItems: [
		{
			stepperName: ' ',
			itemsLayout : [ //FormMultiLineInput
				{
					items : [
						{
							header: 'Select Document',
							componentConfig: [
								[
									{
										objectKey: 'item_type',
										widget: 'FormDropDown',
										text: 'Item Type',
										data: [],
										widgetProps: {
											dataTextField: 'value',
											dataValueField: 'value'
										},
										renderIf: function(context){
											return context.componentValue.module!=='other'
										}
									},
									{
										objectKey: 'item_type',
										widget: 'FormTextInput',
										text: 'Item Type',
										renderIf: function(context){
											return context.componentValue.module==='other'
										}
										
									},
									{
										objectKey: 'item_desc',
										widget: 'FormMultiLineInput',
										text: 'Description',
										validation: [
											{
												type: 'required',
												message: 'Enter Description'
											}
										]
									},
									{
										objectKey: 'person_responsible',
										widget: 'FormDropDown',
										text: 'Person Responsible',
										data: [],
										widgetProps: {
											dataTextField: 'supervisor',
											dataValueField: 'supervisor'
										},
										validation: [
											{
												type: 'required',
												message: 'Enter Description'
											}
										]
									},
									{
										objectKey: 'due_date',
										widget: 'FormDateTimeInput',
										text: 'Due Date',
										validation: [
											{
												type: 'required',
												message: 'Enter Due Date'
											}
										]
									},
									{
										objectKey: 'File11',
										widget: 'FormFileInput',
										text: 'Document Upload',
										validation: [
											{
												type: 'required',
												message: 'Enter Document Upload'
											}
										]
									},
								]
							]
						}
					
					]
				}		
			],
            stepValidation: function(value){
				if(!value.item_type){
					return 'Enter Item Type'
				}
            }

		},
        
	],
	finalSaveParsing: function(_value){
		
		return wrapObjectInFormData({
			..._value,
			"send_for_approval": "True",
			"config_name": 'governance'
		});
	},
	defaultValue: {
		"send_for_approval": "True",
		"config_name": 'governance'
	}

}


export {NewAgeFormConfig}