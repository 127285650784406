import APIConfig from "../../../APIConfig";

export default {
    url: APIConfig.getRiskTreatementStatuses,
    method: 'get',
    key: 'riskTreatmentStatement',
    dataObject: {
        riskStatus: ['risk_status'],
        riskTreatment: ['risk_treatment']
    }
}