import React from 'react';
import EasyECLAudits from './Routes/Audits/EasyECLAudits';
import { Route} from 'react-router-dom';	


function AuditRoutes() {
	
	
	return (<React.Fragment>
                <Route path="/ecl/audit">
                    <EasyECLAudits />
                </Route>
            </React.Fragment>);
}

export default AuditRoutes;
