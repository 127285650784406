import React from 'react';
import { Route} from 'react-router-dom';
import { RMCard } from '../../../Common/Components/WrappedComponents/RMCards/RMCards';
import SettingsSuggest from '@mui/icons-material/SettingsSuggest';
import Typography from '@material-ui/core/Typography';

const AvailableForSubscriberPage = function(props){
    

    return (<div className="app-flex app-right-section">
                    <RMCard className="app-flex">
                        <div className="subscriber-div">
                            <SettingsSuggest className="afs-settings-icon"/>
                            <Typography variant={"h2"} className="app-sub-title ecl-header-title report-heading">
                                Available For Subscribers
                            </Typography>
                        </div>
                    </RMCard> 
                </div>)
}

export {AvailableForSubscriberPage}