import React from 'react';
import {
    Chart,
    Legend,
    ArgumentAxis,
    ValueAxis,
    BarSeries,
    Tooltip,
} from '@devexpress/dx-react-chart-material-ui';
import { Stack, Animation, EventTracker } from '@devexpress/dx-react-chart';

const BarChart = function(props){

    const {data=[], series=[], legendPosition, height, width} = props;

    const _series = React.useMemo(function(){
        return series.map(function(_data){
            return (<BarSeries
                        name={_data.name}
                        valueField={_data.value}
                        key={_data.value}
                        argumentField={_data.argumentField || 'text'}
                    />)
        })
    },[series]);

    return (<Chart
                data={data}
                className="risk-scatter-chart"
                height={height}
                width={width}
            >
                <ArgumentAxis />
                <ValueAxis />
                {_series}
                <Stack />
                <Legend position={legendPosition}/>
                
                <EventTracker />
                <Animation />
                <Tooltip />
            </Chart>)
}

export {BarChart}