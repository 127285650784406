var mainUrl=window.riskMac.mainURL;


export default {	
		getMasterList: mainUrl+'/getErmMasterRecords/all/',
		getMasterRecord: mainUrl+'/getErmMasterRecord/',
		create: mainUrl+'/createErmMasterEntry/',
		delete: mainUrl+'/deleteErmMasterRecord/',
		edit: mainUrl+'/createErmMasterEntry/',
		approve: mainUrl+'/updateErmMasterRecordApproval/',
		approvalList: mainUrl+'/getErmMasterRecords/allaction/',
		/****** Fetching Informational Data */
		getDivisions: mainUrl+'/getDivisions/',
		getRiskOwnersSuperVisorLocations: mainUrl+'/getRiskOwnersSuperVisorLocations/',
		getRiskCategorys: mainUrl+'/getRiskCategorys/',	
		getRiskImpact: mainUrl+'/getRiskImpact/',
		getRiskTestparameters: mainUrl+'/getRiskTestparameters/',
		getRiskControlledStatus: mainUrl+'/getRiskControlledStatus/',
		getRiskAdeqacy: mainUrl+'/getRiskPrincipleImpactAdeqacyControls/',
		getRiskTreatementStatuses: mainUrl+'/getRiskTreatementsandRiskStatuses/',
		getRiskTypes: mainUrl+'/getRiskTypes/',	
		getEmployeeList: mainUrl+'/getEmployeeList/',
		getResidualRiskActions: mainUrl+'/getResidualRiskActions/',	
		getResidualRiskTreatements: mainUrl+'/getResidualRiskTreatements/',	
		/****** Data Dispatchers API Data */
		getRiskDepartments: mainUrl+'/getRiskDepartments/',
		getRiskSubCategory: mainUrl+'/getRiskCategorys/',
		getRiskIndicator: mainUrl+'/getRiskCategorys/',
		calculateMitigationRisk: mainUrl+'/calculateErmMitiGationRisk/',
		calculateRisk: mainUrl+'/calculateErmRisk/'
}


