import React from 'react';
import { dataUIWrapper } from '../../../../../../Common/DataUIWrapper/dataUIWrapper.js';
import { NewAgeFormBuilder } from '../../../../../../Common/ApplicationFormBuilder/FormBuilder/NewAgeFormBuilder.js';
import { useHistory } from 'react-router-dom';
import { NewAgeFormConfig } from '../../formJSON/newAgeConfig.js';
import { PageHeader } from '../../../../../../Common/Components/PageHeader/PageHeader.js';
import CreateDispatchers from '../../Utils/dispatchers/CreateDispatchers.js';

const ModelMasterCreate = function(props){

	const [value, setValue] = React.useState({});
	
	const history = useHistory();

	const {dispatchRequest} = props;

	const _handleSave = React.useCallback(function(_value){
		dispatchRequest('create', {data: _value} ).then(function(){
			history.push('/ecl/model-master/list/');
		});
	},[dispatchRequest]);


	return (<PageHeader
					title={'Create Master Model'}
					previousLinks={[
						{
							href: "/ecl/model-master/list/",
							text: 'Master Model (All List)'
						}
					]}
					currentPage={'Create'}
				>
				<NewAgeFormBuilder 
					config={NewAgeFormConfig} 
					value={value}
					onChange={setValue}
					dispatchRequest={props.dispatchRequest}
					onSave={_handleSave}
				/>
			</PageHeader>);
}


export default dataUIWrapper(ModelMasterCreate, {
								dispatchers:{
									create: CreateDispatchers,
								},
								featchers: []
							});