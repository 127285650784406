import parseHistoryData from "../../../../../Common/Utils/parseHistoryData";

var parseServerObject = function(result){
        
    var yearWiseSce = [];

    result.Scenarios.forEach(function(_sc){
    
        var currentSc = yearWiseSce.find(function(_obj){
            return _obj.scenario_name === _sc.scenario_name;
        });
        if(!currentSc){
            currentSc = {
                scenario_name: _sc.scenario_name,
                variables: []
            }
            yearWiseSce.push(currentSc)
        }

        currentSc.variables.push({
            ..._sc,
            scenario_name: undefined
        });
           
    });



    return {
        ...result.Scenario_Definition,
        Scenario_CurrentMVs: result.Scenario_CurrentMVs,
        Scenario_Weights: result.Scenario_Weights,
        allscenarions: yearWiseSce,
        approval_history: parseHistoryData(result.Scenario_Definition.history) 
    }
}


export {parseServerObject}