const _getReportData = function(obj, options){
	const {configuration, type} = obj;


	if(configuration && type){
		if(type==='risk_control_cost_return'){
			options.dispatchRequest('getCostControl', {conf: configuration} ).then(function(e){
				options.context.onUpdateValue({
					strategy_and_mission_allignment: [],
					strategy_and_risk_adjustment_return: [],
					risk_control_cost_return:e.data
				 })
			});	
		}else{
			options.dispatchRequest('getReport', {conf: configuration} ).then(function(e){
				options.context.onUpdateValue({
					strategy_and_mission_allignment: [],
					strategy_and_risk_adjustment_return: [],
					risk_control_cost_return: [],
					[type]: e.data.DSSConfResult
				 })
			});	
		}
		
		
	}
}

//[DSSConfResult]

const NewAgeFormConfig = {
	layoutItems: [
		{
			stepperName: '',
			itemsLayout : [
				{
					items : [
						{
							header: 'Report Details',
							componentConfig: [
								[
									{
										objectKey: 'configuration_name',
										widget: 'FormDropDown',
										text: 'Configuration Name',
										data: [ ],
										widgetProps: {
											dataTextField: 'conf_name',
											dataValueField: 'conf_name'
										},
										onAfterUpdate: function(options){
											_getReportData({
												configuration: options.value,
												type: options.context.componentValue.report_type
											}, options);
										}
									},{
										objectKey: 'report_type',
										widget: 'FormDropDown',
										text: 'Type of Report',
										onAfterUpdate: function(options){
											_getReportData({
												configuration: options.context.componentValue.configuration_name,
												type: options.value
											}, options);
										},
										data: [
											{
												value: 'strategy_and_mission_allignment',
												text: 'Strategy and Mission Alignment'
											},
											{
												value: 'strategy_and_risk_adjustment_return',
												text: 'Strategy and Risk Adjustment Return'
											},
											{
												value: 'risk_control_cost_return',
												text: 'Risk Control - Cost vs Return'
											}
						
										],
										widgetProps: {
											dataTextField: 'text',
											dataValueField: 'value'
										}
									},
								]
							]
						}
					
					]
				},
				{
					items : [
						{
							header: 'Strategy and Mission Alignment',
							componentConfig: [
								[
									{
										objectKey: 'strategy_and_mission_allignment',
										widget: 'FormGridComponent',
										widgetProps: {
											enableFooter: true,
											columns: [
													{
														field: 'Strategy',
														headerName: 'Strategy Statement',
														width: 500,
														minWidth: 500,
														flex: 1
													},{
														field: 'risk_wtd_score',
														headerName: 'Score for Mission Alignment',
														width: 300,
														minWidth: 300,
														flex: 1
													}
												]
										}
									}
								]
							]
						}
					
					],
					renderIf: function(context){
						return context.componentValue.report_type && context.componentValue.configuration_name &&  context.componentValue.report_type=== 'strategy_and_mission_allignment';
					}
				},
				{
					items : [
						{
							header: 'Strategy and Risk Adjustment Return',
							componentConfig: [
								[
									{
										objectKey: 'strategy_and_risk_adjustment_return',
										widget: 'FormGridComponent',
										widgetProps: {
											enableFooter: true,
											columns: [
													{
														field: 'Strategy',
														headerName: 'Strategy',
														width: 500,
														minWidth: 500,
														flex: 1

													},{
														field: 'return_from_objective',
														headerName: 'Expected Return from the Strategy',
														width: 200
													},{
														field: 'risk_adjusted_return_pre_control',
														headerName: 'RAROC - Risk Adjusted Return from the strategy if no controls and Risk Mgt were Applied(%)',
														width: 300
													},
													{
														field: 'risk_adjusted_return_post_control',
														headerName: 'RAROC - Risk Adjusted Return from the strategy with controls and Risk Mgt applied(%)',
														width: 300
													}
												]
										}
									}
								]
							]
						}
					
					],
					renderIf: function(context){
						return context.componentValue.report_type && context.componentValue.configuration_name &&  context.componentValue.report_type=== 'strategy_and_risk_adjustment_return';
					}
				},
				{
					items : [
						{
							header: 'Risk Control - Cost vs Return',
							componentConfig: [
								[
									{
										objectKey: 'risk_control_cost_return',
										widget: 'FormGridComponent',
										widgetProps: {
											enableFooter: true,
											columns: [
													{
														field: 'Strategy',
														headerName: 'Strategy',
														width: 500,
														minWidth: 500,
														flex: 1
													},{
														field: 'controlMitigationCost',
														headerName: 'Control & migration Expenses',
														width: 200
													},{
														field: 'quantumRiskReduced',
														headerName: 'Quantum of Risk Reduced ',
														width: 300
													},
													{
														field: 'returnOnExpenses',
														headerName: 'Return on Controls & Mitigation Expenses',
														width: 300
													}
												]
										}
									}
								]
							]
						}
					
					],
					renderIf: function(context){
						return context.componentValue.report_type && context.componentValue.configuration_name &&  context.componentValue.report_type=== 'risk_control_cost_return';
					}
				},
			]
		}
	],
	finalSaveParsing: function(_value){
		return _value;
	},
	defaultValue: {
	}
}


export {NewAgeFormConfig}