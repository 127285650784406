
import {notificationObj} from '../../Global/NotificationHandler.js';

var CookieHandler = {
	
	get: function(key){
		var cookies={};
		var docCookie = document.cookie || '';
		docCookie.split(' ').forEach(function(_value){
			var keys = _value.split('=');
			cookies[keys[0]] = (keys[1] || '');
		});
		
		return cookies[key];
	},
	set: function(key, value){
		document.cookie = key +'='+value +'; Path=/;';
	},
	resetCookie: function(){
		document.cookie='key=\'\'; Path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
	},
	verifyTokenKey: function(token){
		var existingKey = this.get('key');
		if(token === existingKey){
			return true;
		}
		notificationObj.showNotification('error','Token Key Expired. Reloading Page.');
		setTimeout(function(){
			window.location.reload()	
		},100);	
		return false;
	}

	
}


export default CookieHandler;
