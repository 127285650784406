import React from 'react';
import { LoginContext } from '../../../../Context/LoginContext/LoginContext';
import { dataUIWrapper } from '../../../Common/DataUIWrapper/dataUIWrapper';
import { PageHeader } from '../../../Common/Components/PageHeader/PageHeader';
import { NewAgeFormBuilder } from '../../../Common/ApplicationFormBuilder/FormBuilder/NewAgeFormBuilder';
import { NewAgeFormConfig } from './formJSON/newAgeConfig';
import Button from '@material-ui/core/Button';
import Modal from '@mui/material/Modal';
import TextField from '@material-ui/core/TextField';
import Box from '@mui/material/Box';
import { RMCard } from '../../../Common/Components/WrappedComponents/RMCards/RMCards';
import verifyLiscence from './Utils/dispatchers/verifyLiscence';
import { notificationObj } from '../../NotificationHandler';

function UserProfileView(props) {
	
	const {userInfo={}, liscenceInfo=[]} = React.useContext(LoginContext)
	const {dispatchRequest, refetch} = props;

	const [value, setValue] = React.useState({});
	const [componentExts, setComponentExts] = React.useState({});
	const [liscencePopup, setLiscencePopup] = React.useState(false);
	const [lisenceKey, setLisenceKey] = React.useState('');
	

	React.useEffect(function(){
		setValue(function(ogValue){
			return {
				...ogValue,
				...userInfo
			}
		})
	},[userInfo]);

	React.useEffect(function(){
		setValue(function(ogValue){
			return {
				...ogValue,
				liscences:liscenceInfo
			}
		})
	},[liscenceInfo]);
	
	const customButtonRenderer = React.useCallback(function(options){
		var buttons = [];
		
		buttons.push(<Button 
						key={'add'}
						variant="contained" 
						color="primary"
						onClick = {()=>{
							setLiscencePopup(true);
							setLisenceKey('');
						}}>
							Add Liscence Key
					</Button>);

		return buttons;
	},[]);

	const _handleAddLiscense = React.useCallback(function(){
		dispatchRequest('verifyLiscence',{
			data: {
				Licensekey: lisenceKey
			}
		}).then(function(){
			notificationObj.showNotification('success','Liscence Key verified Successfully');
			setTimeout(function(){
				refetch();
			},1000);
			setLiscencePopup(false);
		}).catch(function(){
			setLisenceKey('');
		});
	},[lisenceKey, refetch, dispatchRequest]);

	return (<PageHeader
				title={'User Profile'}
				currentPage={'Profile'}
				className="restricted-page"
			>
			<NewAgeFormBuilder
				config={NewAgeFormConfig} 
				value={value}
				onChange={setValue}
				dispatchRequest={props.dispatchRequest}
				componentExts={componentExts}
				onUpdateComponentExts={setComponentExts}
				disableSave={true}
				customButtonRenderer={customButtonRenderer}
			/>
			<Modal
				open={liscencePopup}
			>   
				<Box className="app-modal-box">
					<RMCard header={'Enter Liscence Key'} className="app-flex liscence-key-card">
						<TextField type="text" value={lisenceKey} className="mar-b-20" onInput={(e)=>{setLisenceKey(e.target.value)}} variant="filled"/>
						<div className="button-bar">
							<Button variant="contained"onClick={_handleAddLiscense}>
								Apply
							</Button>
							<Button variant="contained"onClick={()=>{setLiscencePopup(false)}}>
								Cancel
							</Button>
						</div>
					</RMCard>
				</Box>
			</Modal>
		</PageHeader>);
}

export default dataUIWrapper(UserProfileView, {
	dispatchers:{
		verifyLiscence: verifyLiscence
	}
});