import React from 'react';
import {Loader} from '../Components/Loader/Loader.js'
import CookieHandler from './CookieHandler.js'
import {notificationObj} from '../../Global/NotificationHandler.js';
import { LoginContext } from '../../../Context/LoginContext/LoginContext.js';

const axios = require('axios');
const Promise = require('promise');

function dataUIWrapper(MainComponent, options) {
	
	const {skipWrapping, dispatchers, featchers, noWrap, noLoading, skipError} = (options || {})

	return function(props){
	
		const {fetchingData=featchers, dispatcher=dispatchers, skipContainer=noWrap, skipLoading=noLoading} = props;

		const {tokenKey} = React.useContext(LoginContext)
		
		const [dataObject, setDataObject] = React.useState({});
		const [dataLoading, setDataLoading] = React.useState(true);
		const [firstFetched, setFirstFetched] = React.useState(false);		
		
		const handleErrorResponse = React.useCallback(function(e){
			setDataLoading(false);
			if(skipError){

			}
			if(e.response && e.response.status === 400){
				notificationObj.showNotification('error','Authentication Error, Reloading Page.');
				setTimeout(function(){
					window.location.reload()
				},100)	
			}else if(skipError){
				return;
			}else if(e.response && e.response.data && e.response.data.message){
				notificationObj.showNotification('error',e.response.data.message);
			}else{
				notificationObj.showNotification('error','Something went wrong, operation may not be successful');
			}
		},[skipError])
		
		const refetech = React.useCallback(function(){
			setDataLoading(true);
			setFirstFetched(true);

			var _fetchingData = (fetchingData || []);

			if(!tokenKey || !CookieHandler.verifyTokenKey(tokenKey)){			
				return;
			}
			
			var promiseArray = _fetchingData.map(function(_f){
				if(_f.preprocessor){
					_f = _f.preprocessor({
						..._f
					});
				}
				var obj = {
					..._f,
					headers: {
						..._f.headers,
						Authorization: 'Bearer '+ tokenKey
					}
				};
				
				return axios(obj);
				
			});
		
		
			Promise.all(promiseArray)
				.then(function (results) {
					
					var _dataObj = {};
					_fetchingData.forEach(function(_data, idx){
						
						if(_fetchingData[idx].postProcessor){
							_fetchingData[idx].postProcessor(_dataObj, results[idx].data);
						}else{
							_dataObj[_data.key] = results[idx].data;
						}
					});
					
					setDataObject(_dataObj);
					setDataLoading(false);
					
				}).catch(handleErrorResponse);

		},[tokenKey, fetchingData, handleErrorResponse]);
		
		React.useMemo(function(){
			if(!firstFetched){
				refetech();	
			}
		},[refetech, firstFetched])
		
		
		const dispatchRequest = React.useCallback(function(_dispatcher, data){
			
			if(!tokenKey || !CookieHandler.verifyTokenKey(tokenKey)){			
				return;
			}
			
			var dispatcherObj = (dispatcher || {})[_dispatcher];
			if(dispatcherObj){
				setDataLoading(true);
				var _data = data || {};
				var obj = {
					..._data, 
					...dispatcherObj,
					headers: {
						..._data.headers,
						Authorization: 'Bearer '+ tokenKey
					}
				}
				
				if(dispatcherObj.preprocessor){
					obj = dispatcherObj.preprocessor({
						...obj
					});
				}
				var request;
				
				if(dispatcherObj.method === 'delete'){
					request = axios.delete(obj.url, obj);
				}else{
					request = axios(obj);
				}
				request.then(function(e){
					if(dispatcherObj._showMessage!==false && e.data && e.data.message){
						notificationObj.showNotification('success',e.data.message);
					}
					setDataLoading(false);					
				}).catch(handleErrorResponse);
				
				return request;
			}
			
		},[dispatcher, tokenKey, handleErrorResponse]);
		

		if(skipWrapping){
			return ( <MainComponent {...props} {...dataObject}  dispatchRequest = {dispatchRequest} refetch={refetech}/> );
		}
		
		if(skipContainer){
			return (
				<>
					{(dataLoading &&  <Loader/>)}
					<MainComponent {...props} {...dataObject}  dispatchRequest = {dispatchRequest} refetch={refetech}/>
				</>
			)
		}

		return (
			<div className={skipLoading?"":"full-container wait-wrapper"}>
				{(dataLoading &&  <Loader/>)}
				<MainComponent {...props} {...dataObject}  dispatchRequest = {dispatchRequest} refetch={refetech}/>
			</div>
		)
	
	}
}

export {dataUIWrapper}
