import React from 'react';
import { newAgeFormConfig } from '../Configs/NewAgeFormConfig';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import Radio from '@material-ui/core/Radio';

const FormRadioGroup = function(props){

    const {id, value=false, orientation="vertical", items=[], onChange} = props;


    const _handleChange = React.useCallback(function(value){
        
        onChange({
            value: value
        });

    },[onChange]);

    const _renderedRadios = React.useMemo(function(){

           
        
        return items.map(function(_item){

            var _renderedTooltip=null;
            if(_item.tooltip){
                _renderedTooltip = ( <Tooltip title={_item.tooltip}>
                            <InfoIcon/>
                        </Tooltip> )
            }

            return (<div className="app-flex-col app-form-radio-wrapper" onClick={()=>_handleChange(_item.value)}>
                        <div className="app-flex-col">
                            <Radio key={_item.value} checked={value===_item.value} name={id}/>
                            {_item.text}
                        </div>
                        {_renderedTooltip}
                    </div>)
        })

    },[items, _handleChange, value, id]);



    
    return (<div id={id} className={"app-form-radio-group app-radiogroup-"+orientation} >
             {_renderedRadios}
            </div>);
}


newAgeFormConfig.pluginComponent('FormRadioGroup', FormRadioGroup);