export default [
    [
        
        {
			objectKey: 'send_for_approval',
			widget: 'FormSwitch',
			text: 'Send for Approval',
			tooltip: 'Send for Approval will change approval status to Pending for approval',
			viewValueParser: {
				parser: 'switchValue',
				onValue: 'Yes',
				offValue: 'No'
			}
		},
        {
            objectKey: 'strategy_vor',
            widget: 'FormGridComponent',
            widgetProps: {
                columns: [
                    { 	
                        field: 'statement', 
                        headerName: 'Strategy Statement', 
                        width: 600,
                        minWidth: 600,
                        flex: 1
                    },
                    { 	
                        field: 'roi', 
                        headerName: 'Value of Return', 
                        width: 300,
                        flex: 0,
                        editable: {
                            isEditable: function(){
                                return true;
                            },
                            widget: 'FormTextInput',
                            widgetProps: {
                                type: 'number'
                            }
                        }
                    }
        
                ]
            }
        }
    ]
]