import React from 'react';
import { dataUIWrapper } from '../../../../../../Common/DataUIWrapper/dataUIWrapper.js';
import { PageHeader } from '../../../../../../Common/Components/PageHeader/PageHeader.js';
import { ListGrid } from '../../../../../../Common/Components/ListGrid/ListGrid.js';
import getMasterList from '../../Utils/fetchers/getMasterList.js';
import { dateValueParser } from '../../../../../../Common/ApplicationFormBuilder/Parsers/dateValueParser.js';
import { AppDownloadIcon } from '../../../../../../Common/Icons/AppDownloadIcon.js';
import downloadComputation from '../../Utils/dispatchers/downloadComputation.js';
import parseAndDownloadCSV from './parseAndDownloadCSV.js';


const ComputationList = function(props){

	const { dispatchRequest, serverData=[]} = props;

	const _customIconsRenderer = React.useCallback(function(_rowProps){
		const {status, name} = _rowProps;
		if(status === 'Completed'){
			return (<AppDownloadIcon onClick={function(){
				dispatchRequest('download', {model_name: name} ).then(function(e){
					parseAndDownloadCSV(e.data, name)
				});
			}}/>)
		}

	},[dispatchRequest]);

	return (<PageHeader
				title={'ECL Computations List'}
				currentPage={'ECL Computations List'}
				buttonConfig={{
					url: '/ecl/computations/create/',
					label: 'Create ECL Computations'
				}}
			>
				<ListGrid
					data={serverData}
					nameField={"configuration_name"}
					nameTitle={"Configuration Name"}
					editList={[]}
					deleteList={[]}
					viewUrl="/ecl/computations/view/"
					approvalStatusField='Job_status'
					customIconRenderer={_customIconsRenderer}
					approvalStatusHeader='Job Status'
					columns={[
						{ field: 'scenario_definition_name', headerName: 'Scenario Definition Name', width: 280, index: 1},
						{ field: 'reporting_time', headerName: 'Reporting Date', width: 200, index: 3,
							valueFormatter: (params) => {
								return dateValueParser(params.value,{});
							}
						},
						{ field: 'Job_comments', headerName: 'Job Comments', width: 500, index: 4},
					]}
					purgeColumns={[7,7,7,7]}
				/>
			</PageHeader>);
}

export default dataUIWrapper(ComputationList, {
								dispatchers:{
									download: downloadComputation
								},
								featchers: [
									getMasterList
								]
							});