var mainUrl=window.riskMac.mainURL;


export default {	
		login: mainUrl+'/rest-auth/login/',
        otpverify: mainUrl+'/logintokenverification/',
        getuserinfo: mainUrl+'/getUserInfo/',
        logout: mainUrl+'/logout/',
		getLiscenceArray: mainUrl+'/getLicensekeyInformation/'
}


