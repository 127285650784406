import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { newAgeFormConfig } from '../Configs/NewAgeFormConfig';
import { FormGridComponent } from './FormGridComponent';
import AppBar from '@material-ui/core/AppBar';

const FormTabsGridComponent = function(props){
    const {tabField, dataField, value=[], onChange, ...extProps} = props;

    const [selected, setSelected] = React.useState(0) 

    const handleScenerioChange = React.useCallback(function(e, newValue){
		setSelected(newValue);
	},[]);

    const _tabs = React.useMemo(function(){
        return value.map(function(_obj){
            return (<Tab label={_obj[tabField]} key={_obj[tabField]}/>)
        });
    },[value]);

    const gridRows = React.useMemo(function(){
        if(value[selected]){
            return value[selected][dataField] || [];
        }
        return [];
    },[value, selected, dataField]);

    const _handleGridValueChange = React.useCallback(function(obj){
        var newValue = obj.value,
            _value = window.structuredClone(value);
        
        _value[selected][dataField] = newValue

        onChange({
            value: _value
        });

    },[selected, value, onChange, dataField]);

    const _finalSelected = React.useMemo(function(){
        if(value.length && selected > value.length-1){
            setSelected(value.length-1);
            return value.length-1;
        }
        return selected;
    },[value, selected])

    return (<div className="app-form-tabs-grid app-flex">
                <AppBar position="static" className="card-tabs">
                    <Tabs value={_finalSelected} onChange={handleScenerioChange} aria-label="simple tabs example">
                        {_tabs}
                    </Tabs>
                </AppBar>
                <div className="app-full-container">
                    <FormGridComponent {...extProps} value={gridRows} onChange={_handleGridValueChange}/>
                </div>
            </div>)
}


newAgeFormConfig.pluginComponent('FormTabsGridComponent', FormTabsGridComponent);