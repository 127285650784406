var configControl = {
	config: [
		[
			{
				objectKey: 'control',
				widget: 'FormTextInput',
				text: 'Controls'
			},
            
            {
				objectKey: 'control_cost',
				id: 'Control_cost',
				widget: 'FormTextInput',
				text: 'Control Cost',
			}
		],[
			{
				objectKey: 'control_status',
				id: 'Control_Status',
				widget: 'FormDropDown',
				text: 'Control Status',				
				data: [ ]
			},
			{
				objectKey: 'control_effectiveness',
				id: 'control_effectiveness',
				widget: 'FormDropDown',
				text: 'Control Effectiveness',				
				data: [ ]
			},
			{
				objectKey: 'person_responsible_control',
				id: 'Person responsible Control',
				widget: 'FormDropDown',
				text: 'Person responsible',	
				data: [ ]	
			}
		]

	]
}


export {
	configControl
}