import React from 'react';
import { dataUIWrapper } from '../../../../../../Common/DataUIWrapper/dataUIWrapper.js';
import { PageHeader } from '../../../../../../Common/Components/PageHeader/PageHeader.js';
import getModelData from '../../Utils/fetchers/getModelData.js';
import { NewAgeFormBuilder } from '../../../../../../Common/ApplicationFormBuilder/FormBuilder/NewAgeFormBuilder.js';
import { NewAgeFormConfig } from '../../formJSON/newAgeConfig.js';
import getReport from '../../Utils/dispatchers/getReport.js';
import { RMCard } from '../../../../../../Common/Components/WrappedComponents/RMCards/RMCards.js';
import { CardBarChart } from '../../../../../../Common/Components/Charts/CardBarChart.js';

const EasyECLReport = function(props){

	const [value, setValue] = React.useState({});
	const [componentExts, setComponentExts] = React.useState({});

	const {dispatchRequest, models=[]} = props;
	const {report_grid, model_name} = value

	React.useEffect(function(){
		setComponentExts(function(ogExtras){
			return ({
				...ogExtras,
				model_name: {
					data: models
				}
			})
		})
	},[models]);

	const _reportValue = React.useMemo(function(){
		if(!report_grid || !report_grid.length){
			return null;
		}

		const _cards = report_grid.map(function(_obj, idx){
			_obj.ecl_twelve_month_amount = Number(_obj.ecl_twelve_month_amount);
			_obj.lifetime_ecl_amount = Number(_obj.lifetime_ecl_amount);
			_obj.title = ' ';
			return (<CardBarChart
						key={_obj.id || idx}
						headers={
							[
								{
									title: "Configuration Name",
									value: _obj.configuration_name
								},
								{
									title: "Scenario Definition",
									value: _obj.scenario_definition_name
								},
								{
									title: "Model Definition",
									value: _obj.model_definition_name
								},
							]
						}
						series={[
							{
								name: '12-Month ECL',
								value: "ecl_twelve_month_amount",
								argumentField:"title" 
							},
							{
								name: 'Lifetime ECL',
								value: "lifetime_ecl_amount",
								argumentField:"title" 
							}
						]}
						data={[_obj]}
					/>)
		})

		return (<React.Fragment>
					<RMCard
						header={"Config Wise Data ( "+model_name+" )"}
					>
						<div className="rm-inline-chart-container">
							{_cards}
						</div>
					</RMCard>
					
				</React.Fragment>)


	},[report_grid, model_name]);

	return (<PageHeader
				title={'Model Wise ECL Report'}
				previousLinks={[]}
				currentPage={'Report View'}
			>
				<NewAgeFormBuilder 
					config={NewAgeFormConfig} 
					value={value}
					onChange={setValue}
					dispatchRequest={dispatchRequest}
					componentExts={componentExts}
					onUpdateComponentExts={setComponentExts}
					disableSave={true}
				>
					
					{_reportValue}
				</NewAgeFormBuilder>
			</PageHeader>);
}

export default dataUIWrapper(EasyECLReport, {
								dispatchers:{
									getReport: getReport
								},
								featchers: [
									getModelData
								]
							});