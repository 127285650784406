import React from 'react';
import { StatusCell } from "../../../../Common/Components/ListGrid/StatusCell";

const NewAgeFormConfig = {
	layoutItems: [
		{
			stepperName: ' ',
			itemsLayout : [ 
				{
					items : [
						{
							header: 'User Info',
							className: 'user-info-form-wrapper',
							componentConfig: [
								[
									{
										objectKey: 'user-icon',
										widget: 'FormAccountCircle',
									}
								],
								[
									
									{
										objectKey: 'username',
										widget: 'FormTextInput',
										text: 'Username',
										editableModes: []										
									},
									{
										objectKey: 'first_name',
										widget: 'FormTextInput',
										text: 'First Name',
										editableModes: []										
									},
									{
										objectKey: 'last_name',
										widget: 'FormTextInput',
										text: 'Last Name',
										editableModes: []										
									},
									{
										objectKey: 'email',
										widget: 'FormTextInput',
										text: 'Email',
										editableModes: []										
									}
								]
							]
						},
					
					]
				},
				{
					items: [
						{
							header: 'Liscence Info',
							componentConfig: [
								[
									{
										objectKey: 'liscences',
										widget: 'FormGridComponent',
										widgetProps: {
											columns: [
												{ 	
													field: 'name', 
													headerName: 'Product Name', 
													width: 300,
													minWidth: 300,
													flex: 1
												},
												{ 
													field: 'verificationStatus', 
													headerName: 'Liscence Status', 
													width: 200,
													renderCell: function(params){
														  return (<StatusCell status={params.row.verificationStatus} />);
													}
												},
												{ 	
													field: 'days', 
													headerName: 'Days Remaining', 
													width: 600,
													minWidth: 600,
												}
									
											]
										}
									}
								]
							]
						}
					]
				}	
			]

		},
        
	]

}


export {NewAgeFormConfig}