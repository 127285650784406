import React from 'react';
import { dataUIWrapper } from '../../../../../../Common/DataUIWrapper/dataUIWrapper.js';
import { PageHeader } from '../../../../../../Common/Components/PageHeader/PageHeader.js';
import getRAS from '../../Utils/fetchers/getRAS.js';
import { FullPageCardGrid } from '../../../../../../Common/Components/FullPageCardGrid/FullPageCardGrid.js';

const columns = [
	{
		field: 'risk_category', headerName: 'Risk Category',width: 300
	}, {
		field: 'risk_appetite_level', headerName: 'Risk Appetite Level', width: 150
	}, {
		field: 'loss_tolerance_amount', headerName: 'Risk Tolerance Amount',
		type : 'number', width: 200
	}, {
		field: 'risk_Statement', headerName: 'Strategy Statement',
		width: 1500, minWidth: 1500, flex: 1
	}
];


const IRIEN = function(props){

	const { ras=[]} = props;

	return (<PageHeader
				title={'RAS Statements'}
				previousLinks={[]}
				currentPage={'RAS Statements List'}
			>
				<FullPageCardGrid rows={ras} columns={columns}/>
			</PageHeader>);
}

export default dataUIWrapper(IRIEN, {
								dispatchers:{
								},
								featchers: [
									getRAS
								]
							});