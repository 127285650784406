import APIConfig from "../../APIConfig";

export default {
    url: APIConfig.getDashboardReportByRiskValues,
    method: 'get',
    postProcessor: function(dataObj, result){
        dataObj.inherentRisk = [
            {
                title: 'Total Inherent Risk',
                value: result.Total_inherentRisk
            },
            {
                title: 'Total Residual Risk',
                value: result.Total_residualRisk
            }
        ]

        dataObj.controlRisk = [
            {
                title: 'Risk Control Cost',
                value: result.Total_riskControlCost
            },
            {
                title: 'Risk Treatment Cost',
                value: result.Total_riskTreatmentCost
            }
        ];

        dataObj.listsData = {
            inherentRisk: result.Top_inherentRisk,
            residualRisks: result.Top_residualRisk
        }
    }
}