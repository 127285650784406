import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { Link} from "react-router-dom";
import VisibilityIcon from '@material-ui/icons/Visibility';

const AppViewIcon = function(props){

    const {link='#', onClick, title="View"} = props;

    return (<Link to={link}>
                <IconButton title={title} variant="contained" onClick={onClick}>
                    <VisibilityIcon  className="ecl-view-icon"/>
                </IconButton>
            </Link>)
}

export {AppViewIcon}