import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { Link} from "react-router-dom";
import UploadFileIcon from '@mui/icons-material/UploadFile';

const AppUploadIcon = function(props){

    const {link='#', onClick, title="Upload"} = props;

    return (<Link to={link}>
                <IconButton title={title} variant="contained" onClick={onClick}>
                    <UploadFileIcon className="ecl-upload-icon"/>
                </IconButton>
            </Link>)
}

export {AppUploadIcon}