import React from 'react';
import { dataUIWrapper } from '../../../../../../Common/DataUIWrapper/dataUIWrapper.js';
import { PageHeader } from '../../../../../../Common/Components/PageHeader/PageHeader.js';
import { notificationObj } from '../../../../../../Global/NotificationHandler.js';
import { ListGrid } from '../../../../../../Common/Components/ListGrid/ListGrid.js';
import getMasterList from '../../Utils/fetchers/getMasterList.js';
import deleteDispatchers from '../../Utils/dispatchers/deleteDispatchers.js';
import { AppUploadIcon } from '../../../../../../Common/Icons/AppUploadIcon.js';

const DSSList = function(props){

	const { dispatchRequest, refetch, serverData=[]} = props;

	const handleDelete = React.useCallback(function(_row){
		
		notificationObj.showConfirmBox({
			description: 'Do you want to delete?',
			ok: function(){					
				dispatchRequest('delete', {
					name: _row['conf_name']
				}).then(function(){
					refetch();
				});
			}
		});
		
	},[dispatchRequest]);

	const _uploadIconRenderer = React.useCallback(function(_props){
		const {status, name} = _props;
		
		if(status==='Draft' || status==='Rejected'|| status==='Approved'){
			return (<AppUploadIcon link={"/decision-support-system/upload-files/"+name} />)
		}
		return null;

	},[])


	return (<PageHeader
				title={'Decision Support System List'}
				currentPage={'Decision Support System List'}
				buttonConfig={{
					url: '/irien/decision-support-system/create/',
					label: 'Create Decision Support System'
				}}
			>
				<ListGrid
					data={serverData}
					nameField={"conf_name"}
					nameTitle={"Configuration Name"}
					editList={["Draft","Rejected","Approved"]}
					deleteList={["Draft","Rejected"]}
					onDelete={handleDelete}
					viewUrl="/irien/decision-support-system/view/"
					editUrl="/irien/decision-support-system/edit/"
					customIconRenderer={_uploadIconRenderer}
				/>
			</PageHeader>);
}

export default dataUIWrapper(DSSList, {
								dispatchers:{
									delete: deleteDispatchers
								},
								featchers: [
									getMasterList
								]
							});