import React from 'react';
import { AppDownloadIcon } from "../../../../../Common/Icons/AppDownloadIcon";
import { downloadText } from "../../../../../Common/Utils/downloadText";
import { wrapObjectInFormData } from '../../../../../Common/Utils/wrapObjectInFormData';

const NewAgeFormConfig = {
	layoutItems: [
		{
			stepperName: ' ',
			itemsLayout : [
				{
					items : [
						{
							header: 'Computation Details',
							componentConfig: [
								[
									{
										objectKey: 'ConfigurationName',
										widget: 'FormTextInput',
										text: 'Computation Name',
										validation: [
											{
												type: 'required',
												message: 'Enter Computation Name'
											}
										]
									},{
										objectKey: 'model_name',
										widget: 'FormDropDown',
										text: 'Model Name',
										viewModes: ['create'],
										data: [],
										widgetProps: {
											dataTextField: 'model_name',
											dataValueField: 'model_name'
										},
										onAfterUpdate: function(options){
											var _data = options.context.componentExtProps._tempDefinitions.data.filter(function(_e){
												return _e.model_name===options.value
											});
											options.context.onUpdateExts({
												definition_name:{
													data: _data
												}
											});

											options.context.onUpdateValue({
												definition_name: undefined
											});
										},
										validation: [
											{
												type: 'required',
												message: 'Enter Model Name'
											}
										]
										
									},
									{
										objectKey: 'definition_name',
										widget: 'FormDropDown',
										text: 'Model Definition Name',
										data: [],
										viewModes: ['create'],
										widgetProps: {
											dataTextField: 'model_definition_name',
											dataValueField: 'model_definition_name'
										},
										isDisabled: function(context){
											return !context.componentValue.model_name;
										},
										validation: [
											{
												type: 'required',
												message: 'Enter Model Definition Name'
											}
										]
										
									},
									
									{
										objectKey: 'reporting_time',
										widget: 'FormDateTimeInput',
										text: 'Reporting Date',
										validation: [
											{
												type: 'required',
												message: 'Enter Reporting Date'
											}
										]
									},
									{
										objectKey: 'csv_file_name',
										widget: 'FormFileInput',
										viewModes: ['create'],
										text: function(context){
											
											var definitionName = context.componentValue.definition_name;
											const _download = function(){
												context.dispatchRequest('getCSV', {model: definitionName}).then(function(e){
													downloadText('sample_configuration_data.csv', e.data)
												})
											}

											return (<span>
														Download Sample CSV, Fill Data, and Upload CSV using Choose File Option
														{definitionName && (<AppDownloadIcon title="Download Sample CSV" onClick={_download} />)}
													</span>)
										},
										validation: [
											{
												type: 'required',
												message: 'Upload file'
											}
										]
									},
									
									{
										objectKey: '_separator',
										widget: 'Separator',
										viewModes: ['view']
									},
									
									{
										objectKey: 'Job_status',
										widget: 'FormFileInput',
										text: 'Job Status',
										viewModes: ['view']
									}
								],
								[
									{
										objectKey: 'Job_comments',
										widget: 'FormFileInput',
										text: 'Job Comments',
										viewModes: ['view']
									},
									{
										objectKey: '_separator',
										widget: 'Separator',
										viewModes: ['view']
									},
									{
										objectKey: 'Job_Starttime',
										widget: 'FormFileInput',
										text: 'Job Start Time',
										viewModes: ['view'],
										viewValueParser: {
											parser: 'dateValueParser'
										}
									},
									{
										objectKey: 'Job_Completedtime',
										widget: 'FormFileInput',
										text: 'Job Completion Time',
										viewModes: ['view'],
										viewValueParser: {
											parser: 'dateValueParser'
										}
									}

								]
							]
						},
						
					]
				},
				{
					viewModes: ['view'],
					items : [
						{
							header: 'User Details',
							componentConfig: [
								[
									{
										objectKey: 'created_user',
										widget: 'FormTextInput',
										editableModes: [],
										text: 'Created User'
									},	
									{
										objectKey: 'created_time',
										widget: 'FormTextInput',
										editableModes: [],
										text: 'Created Time',
										viewValueParser: {
											parser: 'dateValueParser'
										}
									},
								],
								[
									{
										objectKey: 'created_user_ip_address',
										widget: 'FormTextInput',
										editableModes: [],
										text: 'IP Address'
									}
								]
							]
						}
					
					]
				}
			],
            stepValidation: function(value){

            }

		},
        
	],
	finalSaveParsing: function(_value){
		return wrapObjectInFormData(_value);
	},
	defaultValue: {
		"send_for_approval": false
	}

}


export {NewAgeFormConfig}