import React from 'react';
import { RMCard } from '../../../../../../Common/Components/WrappedComponents/RMCards/RMCards';
import List from '../../../../../../Common/Components/Charts/ListChart';
import { SimplePieChart } from '../../../../../../Common/Components/Charts/SimplePieChart';
import { BarChart } from '../../../../../../Common/Components/Charts/BarChart';

function IRIENDashboardView(props) {

	const {inherentRisk, controlRisk, listsData={}, deptWiseRating, riskStatusCount} = props;

	return (
		<div className="app-form-cards-container app-full-overflow">
			<div className="app-cards-columns-wrapper">
				<RMCard variant="h5" header="Inherent and Residual Risk">
					<SimplePieChart data={inherentRisk} />
				</RMCard>
				<RMCard variant="h5" header="Inherent and Residual Risk">
					<SimplePieChart data={controlRisk} />
				</RMCard>
			</div>
			<div className="app-cards-columns-wrapper">
				<RMCard variant="h5" header="Top 5 Inherent Risks">
					<List data={listsData.inherentRisk} />
				</RMCard>
				<RMCard variant="h5" header="Top 5 Residual Risks">
					<List data={listsData.residualRisks} />
				</RMCard>
			</div>
			<div className="app-cards-columns-wrapper">
				<RMCard variant="h5" header="Organization Division/Department Wise Risk Rating">
					<BarChart
						data={deptWiseRating}
						series={
							[
								{
									name: 'Retired',
									value: 'Retired'
								},{
									name: 'Ongoing',
									value: 'Open'
								},{
									name: 'Closed',
									value: 'Closed'
								},
							]
						}
					/>
				</RMCard>
			</div>
			<div className="app-cards-columns-wrapper">
				<RMCard variant="h5" header="Organization Division/Department Wise Risk Rating">
					<BarChart
						data={riskStatusCount}
						series={
							[
								{
									name: 'Very High',
									value: 'VeryHigh'
								},{
									name: 'High',
									value: 'High'
								},{
									name: 'Medium',
									value: 'Medium'
								},
								{
									name: 'Low',
									value: 'Low'
								},
							]
						}
					/>
				</RMCard>
			</div>
		</div>
	);
}

export default IRIENDashboardView;