import APIConfig from "../../APIConfig";
import { parseServerObject } from "../serverObjectParser";

export default {
    url: APIConfig.getChartsData,
    method: 'get',
    postProcessor: function(dataObj, result){
        if(result){
            dataObj.chartData=parseServerObject(result);
        }
    }
}