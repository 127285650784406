import APIConfig from "../../APIConfig";

export default {
    url: APIConfig.getRiskFactorCountReport,
    method: 'get',
    postProcessor: function(dataObj, result){
        dataObj.riskStatusCount = Object.keys(result.Divisions).map(function(_k){
            return {
                text: _k,
                High: result.Divisions[_k].High,
                Low: result.Divisions[_k].Low,
                Medium: result.Divisions[_k].Medium,
                VeryHigh: result.Divisions[_k]['Very High']
            }
        })
       
    }
}