import APIConfig from "../../../APIConfig";

export default {
    url: APIConfig.getMisssionGoals,
    method: 'get',
    key: 'goals',
    postProcessor: function(dataObj, result){
        if(result){
            dataObj.goals = result.map(function(_row){
                return {
                    missiongoal: _row.mission_goal,
                    Weight: 0
                }
            });
        }
    },
    valueObject: ["MissionGoalWeight","RASObjective"]
}