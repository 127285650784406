import controlAnalysisConfig from "./controlAnalysisConfig.js";
import organizationDetailConfig from "./organizationDetailConfig.js";
import residualRiskConfig from "./residualRiskConfig.js";
import riskAnalysisConfig from "./riskAnalysisConfig.js";
import riskEvaluationConfig from "./riskEvaluationConfig.js";
import riskIdentificationConfig from './riskIdentificationConfigs.js';
import riskTreatementConfig from "./riskTreatementConfig.js";
import {getHistoryObjectPerMode} from "../../../../../Common/FormConfig/ApprovalHistoryObject.js";


const NewAgeFormConfig = {
	layoutItems: [
		{
			stepperName: 'Organization Details',
			itemsLayout : [
				{
					items : [
						{
							header: 'Organization Details',
							componentConfig: organizationDetailConfig
						}
					
					]
				},
				...getHistoryObjectPerMode(["edit"])
			],
            stepValidation: function(value){

            }
		},
		{
			stepperName: 'Risk Identification',
			itemsLayout : [
				{
					items : [
						{
							header: 'Risk Identification',
							componentConfig: riskIdentificationConfig
						}
					
					]
				}		
			],
            stepValidation: function(value){

            }

		},
		{
			stepperName: 'Risk Analysis',
			itemsLayout : [
				{
					items : [
						{
							header: 'Risk Analysis',
							componentConfig: riskAnalysisConfig
						}
					
					]
				}		
			],
            stepValidation: function(value){

            }

		},
		
		{
			stepperName: 'Control Analysis',
			itemsLayout : [
				{
					items : [
						{
							header: 'Control Analysis',
							componentConfig: controlAnalysisConfig
						}
					
					]
				}		
			],
            stepValidation: function(value){

            }

		},
		{
			stepperName: 'Risk Evaluation',
			itemsLayout : [
				{
					items : [
						{
							header: 'Risk Evaluation',
							componentConfig: riskEvaluationConfig
						}
					
					]
				}		
			],
            stepValidation: function(value){

            }

		},
		{
			stepperName: 'Risk Treatment',
			itemsLayout : [
				{
					items : [
						{
							header: 'Risk Treatment',
							componentConfig: riskTreatementConfig
						}
					
					]
				}		
			],
            stepValidation: function(value){

            }

		},
		{
			stepperName: 'Residual Risk',
			itemsLayout : [
				{
					items : [
						{
							header: 'Residual Risk',
							componentConfig: residualRiskConfig
						}
					
					]
				},
				{
					viewModes: ['view','approve'],
					items: [
						{
							header: 'Uploaded Documents',
							componentConfig : [
								[
									{
										editableModes: [],
										objectKey: 'form_documents',
										widget: 'FormDocument',
										widgetProps: {
											moduleName: 'erm',
											columns: [
												{
													field: 'iteminfo',
													headerName: 'Description',
													width: 700
												}
											]
										}
									}
								]
							]
						}
					]

				},
				...getHistoryObjectPerMode(['view','approve'])
			],
            stepValidation: function(value){

            }

		},
	],
	finalSaveParsing: function(_value){
		
		return _value;
	},
	defaultValue: {
		"send_for_approval": false
	}

}


export {NewAgeFormConfig}