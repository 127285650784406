import React from 'react';
import { ComponentRenderer } from './ComponentRenderer';

const ComponentList = function(props){

    const {list} = props;

    const _renderedList = React.useMemo(function(){

        return list.map(function(_compObj, idx){
            return (<ComponentRenderer key={_compObj.objectKey || idx} {..._compObj}/>)
        })

    },[list])

    return (<div className={"ecl-form-builder-column"}>
                {_renderedList}
            </div>)

}

const ComponentLayout = function(props){

    const {components=[], className} = props;


    const _columns = React.useMemo(function(){
        
        return components.map(function(_componentList, idx){
            return (<ComponentList key={idx} list={_componentList} />)
        });

    },[components]);

    return (<div className={"ecl-form-builder-wrapper "+(className?className:'')}>
                {_columns}
            </div>)

}

export {ComponentLayout}

//+(props.mode==='view'?" form-read-only":'')