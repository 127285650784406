import ApprovalHistoryObject from "../../../../../Common/FormConfig/ApprovalHistoryObject";
import SendForApprovalSwitch from "../../../../../Common/FormConfig/SendForApprovalSwitch";

const ModelDefinitionConfig = [
	[
		{
			objectKey: 'model_definition_name',
			widget: 'FormTextInput',
			text: 'Definition Name',
			editableModes: ['create'],
			validation: [
				{
					type: 'required',
					message: 'Enter model definition name'
				}
			]
		},
		{
			objectKey: 'model_name',
			widget: 'FormDropDown',
			editableModes: ['create'],
			text: 'Model Name',
			data: [],
			widgetProps: {
				dataTextField: 'model_name',
				dataValueField: 'model_name'
			},
			onAfterUpdate: function(options){
				options.dispatchRequest('fetchVariables', {model_name: options.value} ).then(function(e){
					
					var _variables = e.data[1].filter(function(_var){
						return _var.variable_name && _var.variable_type;
					});

					options.context.onUpdateValue({
						variables: _variables
					 })
				});	
			},
			onExplicitEditUpdate: function(options){

				var variableMap = {}
				options.context.componentValue.variables.forEach(function(e){
					variableMap[e.variable_id] = e
				})

				options.dispatchRequest('fetchVariables', {model_name: options.value} ).then(function(e){

					var variableData = e.data[1].filter(function(_var){
						return _var.variable_name && _var.variable_type;
					});

					variableData.forEach(function(_var){
						var ogVariable = variableMap[_var.variable_id];
						if(ogVariable){
							_var.variable_selected_yn = ogVariable.variable_selected_yn;
							_var.variable_selected_value = ogVariable.variable_selected_value;
						}
					});

					options.context.onUpdateValue({
						variables:   variableData
					 })
				});	
			},
			validation: [
				{
					type: 'required',
					message: 'Enter model name'
				}
			]
		},
		
		{
			objectKey: 'model_type',
			widget: 'FormTextInput',
			text: 'Model Type',
			validation: [
				{
					type: 'required',
					message: 'Select model type'
				}
			]
		}		
		
	],
	[
	
		{
			objectKey: 'intercept_y_value',
			widget: 'FormTextInput',
			text: 'Intercept Y Value',
			tooltip: 'Any numeric value, positive or negative',
			widgetProps: {
				type: "number",
			},
			validation: [
				{
					type: 'required',
					message: 'Enter intercept Y value'
				}
			]
		},
		SendForApprovalSwitch
		
	]
]


const variableGridConfig = {
	objectKey: 'variables',
	widget: 'FormGridComponent',
	widgetProps: {
		className: 'variable-grid',
		columns: [
			{ 	
				field: 'variable_id', 
				headerName: 'Variable ID', 
				width: 300
			},
			{ 
				field: 'variable_name', 
				headerName: 'Variable Name', 
				width: 300
			},
			{ 
				field: 'variable_type', 
				headerName: 'Variable Type', 
				width: 185
			},
			{ 
				field: 'variable_selected_yn', 
				headerName: 'Select Variable',  
				width: 200,
				cellClassName: 'ecl-checkbox-col',
				viewValueParser: {
					parser: 'checkboxValue',
					trueValue: 'Yes',
					falseValue: 'No',
					componentTrueValue: 'TRUE',
					componentFalseValue: 'FALSE',
				},
				editable: {
					isEditable: function(){
						return true;
					},
					widget: 'FormCheckbox',
					widgetProps: {
						trueValue: 'TRUE',
						falseValue: 'FALSE'
					}
				}
			},
			{ 
				field: 'variable_selected_value', 
				headerName: 'Variable Value (Numeric - Positive/negative value, Categorical - MIN = 0, MAX = 20)',  
				width: 500, 
				type: 'number',
				editable: {
					isEditable: function(param){
						return param.row.variable_selected_yn==='TRUE';
					},
					widget: 'FormTextInput',
					widgetProps: {
						type: "number",
					},
					valueParser: function(value, params){
						return params.row.variable_selected_yn === "TRUE"?value:'';
					}
				},
			},

		]
	},
	validation: [
		{
			type:'custom',
			fn: function(value){
				value = value || [];
				var message;
				value.forEach(function(_row){
					if(message){
						return;
					}
							
					if(_row.variable_selected_yn === "TRUE" && typeof _row.variable_selected_value==='undefined'){					
						message = 'Enter variable value for selected variables';
					}else if(_row.variable_type === "categorical" && ( _row.variable_selected_value > 20 || _row.variable_selected_value < 0 )){
						message = 'Categorical value should be between 0 to 20';
					}
				});

				return message;
			}
		}
	],
	saveParsing: function(value){
		return (value || []).map(function(_d){
			return {
					id: _d.id,
					"variable_id": _d.variable_id,
					"variable_name": _d.variable_name,
					"variable_type":  _d.variable_type,
					"variable_selected_value": _d.variable_selected_yn === "TRUE"?Number(_d.variable_selected_value):'',
					"variable_selected_yn":_d.variable_selected_yn || 'FALSE'
				}
		})
	}
}

const NewAgeFormConfig = {
	layoutItems: [
		{
			stepperName: ' ',
			itemsLayout : [
				{
					items : [
						{
							header: 'Model Definition',
							componentConfig: ModelDefinitionConfig
						}
					
					]
				},
				{
					items : [
						{
							header: 'Model Definition Variables',
							componentConfig: [
								[
									variableGridConfig
								]
							]
						}
					]
				},
				...ApprovalHistoryObject
			],
            stepValidation: function(value){

            }

		},
        
	],
	finalSaveParsing: function(_value){
		
		return _value;
	},
	defaultValue: {
		"send_for_approval": false
	}

}


export {NewAgeFormConfig}