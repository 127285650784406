var mainUrl=window.riskMac.mainURL;

export default {
		getChartsData: mainUrl+'/getDashboard/',
		getRiskCategoryCountReport: mainUrl+'/getRiskCategoryCountReport/' ,//--dashboard
		getDashboardReportByRiskValues: mainUrl + '/getDashboardReportByRiskValues/', //--dashboard
		getRiskFactorCountReport: mainUrl+'/getRiskFactorCountReport/',
}


