import { dateValueParser } from "../ApplicationFormBuilder/Parsers/dateValueParser";

export default function(data){
    return data.filter(function(_data){
        _data.id = _data.history_id;
        _data.history_date = dateValueParser(_data.history_date, {});
		
		if(_data.approval_status === 'Pending'){
			_data.approved_user_comment='';
		}
		
        return (_data.created_user_ip_address &&  _data.approval_status!=='Draft');
    }).sort(function(a, b){
        return b.history_id - a.history_id;
    })
}