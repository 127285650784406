

export default [
    {text: "RMC", 
        value: 'rmc', 
        itemTypes:[
            {value:"RMC MoM"},
            {value:"RMC Agenda"},
            {value:"RMC ATR"},
            {value:"RMC PPT"},
            {value:"Other"}
        ]},
    {text: "IRC", value: 'irc', 
        itemTypes:[
            {value:"IRC MoM"},
            {value:"IRC Agenda"},
            {value:"IRC ATR"},
            {value:"IRC PPT"},
            {value:"Other"}] 
    },
    {text: "Policies", value: 'policies', 
        itemTypes: [
            {value:"Risk Policy"},
            {value:"Non-Risk Policy"},
            {value:"Document of Procedures"},
            {value:"Decisions: memo or communication"},
            {value:"Other"}
        ]},
    {text: "Incident Management", value: 'other', itemTypes: []},
]