import React from 'react';
import { Palette } from '@devexpress/dx-react-chart';

const _paletteObj={
    status: ["#87cf87", "#f9a82f", "#46c5ff", "#d64b4b"]
}

const getPalette = function(value){
    if(_paletteObj[value]){
        return (<Palette scheme={_paletteObj[value]} />)
    }
    return null
}

export {getPalette}