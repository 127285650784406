import React from 'react';
import { dataUIWrapper } from '../../../../../../Common/DataUIWrapper/dataUIWrapper.js';
import { PageHeader } from '../../../../../../Common/Components/PageHeader/PageHeader.js';
import { NewAgeFormBuilder } from '../../../../../../Common/ApplicationFormBuilder/FormBuilder/NewAgeFormBuilder.js';
import { NewAgeFormConfig } from '../../formJSON/newAgeConfig.js';
import getReport from '../../Utils/dispatchers/getReport.js';
import getModelData from '../../Utils/fetchers/getModelData.js';
import getCostControl from '../../Utils/dispatchers/getCostControl.js';

const DSSReport = function(props){

	const [value, setValue] = React.useState({});
	const [componentExts, setComponentExts] = React.useState({});

	const {dispatchRequest, models=[]} = props;

	React.useEffect(function(){
		setComponentExts(function(ogExtras){
			return ({
				...ogExtras,
				configuration_name: {
					data: models
				}
			})
		})
	},[models]);

	return (<PageHeader
				title={'DSS Report'}
				previousLinks={[]}
				currentPage={'Report View'}
			>
				<NewAgeFormBuilder 
					config={NewAgeFormConfig} 
					value={value}
					onChange={setValue}
					dispatchRequest={dispatchRequest}
					componentExts={componentExts}
					onUpdateComponentExts={setComponentExts}
					disableSave={true}
				>
				</NewAgeFormBuilder>
			</PageHeader>);
}

export default dataUIWrapper(DSSReport, {
								dispatchers:{
									getReport: getReport,
									getCostControl: getCostControl
								},
								featchers: [
									getModelData
								]
							});