import React from 'react';
import rmIcon from './resources/rmicon-min.png';

const StaticPage = function(props){

    return (<div className="app-flex app-flex-center">
                <img className="static-page-logo" src={rmIcon} alt="Logo" />
            </div>)
}

export {StaticPage}