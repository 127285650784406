import React from 'react';
import ModelDefinitionCreate from './Routes/Create/ModelDefinitionCreate';
import ModelDefinitionEdit from './Routes/Edit/ModelDefinitionEdit';
import ModelDefinitionView from './Routes/View/ModelDefinitionView';
import ModelDefinitionApprove from './Routes/Approve/ModelDefinitionApprove';
import ModelDefinitionApprovalList from './Routes/ApprovalList/ModelDefinitionApprovalList';
import ModelDefinitionList from './Routes/List/ModelDefinitionList';
import { Route} from 'react-router-dom';	


function ModelDefinitionRoutes() {
	
	
	return (<React.Fragment>
                <Route path="/rating/model-definition/list">
                    <ModelDefinitionList />
                </Route>
                <Route path="/rating/model-definition/approve-list">
                    <ModelDefinitionApprovalList />
                </Route>
                <Route path="/rating/model-definition/create">
                    <ModelDefinitionCreate />
                </Route>
                <Route path="/rating/model-definition/edit/:id">
                    <ModelDefinitionEdit />
                </Route>
                <Route path="/rating/model-definition/view/:id">
                    <ModelDefinitionView />
                </Route>
                <Route path="/rating/model-definition/approve/:id">
                    <ModelDefinitionApprove/>
                </Route>
            </React.Fragment>);
}

export default ModelDefinitionRoutes;
