import React from 'react';
import { dataUIWrapper } from '../../../../../../Common/DataUIWrapper/dataUIWrapper.js';
import { NewAgeFormBuilder } from '../../../../../../Common/ApplicationFormBuilder/FormBuilder/NewAgeFormBuilder.js';
import { useHistory } from 'react-router-dom';
import { NewAgeFormConfig } from '../../formJSON/newAgeConfig.js';
import { PageHeader } from '../../../../../../Common/Components/PageHeader/PageHeader.js';
import { notificationObj } from '../../../../../../Global/NotificationHandler.js';
import MasterRecord from '../../Utils/fetchers/MasterRecord.js';
import EditDispatchers from '../../Utils/dispatchers/EditDispatchers.js';
import getModelMasterData from '../../Utils/dispatchers/getModelMasterData.js';
import getModelMaster from '../../Utils/fetchers/getModelMaster.js';
import getClientFields from '../../Utils/fetchers/getClientFields.js';

const ModelDefinitionEdit = function(props){

	const [value, setValue] = React.useState({});
	const [componentExts, setComponentExts] = React.useState({});

	const history = useHistory();

	const {dispatchRequest, serverData, models, clients} = props;

	const _handleSave = React.useCallback(function(_value){
		dispatchRequest('edit', {data: _value} ).then(function(){
			notificationObj.showNotification('success', 'Master Definition Updated Successfully'); 
			history.push('/rating/model-definition/list/');
		});
	},[dispatchRequest]);

	React.useEffect(function(){
		if(models){
			setComponentExts((_ogExts)=>{
				return ({
					..._ogExts,
					'model_name': {
						data: models
					}
				});
			})
		}
	},[models]);

	React.useEffect(function(){
		if(clients){
			setComponentExts((_ogExts)=>{
				return ({
					..._ogExts,
					'client_fields': {
						data: clients
					}
				});
			})
		}
	},[clients]);

	return (<PageHeader
				title={'Edit Master Definition'}
				previousLinks={[
					{
						href: "/rating/model-definition/list/",
						text: 'Master Definition (All List)'
					}
				]}
				currentPage={'Edit'}
			>
				<NewAgeFormBuilder 
					mode="edit"
					incomingData={serverData}
					config={NewAgeFormConfig} 
					value={value}
					onChange={setValue}
					dispatchRequest={props.dispatchRequest}
					onSave={_handleSave}
					componentExts={componentExts}
					onUpdateComponentExts={setComponentExts}
				/>
			</PageHeader>);
}

export default dataUIWrapper(ModelDefinitionEdit, {
								dispatchers:{
									edit: EditDispatchers,
									getModelMasterData: getModelMasterData
								},
								featchers: [
									MasterRecord,
									getModelMaster,
									getClientFields
								]
								
							});