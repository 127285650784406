import React from 'react';
import { dataUIWrapper } from '../../../../../../Common/DataUIWrapper/dataUIWrapper.js';
import { PageHeader } from '../../../../../../Common/Components/PageHeader/PageHeader.js';
import { ListGrid } from '../../../../../../Common/Components/ListGrid/ListGrid.js';
import getApprovalMasterList from '../../Utils/fetchers/getApprovalMasterList.js';

const ModelDefinitionApprovalList = function(props){

	const { serverData=[]} = props;

	return (<PageHeader
				title={'Master Definition Approval List'}
				currentPage={'Master Definition Approval List'}
			>
				<ListGrid
					data={serverData}
					nameField={"model_definition_name"}
					nameTitle={"Model Definition Name"}
					editList={["Pending"]}
					viewUrl="/rating/model-definition/view/"
					editUrl="/rating/model-definition/approve/"
					columns={[
						{ field: 'model_name', headerName: 'Model Name', width: 250, index: 1}
					]}
				/>
			</PageHeader>);
}

export default dataUIWrapper(ModelDefinitionApprovalList, {
								dispatchers:{},
								featchers: [
									getApprovalMasterList
								]
							});