import React from 'react';
import { dataUIWrapper } from '../../../../../../Common/DataUIWrapper/dataUIWrapper.js';
import { PageHeader } from '../../../../../../Common/Components/PageHeader/PageHeader.js';
import getAudits from '../../Utils/fetchers/getAudits.js';
import { AuditGrid } from '../../../../../../Common/Components/ModuleComponents/AuditGrid/AuditGrid.js';


const IRIEN = function(props){

	const { audits=[]} = props;

	return (<PageHeader
				title={'Integrated Risk Audit Trail'}
				previousLinks={[]}
				currentPage={'Audit Trail List'}
			>
				<AuditGrid data={audits} />
			</PageHeader>);
}

export default dataUIWrapper(IRIEN, {
								dispatchers:{
								},
								featchers: [
									getAudits
								]
							});