var mainUrl=window.riskMac.mainURL;


export default {	
		getMasterList: mainUrl+'/ratinggetDefinitionRecords/all/',
		getMasterRecord: mainUrl+'/ratinggetDefinitionRecord/',
		create: mainUrl+'/ratingcreateDefinitionEntry/',
		delete: mainUrl+'/ratingdeleteDefinitionRecord/',
		edit: mainUrl+'/ratingcreateDefinitionEntry/',
		approve: mainUrl+'/ratingupdateDefinitionApproval/',
		approvalList: mainUrl+'/ratinggetDefinitionRecords/allaction/',
		/*********** */
		getApprovedModels: mainUrl+'/ratinggetMasterRecords/approved/',
		getModelMasterData: mainUrl+'/ratinggetMasterRecord/',
		getClientFields: mainUrl+'/ratinggetClientFields/'
}


