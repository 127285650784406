import React from 'react';
import { newAgeFormConfig } from "../Configs/NewAgeFormConfig";

const Separator = function(){
   
    return (<div className={'app-form-separator'}/>);

}


newAgeFormConfig.pluginComponent('Separator', Separator);