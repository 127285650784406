import React from 'react';
import TextField from '@material-ui/core/TextField';


const SearchBar = function(props){

    const {value, onChange} = props;

    return (<div className="app-search-wrapper">
        Search :<TextField type="text" value={value}  onInput={onChange} variant="filled"/>
    </div>)
}

export {SearchBar}