import APIConfig from "../../APIConfig";

export default {
    url: APIConfig.getRiskCategoryCountReport,
    method: 'get',
    postProcessor: function(dataObj, result){
        dataObj.deptWiseRating = Object.keys(result).map(function(_k){
            return {
                text: _k,
                Open: result[_k].open,
                Closed: result[_k].Closed,
                Retired: result[_k].Retired,
             }
        });

    }
}