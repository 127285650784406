import React from 'react';
import RatingAppRouter from './Rating/RatingRouter.js';
import EasyECLRouter from './EasyECL/ApplicationRouter.js';
import IRIENRouter from './IRIEN/IRIENRouter.js';

function ApplicationRouter() {

	
	return (
		<React.Fragment>
            <RatingAppRouter />
			<EasyECLRouter />
			<IRIENRouter/>
		</React.Fragment>
	);
}

export {ApplicationRouter}
