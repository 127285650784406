import React from 'react';
import { dataUIWrapper } from '../../../../../../Common/DataUIWrapper/dataUIWrapper.js';
import { NewAgeFormBuilder } from '../../../../../../Common/ApplicationFormBuilder/FormBuilder/NewAgeFormBuilder.js';
import { NewAgeFormConfig } from '../../formJSON/newAgeConfig.js';
import { PageHeader } from '../../../../../../Common/Components/PageHeader/PageHeader.js';
import MasterRecord from '../../Utils/fetchers/MasterRecord.js';

const ComputationView = function(props){

	const [value, setValue] = React.useState({});

	const { serverData} = props;

	return (<PageHeader
				title={'ECL Computations View'}
				previousLinks={[
					{
						href: "/ecl/computations/list/",
						text: 'ECL Computations (All List)'
					}
				]}
				currentPage={'View'}
			>
				<NewAgeFormBuilder 
					mode="view"
					incomingData={serverData}
					config={NewAgeFormConfig} 
					value={value}
					onChange={setValue}
				/>
			</PageHeader>);
}

export default dataUIWrapper(ComputationView, {
								dispatchers:{
								},
								featchers: [
									MasterRecord
								]	
							});