import React from 'react';
import GovernanceRoutes from './Modules/Governance/GovernanceRoutes.js';
import DashboardRoutes from './Modules/DashBoard/DashboardRoutes.js';
import AuditsRoutes from './Modules/AuditTrail/AuditsRoutes.js';
import RiskRegisterRoutes from './Modules/RiskRegister/RiskRegisterRoutes.js';
import DSSRoutes from './Modules/DSS/DSSRoutes.js';
import RASRouter from './Modules/RASStatement/RASRoutes.js'
import DSSReportRouter from './Modules/DSSReport/DSSReportRouter.js';
import LandingPageRouter from './Modules/LandingPage/LandingPageRoutes.js';
/*Push Import*/

const ApplicationRoutes = function(){

    return (<React.Fragment>
                <RiskRegisterRoutes/>
                <DSSRoutes/>
                <GovernanceRoutes/>
				<DashboardRoutes/>
				<AuditsRoutes/>
                <RASRouter />
				<DSSReportRouter/>
                <LandingPageRouter/>
				{/*Push*/}
            </React.Fragment>)

}

export default ApplicationRoutes;