import React from 'react';
import { dataUIWrapper } from '../../../../../../Common/DataUIWrapper/dataUIWrapper.js';
import { PageHeader } from '../../../../../../Common/Components/PageHeader/PageHeader.js';
import { ListGrid } from '../../../../../../Common/Components/ListGrid/ListGrid.js';
import getApprovalMasterList from '../../Utils/fetchers/getApprovalMasterList.js';

const RiskRegisterApprovalList = function(props){

	const { serverData=[]} = props;

	return (<PageHeader
				title={'Risk Configurations Approval List'}
				currentPage={'Risk Configurations Approval List'}
			>
				<ListGrid
					data={serverData}
					nameField={"model_name"}
					nameTitle={"Risk Configurations Name"}
					editList={["Pending"]}
					viewUrl="/irien/risk-register/view/"
					editUrl="/irien/risk-register/approve/"
				/>
			</PageHeader>);
}

export default dataUIWrapper(RiskRegisterApprovalList, {
								dispatchers:{},
								featchers: [
									getApprovalMasterList
								]
							});