var mainUrl=window.riskMac.mainURL;


export default {	
		getMasterList: mainUrl+'/getListofFiles/',
		create: mainUrl+'/uploadFile/',
		delete: mainUrl+'/deleteFile/',
		download: mainUrl+'/downloadFile/',
		approve: mainUrl+'/approveGovernanceDocs/',
		getPersonResponsible: mainUrl+'/getRiskOwnersSuperVisorLocations/'
}


