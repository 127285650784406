import React from 'react';
import IRIENRAS from './Routes/RAS/IRIENRAS';
import { Route} from 'react-router-dom';	


function AuditRoutes() {
	
	
	return (<React.Fragment>
                <Route path="/irien/ras-statement">
                    <IRIENRAS />
                </Route>
            </React.Fragment>);
}

export default AuditRoutes;
