import parseHistoryData from "../../../../../Common/Utils/parseHistoryData";

var parseServerObject = function(result){

    var clientFieldObj = {};
    
    
    result.ClientFields.forEach(function(_item){
        clientFieldObj[_item["client_variable_name"]] = _item["variable_selected"];
    });
    
    return {
        ...result.Definition,
        "variables": result.Variables,
        "Grades": result.Grades,
        client_fields: clientFieldObj,
        approval_history: parseHistoryData(result.Definition.history) 
    }
}

export {parseServerObject}