import ApprovalHistoryObject from "../../../../../Common/FormConfig/ApprovalHistoryObject";
import SendForApprovalSwitch from "../../../../../Common/FormConfig/SendForApprovalSwitch";

const yearData = [];
const defaultYearWiseScenerioGridRow = {};
for(var i=1;i<31;++i){
	yearData.push({id:i, value: i})
	defaultYearWiseScenerioGridRow['year'+(i)] = 0
}

var defaultColumn = { field: 'variable_name', headerName: 'Variable Name',  width: 350};
const getYearWiseScenerioColumns = function(years){
	const yearWiseScenerioColumns = [defaultColumn];
	for(var i=1;i<=years;++i){
		yearWiseScenerioColumns.push({
			field: 'year'+i,
			headerName: 'Year '+(i),
			width: 120,
			editable: scenerioyearGridEditable
		})
	}
	return yearWiseScenerioColumns;
}

const ScenerioDefinitionConfig = [
	[
		{
			objectKey: 'scenario_definition_name',
			widget: 'FormTextInput',
			text: 'Scenerio Name',
			editableModes: ['create'],
			validation: [
				{
					type: 'required',
					message: 'Enter model definition name'
				}
			]
		},
		{
			objectKey: 'model_definition_name',
			widget: 'FormDropDown',
			editableModes: ['create'],
			text: 'Model Definition Name',
			data: [],
			widgetProps: {
				dataTextField: 'model_definition_name',
				dataValueField: 'model_definition_name'
			},
			onAfterUpdate: function(options){
				options.dispatchRequest('fetchDefinition', {model_definition_name: options.value} ).then(function(e){
					var variables = e.data[1],
						mvs=[], 
						allSceneriosValue = window.structuredClone(options.context.componentValue.allscenarions),
						scenerioGridData = [];

					if(variables){
						variables.forEach(function(_var){
							if(_var.variable_selected_yn==='TRUE'){
								mvs.push({
									variable_id: _var.variable_id,
									variable_name: _var.variable_name,
									variable_current_mv: 0
								});

								scenerioGridData.push({
									variable_id: _var.variable_id,
									variable_name: _var.variable_name,
									...defaultYearWiseScenerioGridRow
								})

							}

							allSceneriosValue.forEach(function(_scenerioValue){
								_scenerioValue.variables = window.structuredClone(scenerioGridData);
							})
						});

						options.context.onUpdateValue({
							Scenario_CurrentMVs: mvs,
							allscenarions: allSceneriosValue
						});
					}


				});	
			},
			validation: [
				{
					type: 'required',
					message: 'Enter model definition ame'
				}
			]
		},
		{
			objectKey: 'no_of_scenarios',
			widget: 'FormDropDown',
			text: 'No Of Scenarios',
			data: [
				{id:1, value: 1},
				{id:2, value: 2},
				{id:3, value: 3},
				{id:4, value: 4},
				{id:5, value: 5}
			],
			widgetProps: {
				dataTextField: 'id',
				dataValueField: 'value'
			},
			onAfterUpdate: function(options){
				var currentScenerioWeights = [...options.context.componentValue.Scenario_Weights],
					currentAllScenarios = [...options.context.componentValue.allscenarions],
					currentScenariosMVs = options.context.componentValue.Scenario_CurrentMVs,
					oldScenariosLength = currentScenerioWeights.length, 
					newScenarios = options.value,
					gridData = [];
				
				if(oldScenariosLength < newScenarios){

					currentScenariosMVs.forEach(function(_mv){
						gridData.push({
							variable_id: _mv.variable_id,
							variable_name: _mv.variable_name,
							...defaultYearWiseScenerioGridRow
						})
					})

					for(var i=(oldScenariosLength+1);i<=newScenarios;++i){
						var scenarioName = 'Scenario '+i
						currentScenerioWeights.push({scenario_name: scenarioName, scenario_weight_value:0});
						currentAllScenarios.push({ scenario_name: scenarioName, variables: window.structuredClone(gridData) });
					}
				}else if(oldScenariosLength > newScenarios){
					currentScenerioWeights = currentScenerioWeights.slice(0, newScenarios);
					currentAllScenarios  = currentAllScenarios.slice(0, newScenarios);
				}

				options.context.onUpdateValue({
					Scenario_Weights: currentScenerioWeights,
					allscenarions: currentAllScenarios
				});

			},
			validation: [
				{
					type: 'required',
					message: 'Enter Years Per Scenario'
				}
			]
		},
		{
			objectKey: 'no_of_years',
			widget: 'FormDropDown',
			text: 'Years Per Scenario',
			data: yearData,
			explicitUpdateModes:["edit","view","approve"],
			widgetProps: {
				dataTextField: 'id',
				dataValueField: 'value'
			},
			onAfterUpdate: function(options){
				var newYears = options.value,
					newColumns = getYearWiseScenerioColumns(newYears);
				
				options.context.onUpdateExts({
					allscenarions:{
						columns: newColumns
					}
				});
			},
			explicitAfterUpdate: true,
			validation: [
				{
					type: 'required',
					message: 'Enter Years Per Scenario'
				}
			]
		},
		SendForApprovalSwitch
	],
	[
		{
			objectKey: 'lgd_percent',
			widget: 'FormTextInput',
			text: 'LGD %',
			tooltip: 'Numeric value in range from 0 to 100',
			widgetProps: {
				type: "number",
			},
			validation: [
				{
					type: 'required',
					message: 'Enter LGD %'
				},
				{
					type: 'minMax',
					minMessage: 'LGD % should be greater than 0',
					maxMessage: 'LGD % should be less than or equal to 100',
					min: 0.001, 
					max: 100
				}
			]
		},
		{
			objectKey: 'current_pd_rate',
			widget: 'FormTextInput',
			text: 'Current PD Rate',
			tooltip: 'Any numeric value, positive or negative',
			widgetProps: {
				type: "number",
			},
			validation: [
				{
					type: 'required',
					message: 'Enter Current PD Rate'
				}
			]
		},
		{
			objectKey: 'pd_transition_rate',
			widget: 'FormTextInput',
			text: 'PD Transition Rate %',
			tooltip: 'Any numeric value, positive or negative',
			widgetProps: {
				type: "number",
			},
			validation: [
				{
					type: 'required',
					message: 'Enter PD Transition Rate %'
				},
				{
					type: 'minMax',
					minMessage: 'PD transition rate % should be greater than 0',
					maxMessage: 'PD rransition rate % should be less than 100',
					min: 0.001,
					max: 100
				}
			]
		}
	]
]

const MVValueConfig = [
	[
		{
			objectKey: 'Scenario_CurrentMVs',
			widget: 'FormGridComponent',
			widgetProps: {
				columns: [
					{ 	
						field: 'variable_name', 
						headerName: 'Variable Name', 
						width: 300,
						minWidth: 300,
						flex: 1
					},
					{ 
						field: 'variable_current_mv', 
						headerName: 'Current MV Value', 
						width: 300,
						editable: {
							widget: 'FormTextInput',
							widgetProps: {
								type: "number",
							},
						}
					}
				]
			},
			validation:[
				{
					type: 'gridRequiredFields',
					gridFields: [
						{
							field: 'variable_current_mv',
							message: 'Enter scenerio variable MV value'
						}
					]
				}
			]
		}
	]
]

const ScenerioWeightsConfig = [
	[
		{
			objectKey: 'Scenario_Weights',
			widget: 'FormGridComponent',
			widgetProps: {
				columns: [
					{ 	
						field: 'scenario_name', 
						headerName: 'Scenario Name', 
						width: 300,
						minWidth: 300,
						flex: 1
					},
					{ 
						field: 'scenario_weight_value', 
						headerName: 'Weight Value', 
						width: 300,
						editable: {
							widget: 'FormTextInput',
							widgetProps: {
								type: "number",
							},
						}
					}
				]
			},
			validation:[
				{
					type: 'gridRequiredFields',
					gridFields: [
						{
							field: 'scenario_weight_value',
							message: 'Enter scenerio weights'
						}
					]
				},
				{
					type: 'custom',
					fn: function(value){
						value = value || [];
						var total = 0;
						value.forEach(function(_row){
							total = Number(_row.scenario_weight_value)*100000+total;
						});

						if(total!==100000){
							return 'Sum of weight value should equal to 1.0'
						}

					}
				}
			]
		}
	]
]

const scenerioyearGridEditable =  {
	widget: 'FormTextInput',
	widgetProps: {
		type: "number",
	},
}

const YearWiseScenerio = [
	[
		{
			objectKey: 'allscenarions',
			widget: 'FormTabsGridComponent',
			widgetProps: {
				tabField: "scenario_name",
				dataField: "variables",
				columns: getYearWiseScenerioColumns(7)
			},
			validation:[
			]
		}	
	]
]


const NewAgeFormConfig = {
	layoutItems: [
		{
			stepperName: ' ',
			itemsLayout : [		
				{
					items : [
						{
							header: 'Scenerio Definition',
							componentConfig: ScenerioDefinitionConfig
						}
					
					]
				},
				{
					items : [
						{
							header: 'Variable Current MV Values',
							componentConfig: MVValueConfig
						},
						{
							header: 'Scenario Weights (Sum of weight value should equal to 1.0)',
							componentConfig: ScenerioWeightsConfig
						}
					
					]
				},
				{
					items : [
						{
							header: 'Scenario Year Wise',
							componentConfig: YearWiseScenerio
						}					
					]
				},
				...ApprovalHistoryObject		
			],
            stepValidation: function(value){
				
            }

		},
        
	],
	finalSaveParsing: function(_value){

		var sceneriosYearWise =_value.allscenarions.map(function(_sce){

			var variables = _sce.variables.map(function(_obj){
				
				var years={};

				for(var i=1;i<=_value.no_of_years;++i){
					years[i] = Number(_obj["year"+i])
				}

				return {
					variable_name: _obj.variable_name,
					years: years
				}
			})

			return {
				scenario_name: _sce.scenario_name,
				variables: variables

			}
		})

		return {
			..._value,
			allscenarions: sceneriosYearWise
		};
	},
	defaultValue: {
		"send_for_approval": false,
		no_of_years: 7,
		no_of_scenarios: 1,
		Scenario_Weights: [
			{scenario_name: 'Scenario 1', scenario_weight_value:0}
		],
		allscenarions: [
			{
				scenario_name: 'Scenario 1',
				variables: []
			}
		]
	}

}


export {NewAgeFormConfig}