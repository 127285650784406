import React from 'react';

var newAgeComponents = {};
var newAgeValueParsers = {}
var defaultSaveParsers={};
var newAgeFormConfig = {};
var validations = {};



const UDComponent = function(props){
    return (<div>Under Development : {props.id}</div>)
}

/**
 * Components Config
 * */
newAgeFormConfig.pluginComponent = function(componentName, component){
    newAgeComponents[componentName] = component;
}


newAgeFormConfig.getComponent = function(componentName, component){
    return newAgeComponents[componentName] || UDComponent;
}


/**
 * Validation Config
 * */
newAgeFormConfig.addValidation = function(name, fn){
    validations[name] = fn;
}

newAgeFormConfig.getValidation = function(name, fn){
    return validations[name];
}


/**
 * ValueParser Config
 * */

newAgeFormConfig.addValueParser = function(name, fn){
    newAgeValueParsers[name] = fn;
}

newAgeFormConfig.getValueParser = function(name, fn){
    return newAgeValueParsers[name];
}


/**
 * Default Save Parser Config
 * */

newAgeFormConfig.addDefaultSaveParser = function(name, fn){
    defaultSaveParsers[name] = fn;
}

newAgeFormConfig.getDefaultSaveParser = function(name, fn){
    return defaultSaveParsers[name];
}

export {newAgeFormConfig};
