import React from 'react';
import { newAgeFormConfig } from "../Configs/NewAgeFormConfig";
import TextField from '@material-ui/core/TextField';
import { AppCloseIcon } from '../../Icons/AppCloseIcon';

const FormFileInput = function(props){

    const {id, value, error, onChange, inputProps={}, ...extProps} = props;

    const {type="file", accept=".csv"} = inputProps;

    const [key, setKey] = React.useState(Date.now());

    const _handleChange = React.useCallback(function(event){
        var _files = event.target.files[0];
        onChange({
            value: {
                file: _files
            }
        });      
    },[onChange]);

    const _removeValue = React.useCallback(function(){
        onChange({
            value: undefined   
        });
    },[onChange])


    const _fileName = React.useMemo(function(){
        if(value && value.file){
            var _name =value.file.name;
            return (<div className="rm-form-file-name-wrapper">
                        <span className="rm-form-file-name">{_name}</span>
                        <AppCloseIcon onClick={_removeValue} title="Remove file"/>
                    </div>)
        }
        return (<div className="rm-form-file-name-wrapper">No File Selected</div>);
    },[value, _removeValue]);

    React.useEffect(function(){
        setKey(Date.now());
    },[value])

    return (<div className="rm-form-file">  
                <label htmlFor={id} id={id+"-label"} className="rm-form-file-input-button">Select  File</label>
                <TextField
                    id={id}
                    key={key}
                    value={undefined}
                    type={'file'}
                    onChange={_handleChange}
                    {...extProps}
                    variant="filled"
                    error={!!error}
                    helperText={error}
                    inputProps={{
                        ...inputProps,
                        type: type,
                        accept: accept
                    }}
                ></TextField>
                {_fileName}
            </div>);


}


newAgeFormConfig.pluginComponent('FormFileInput', FormFileInput);
newAgeFormConfig.addDefaultSaveParser('FormFileInput', function(value){
    if(value){
        return value.file;
    }
})