
var RiskConf = {
	config: [
		[
			{
				objectKey: 'configuration_name',
				id: 'configuration_name',
				widget: 'FormDropDown',
				text: 'Select Risk Reporting Unit',
				data: [
					{
						text: 'Risk Categories',
						value: 'RiskCategories'
					},
					// {
					// 	text: 'Risk Sub-Categories',
					// 	value: 'RiskSubCategories'
					// },
					{
						text: 'Division',
						value: 'Division'
					},
					// {
					// 	text: 'Department',
					// 	value: 'Department'
					// },
					{
						text: 'Location',
						value: 'Location'
					},
					{
						text: 'RiskOwner',
						value: 'RiskOwner'
					},
					{
						text: 'Supervisior',
						value: 'Supervisior'
					},
				]
			}
		],
		[
			
		]
	]
}


export default RiskConf;