import React from 'react';
import { newAgeFormConfig } from '../Configs/NewAgeFormConfig';
import { DataGrid } from '@material-ui/data-grid';


const getEditableObject = function(obj, options){

    const {editable, _updateColumns, field} = options

    obj.editable = undefined;
                
    var {widget, widgetProps={}, isEditable, valueParser} = editable;

    var Component = newAgeFormConfig.getComponent(widget);
    
    obj.renderCell = function(param){

        const {row} = param;
        var editable=true;

        if(isEditable){
            editable = isEditable(param);
        }
        
        if(editable){
            var title, value=row[field];
            if(typeof value !=='undefined' && typeof value!=='object'){
                title = value+'';
            }

            return (<div key={row[field]} title={title} className="app-form-grid-rendered-column-input" onClick={e=>e.stopPropagation()}>
                        <Component 
                            key={row[field]+'-component'}
                            value={row[field]}
                            updateOnBlur={true}
                            {...widgetProps}
                            onChange={(e)=>{ 
                                _updateColumns({
                                    componentValue: e.value,
                                    field: field,
                                    idx: row.___idx
                                }) ;
                            }}
                        />
                    </div>)
        }else{
            if(valueParser){
                return valueParser(row[field], param);
            }
            return row[field];
        }
    }
}

const getViewOnlyColumn = function(obj, options){

    const { viewValueParser, field } = options;
    obj.editable = undefined;

    var Component = newAgeFormConfig.getComponent('FormTextDisplay');
    
    obj.renderCell = function(param){

        const {row} = param;
        
        return (<div className="app-form-grid-rendered-column-input" onClick={e=>e.stopPropagation()}>
                        <Component 
                            value={row[field]}
                            viewValueParser={viewValueParser}
                        />
                    </div>)
        }
}

const FormGridComponent = function(props){

    const {id, value=[], columns, onChange,  viewOnlyMode, enableFooter, ...extProps} = props;

    const _updateColumns = React.useCallback(function(options){

        const {idx, field, componentValue} = options;

        var newValue = window.structuredClone(value);
        var newObj = newValue[idx];
        newObj[field] = componentValue;

        onChange({
            value: newValue
        });

    },[value, onChange])

    var _parsedColumns = React.useMemo(function(){

        return columns.map(function(_column){
            
            const {editable, field} = _column
            var obj = {};

           if(editable && typeof editable === 'object'){
                if(viewOnlyMode){
                    getViewOnlyColumn(obj,{
                        viewValueParser: _column.viewValueParser, field
                    });
                }else{
                    obj.headerClassName="app-center-header-align";
                    getEditableObject(obj,{
                        editable, _updateColumns, field
                    });
                }
            }

            return {
                ..._column,
                ...obj
            }
        });

    },[columns, _updateColumns, viewOnlyMode]);

    const _parsedValue = React.useMemo(function(){
        return value.map(function(_v, idx){
            return {
                ..._v,
                ___idx: idx,
                id: _v.id || idx
            }
        })
    },[value]);

    const __className = (enableFooter?"rm-grid-footer-enabled ":"")+"rm-form-datagrid";

	return (<DataGrid
                rows={_parsedValue} 
                columns={_parsedColumns}
                className={__className}
                {...extProps}
		    />);

}


newAgeFormConfig.pluginComponent('FormGridComponent', FormGridComponent);

export {FormGridComponent}