import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { Link} from "react-router-dom";
import CancelIcon from '@mui/icons-material/Cancel';


const AppRejectIcon = function(props){

    const {link='#', onClick, title="Reject"} = props;

    return (<Link to={link}>
                <IconButton title={title} variant="contained" onClick={onClick}>
                    <CancelIcon className="ecl-view-icon ecl-delete-icon"/>
                </IconButton>
            </Link>)
}

export {AppRejectIcon}