import appNavConfig from "../../Global/ApplicationNavProvider/Config/AppNavConfig";

var navData = [{
    header: 'EasyRating',
    items: [  
        {
            id: 'panel-rating-Dashboard',
            title: 'Dashboard',
            link: '/rating/dashboard/available-for-subscribers',
        },		
        {	id: 'panel-rating-modelling',
            title: 'Modelling',
            items: [{
                    id: 'panel-rating-modal-master',
                    title: 'Model Master',
                    items: [
                        {
                            link: '/rating/model-master/list',
                            title: 'Models',
                            plusIconLink: '/rating/model-master/create'
                        },
                        {
                            link: '/rating/model-master/approve-list',
                            title: 'Approve'
                        }
                    ]
                    
                },
                {
                    id: 'panel-rating-modal-definition',
                    title: 'Model Definition',
                    items: [
                        {
                            link: '/rating/model-definition/list',
                            title: 'Defintions',
                            plusIconLink: '/rating/model-definition/create'
                        },
                        {
                            link: '/rating/model-definition/approve-list',
                            title: 'Approve'
                        }
                    ]
                    
                }]
        },
        {
            id: 'panel-rating-computation',
            title: 'Rating Computation',
            link: '/rating/computations/list',
            plusIconLink: '/rating/computations/create/'
        },			
        {
            id: 'panel-rating-reporting',
            title: 'Reports',
            items: [
                {
                    link: '/rating/report/available-for-subscribers',
                    title: 'EasyRating'
                },
            ]
            
        },
        {
            id: 'panel-rating-audit-trail',
            title: 'Audit Trail',
            link:'/rating/audit',			
        }
    ]
}];

var pagesRegex = [/\/rating\/*/];

var homePage = '/rating/dashboard/available-for-subscribers';

appNavConfig.plugin('rating',{
    nav: navData,
    regex: pagesRegex,
    homePage: homePage
});
