import React from 'react';
import logo from './resources/irienlogo.png';
import Typography from '@material-ui/core/Typography';
import { RMCard } from '../../../../../Common/Components/WrappedComponents/RMCards/RMCards';

const LandingPage = function(props){
    

    return (<div className="app-flex app-right-section">
                    <RMCard className="app-flex">
                        <div className="subscriber-div landing-page-div">
                            <img src={logo} className="landing-page-image"/>
                            <Typography variant={"h4"} className="app-sub-title ecl-header-title report-heading landing-page-info">
                                <div>The enterprise-wide risk management system</div>
                                <div>ERM and ICAAP for financial institutions</div>
                                <div>Risk identification, measurement, management, decision support, economic capital, and governance</div>
                            </Typography>
                        </div>
                    </RMCard> 
                </div>)
}

export {LandingPage}