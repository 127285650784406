import React from 'react';
import { dataUIWrapper } from '../../../../../../Common/DataUIWrapper/dataUIWrapper.js';
import { NewAgeFormBuilder } from '../../../../../../Common/ApplicationFormBuilder/FormBuilder/NewAgeFormBuilder.js';
import { NewAgeFormConfig } from '../../formJSON/newAgeConfig.js';
import { PageHeader } from '../../../../../../Common/Components/PageHeader/PageHeader.js';
import MasterRecord from '../../Utils/fetchers/MasterRecord.js';
import getClientFields from '../../Utils/fetchers/getClientFields.js';

const ModelDefinitionView = function(props){

	const [value, setValue] = React.useState({});
	const [componentExts, setComponentExts] = React.useState({});

	const { serverData, clients} = props;

	React.useEffect(function(){
		if(clients){
			setComponentExts((_ogExts)=>{
				return ({
					..._ogExts,
					'client_fields': {
						data: clients
					}
				});
			})
		}
	},[clients]);

	return (<PageHeader
				title={'Master Definition Definition'}
				previousLinks={[
					{
						href: "/rating/model-definition/list/",
						text: 'Model Definition (All List)'
					}
				]}
				currentPage={'View'}
			>
				<NewAgeFormBuilder 
					mode="view"
					incomingData={serverData}
					config={NewAgeFormConfig} 
					value={value}
					onChange={setValue}
					componentExts={componentExts}
					onUpdateComponentExts={setComponentExts}
				/>
			</PageHeader>);
}

export default dataUIWrapper(ModelDefinitionView, {
								dispatchers:{
								},
								featchers: [
									MasterRecord,
									getClientFields
								]	
							});