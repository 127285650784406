import React from 'react';
import { dataUIWrapper } from '../../../../../../Common/DataUIWrapper/dataUIWrapper.js';
import { NewAgeFormBuilder } from '../../../../../../Common/ApplicationFormBuilder/FormBuilder/NewAgeFormBuilder.js';
import { useHistory } from 'react-router-dom';
import { NewAgeFormConfig } from '../../formJSON/newAgeConfig.js';
import { PageHeader } from '../../../../../../Common/Components/PageHeader/PageHeader.js';
import { notificationObj } from '../../../../../../Global/NotificationHandler.js';
import MasterRecord from '../../Utils/fetchers/MasterRecord.js';
import EditDispatchers from '../../Utils/dispatchers/EditDispatchers.js';
import allDataFetchers from '../../Utils/fetchers/allDataFetchers.js';
import { useFetcherDataMapping } from '../../../../../../Common/ApplicationHooks/FormHooks/useFetcherDataMapping.js';
import getDepartments from '../../Utils/dispatchers/getDepartments.js';
import getRiskSubCategory from '../../Utils/dispatchers/getRiskSubCategory.js';
import getRiskIndicator from '../../Utils/dispatchers/getRiskIndicator.js';
import calculateMitigationRisk from '../../Utils/dispatchers/calculateMitigationRisk.js';
import calculateRisk from '../../Utils/dispatchers/calculateRisk.js';
import Button from '@material-ui/core/Button';

const RiskRegisterEdit = function(props){

	const [value, setValue] = React.useState({});
	const [componentExts, setComponentExts] = React.useState({});

	const history = useHistory();

	const {dispatchRequest, serverData} = props;

	const _handleSave = React.useCallback(function(_value, options){
		dispatchRequest('edit', {data: _value} ).then(function(){
			notificationObj.showNotification('success', 'Risk configuration updated successfully'); 
			if(options.isUpload){
				history.push('/integated-risk/upload-files/'+options.model_name);
			}else{
				history.push('/irien/risk-register/list/');
			}
		});
	},[dispatchRequest]);

	useFetcherDataMapping(props, allDataFetchers, setComponentExts);

	const customButtonRenderer = React.useCallback(function(options){
		const {saveButtonIndex, stepperSelected, triggerSave} = options;
		if(saveButtonIndex===stepperSelected){
			return (<Button 
						key={'save_and_upload'}
						variant="contained" 
						color="primary"
						onClick = {()=>triggerSave({
										isUpload: true,
										model_name: value.model_name
									})}>
							Save and Upload
					</Button>)
		}
		return null;
	},[value])

	return (<PageHeader
				title={'Edit Risk Configurations'}
				previousLinks={[
					{
						href: "/irien/risk-register/list/",
						text: 'Risk Configurations (All List)'
					}
				]}
				currentPage={'Edit'}
			>
				<NewAgeFormBuilder 
					mode="edit"
					incomingData={serverData}
					config={NewAgeFormConfig} 
					value={value}
					onChange={setValue}
					dispatchRequest={props.dispatchRequest}
					onSave={_handleSave}
					componentExts={componentExts}
					onUpdateComponentExts={setComponentExts}
					customButtonRenderer={customButtonRenderer}
				/>
			</PageHeader>);
}

export default dataUIWrapper(RiskRegisterEdit, {
								dispatchers:{
									edit: EditDispatchers,
									getDepartment: getDepartments,
									getRiskSubCategory: getRiskSubCategory,
									getRiskIndicator: getRiskIndicator,
									calculateMitigationRisk: calculateMitigationRisk,
									calculateRisk: calculateRisk
								},
								featchers: [
									MasterRecord,
									...allDataFetchers
								]
								
							});