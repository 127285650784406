import React from 'react';
import { dataUIWrapper } from '../../../../../../Common/DataUIWrapper/dataUIWrapper.js';
import { NewAgeFormBuilder } from '../../../../../../Common/ApplicationFormBuilder/FormBuilder/NewAgeFormBuilder.js';
import { useHistory } from 'react-router-dom';
import { NewAgeFormConfig } from '../../formJSON/newAgeConfig.js';
import { PageHeader } from '../../../../../../Common/Components/PageHeader/PageHeader.js';
import { notificationObj } from '../../../../../../Global/NotificationHandler.js';
import MasterRecord from '../../Utils/fetchers/MasterRecord.js';
import EditDispatchers from '../../Utils/dispatchers/EditDispatchers.js';
import FetchVariables from '../../Utils/dispatchers/FetchVariables.js';

const ModelDefinitionEdit = function(props){

	const [value, setValue] = React.useState({});

	const history = useHistory();

	const {dispatchRequest, serverData} = props;

	const _handleSave = React.useCallback(function(_value){
		dispatchRequest('edit', {data: _value} ).then(function(){
			notificationObj.showNotification('success', 'Model Definition Updated Successfully'); 
			history.push('/ecl/model-definition/list/');
		});
	},[dispatchRequest]);


	return (<PageHeader
				title={'Edit Model Definition'}
				previousLinks={[
					{
						href: "/ecl/model-definition/list/",
						text: 'Model Definition (All List)'
					}
				]}
				currentPage={'Edit'}
			>
				<NewAgeFormBuilder 
					mode="edit"
					incomingData={serverData}
					config={NewAgeFormConfig} 
					value={value}
					onChange={setValue}
					dispatchRequest={props.dispatchRequest}
					onSave={_handleSave}
				/>
			</PageHeader>);
}

export default dataUIWrapper(ModelDefinitionEdit, {
								dispatchers:{
									edit: EditDispatchers,
									fetchVariables: FetchVariables
								},
								featchers: [
									MasterRecord
								]
								
							});