import React from 'react';
import { DataGrid } from '@material-ui/data-grid';

const RMAppGrid = function(props){

    const {rows=[]} = props; 

    const _parsedValue = React.useMemo(function(){
        return rows.map(function(_v, idx){
            return {
                ..._v,
                ___idx: idx,
                id: _v.id || idx
            }
        })
    },[rows]);

    return (<DataGrid {...props} rows={_parsedValue}/>)

}

export {RMAppGrid}