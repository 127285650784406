import React from 'react';
import { Route} from 'react-router-dom';
import RiskReport from './RiskDetails/RiskReport.js';
import APIConfig from '../../APIConfig.js'
import FilesUploadComponent from '../Global/UploadComponent/FilesUploadComponent.js';

function MainRouter() {

	
	return (
		<React.Fragment>
			
			<Route exact path="/integated-risk/report">
			    <RiskReport/>
			</Route>
			
			<Route exact path="/integated-risk/upload-files/:id">
			    <FilesUploadComponent
					homeUrl={'/irien/risk-register/list'}
					homeText={'Risk Configurations (All List)'}
					fetchingUrl={APIConfig.getFiles}
					saveUrl={APIConfig.uploadFiles}
					module={'erm'}
					deleteUrl={APIConfig.deleteFile}
					downloadUrl = {APIConfig.downloadFile}
				/>
			</Route>

		</React.Fragment>
	);
}

export default MainRouter;
