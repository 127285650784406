import React from 'react';
import APIConfig from '../../../../APIConfig.js';
import configurationConfig from './reportConf/configurationConfig.js';
import Card from '@material-ui/core/Card';
import {FormBuilder} from '../../../Global/FormBuilder/FormBuilderNew.js';
import { DataGrid } from '@material-ui/data-grid';

import Typography from '@material-ui/core/Typography';
import {config} from '../config/RiskDetailsCreateS1.js';
import {configS2} from '../config/RiskDetailsCreateS2.js';
import {configS3} from '../config/RiskDetailsCreateS3.js';
import {configS4} from '../config/RiskDetailsCreateS4.js';
import {configS5} from '../config/RiskDetailsCreateS5.js';
import {configS6} from '../config/RiskDetailsCreateS6.js';
import {configS7} from '../config/RiskDetailsResidualRiskCreateS7.js';
import {configControl} from '../config/RiskDetailsControlScreen.js';
import {approvalConfig} from '../config/RiskApprovalConfig.js';
import { dataUIWrapper } from '../../../Common/DataUIWrapper/dataUIWrapper.js';


const columns = [
    {
        field: 'key',
        headerName: 'Risk Configuration',
        width: 500
    },{
        field: 'value',
        headerName: 'Value',
        width: 800
    }
];

const RiskDashboard = function(props){
    const [formData, setFormData] = React.useState({});
    const [apiConf, setApiRef] = React.useState({});
    const [formBuilderConfig, setFormBuilderConfig] = React.useState(configurationConfig);
    const [details, setDetails] = React.useState(null);

    React.useEffect(function(){
        
        if(props.riskRegistersNames){
            debugger;
            var _data = props.riskRegistersNames.map(function(_d){
                return {
                    value: _d.conf_name,
                    text: _d.name
                }
            });
            var conf = {
                config: [...formBuilderConfig.config]
            }
            conf.config[0][0].data = _data;
            setFormBuilderConfig(conf);
        }

    },[props]);

    const extractConfig = function(name, _config, _d){
        var row=[];
        var _id = 0;
        _config.config.forEach(function(_c){
            _c.forEach(function(_con){
                if(_con.widget=== 'Spacer'){
                    return;
                }
                row.push({
                    id: _id++,
                    key: _con.text,
                    value: _d.data[_con.objectKey]
                });
            });
       });
       return {
        name: name,
        rows: row
     }
    }

    const handleScreenUpdate = React.useCallback(function(key, value){
        
        props.dispatchRequest('getMasterRecord',{conf: value}).then(function(_d){
            var baseObj = [];    
           baseObj.push(extractConfig('Organization Details', config, _d));
           baseObj.push(extractConfig('Risk Identification', configS3, _d));
           baseObj.push(extractConfig('Risk Analysis', configS4, _d));
           baseObj.push(extractConfig('Control Analysis', configControl, _d));
           baseObj.push(extractConfig('Risk Evaluation', configS5, _d));
           baseObj.push(extractConfig('Risk Treatment', configS6, _d));
           baseObj.push(extractConfig('Residual Risk', configS7, _d));
           baseObj.push(extractConfig('Approval', approvalConfig, _d));
           setDetails(baseObj)
        });

    },[]);


    return (<div>
                <Card className="main-ui-card">
                    <FormBuilder apiConf={apiConf} {...formBuilderConfig}  data = { formData } onUpdate={handleScreenUpdate}  />
                </Card>
                {details && (details.map(function(_d, idx){
                    return (<Card className="main-ui-card" key={idx}>
                                 <Typography variant="h6" className="app-sub-title ecl-header-title">
                                   {_d.name}
                                </Typography>
                                <div className={"dss-grid-container report-grid erm-report-size_"+idx}>
                                    <DataGrid rows={_d.rows} columns={columns}  />
                                </div>
                            </Card>)
                }))}

            </div>) ;

}


var createDispatchers = {
    getMasterRecord:{
        url: APIConfig.erm.getErmMasterRecord,
        method: 'get',
        preprocessor: function(obj){
            obj.url = obj.url +obj.conf+'/';
            obj.conf=undefined;
            return obj;
        }
    }
};


var fetchingData = [
    {
        url: APIConfig.erm.getReportRiskRegistersNames,
        method: 'get',
        key: 'riskRegistersNames'
    }
];


var RiskDashboardModule = dataUIWrapper(RiskDashboard);

const ExportModule = function(props){
    return (<RiskDashboardModule fetchingData={fetchingData}  dispatcher={createDispatchers} />)
}

export default ExportModule;