export default [
    [
        {
			objectKey: 'control',
			widget: 'FormTextInput',
			text: 'Controls',
			validation: [
				{
					type: 'required',
					message: 'Enter Controls'
				} 
			]
		},
        {
			objectKey: 'control_cost',
			widget: 'FormTextInput',
			text: 'Control Cost',
			widgetProps: {
				type: 'number'
			},
			validation: [
				{
					type: 'required',
					message: 'Enter Control Cost'
				}
			]
		}
    ],
    [
        {
			objectKey: 'control_status',
			widget: 'FormDropDown',
			text: 'Control Status',
			data: [],
			widgetProps: {
				dataTextField: 'controlled_status',
				dataValueField: 'controlled_status'
			},
			validation: [
				{
					type: 'required',
					message: 'Enter Control Status'
				}
			]
		},
        {
			objectKey: 'control_effectiveness',
			widget: 'FormDropDown',
			text: 'Control Effectiveness',
			data: [],
			widgetProps: {
				dataTextField: 'control_effective',
				dataValueField: 'control_effective'
			},
			validation: [
				{
					type: 'required',
					message: 'Enter Control Effectiveness'
				}
			],

			onAfterUpdate: function(options){

				var severity = options.context.componentValue.inherent_risk_factor,
					riskCategory= options.context.componentValue.risk_category,
					riskSubCategory = options.context.componentValue.risk_subcategory,
					likelihood = options.value;

					if(!options.context.checkWatch({
						inherent_risk_factor: severity,
						risk_category: riskCategory,
						risk_subcategory: riskSubCategory,
						control_effectiveness: likelihood
					})){
						return;
					}

					if(likelihood && severity){
						options.dispatchRequest('calculateRisk', {data: {riskLikelihood: likelihood, riskSeverity: severity}} ).then(function(e){
						
							options.context.onUpdateValue({
								net_risk: e.data.RiskResult.risk,
								net_risk_factor: e.data.RiskResult.factor,
								net_risk_action: e.data.RiskResult.Action
							});

							options.dispatchRequest('calculateMitigationRisk', 
								{data:{
									risk_category: riskCategory, 
									risk_subcategory: riskSubCategory,
									riskStatus: e.data.RiskResult
								}}
							).then(function(f){							

								options.context.onUpdateValue({
									mitigation_priority: f.data.RiskResult.riskMitiGationPriority,
									mitigation_cost: f.data.RiskResult.riskMitigationCost 
								});
						
							});	
					
						});	
					}
			}
		},
        {
			objectKey: 'person_responsible_control',
			widget: 'FormDropDown',
			text: 'Person responsible',
			data: [],
			widgetProps: {
				dataTextField: 'name',
				dataValueField: 'name'
			},
			validation: [
				{
					type: 'required',
					message: 'Enter Person responsible'
				}
			]
		},
    ]
]