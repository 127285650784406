var mainUrl=window.riskMac.mainURL;


export default {	
		getMasterList: mainUrl+'/getMasterRecords/all/',
		getMasterRecord: mainUrl+'/getMasterRecord/',
		create: mainUrl+'/createMasterEntry/',
		delete: mainUrl+'/deleteMasterRecord/',
		edit: mainUrl+'/createMasterEntry/',
		approve: mainUrl+'/updateMasterRecordApproval/',
		approvalList: mainUrl+'/getMasterRecords/allaction/',
}


