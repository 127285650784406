import React from 'react';
import { newAgeFormConfig } from '../Configs/NewAgeFormConfig';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const FormAccountCircle = function(props){

    const {id} = props;

    return (<AccountCircleIcon className="user-profile-icon" />);
}


newAgeFormConfig.pluginComponent('FormAccountCircle', FormAccountCircle);