var configS7 = {
	config: [
		[
			{
				objectKey: 'residual_risk_type',
				id: 'residual_risk_type',
				widget: 'FormDropDown',
				text: 'Residual Risk Type',
				data: [ ]

			},		
			{
				objectKey: 'residual_risk_description',
				widget: 'FormTextInput',
				text: 'Residual Risk Description'
				/*widgetProps:{
					disabled: true,
					readonly: true,
					className: 'ecl-spotlighted'
				}*/

			},
			// {
			// 	objectKey: 'residual_impact',
			// 	id: 'residual_impact',
			// 	widget: 'FormDropDown',
			// 	text: 'Residual Risk  Impact',				
			// 	data: [ ]
			// },
			{
				objectKey: 'residual_likelihood',
				id: 'residual_likelihood',
				widget: 'FormDropDown',
				text: 'Residual Risk Likelihood',				
				data: [ ]
			},
			{
				objectKey: 'residual_severity',
				id: 'residual_severity',
				widget: 'FormDropDown',
				text: 'Residual Risk Severity',				
				data: [ ]
			}

		],[
			
			{
				objectKey: 'residual_risk_action',
				id: 'residual_risk_action',
				widget: 'FormDropDown',
				text: 'Residual Risk Action',
				data: [ ]
			},
			{
                objectKey: 'residual_risk_action_description',
                widget: 'FormMultilineInput',
                text: 'Action Description'
            },
			{
				objectKey: 'person_responsible_Residual_Risk',
				id: 'person_responsible_Residual_Risk',
				widget: 'FormDropDown',
				text: 'Person responsible',	
				data: [ ]	
			},
			{
				objectKey: 'residual_risk_outcome',
				widget: 'FormTextInput',
				text: 'Residual Risk Outcome',
				widgetProps:{
					disabled: true,
					readonly: true,
					className: 'ecl-spotlighted'
				}

			}
			
			/*
			{
				objectKey: 'residual_risk_outcome',
				id: 'residual_risk_outcome',
				widget: 'FormTextInput',
				text: 'Mitigation Cost ',
				// data: []
			},
			{
				objectKey: 'residual_risk_factor',
				widget: 'FormTextInput',
				text: 'Residual Risk Factor',
				widgetProps:{
					disabled: true,
					readonly: true,
					className: 'ecl-spotlighted'
				}

			},
			{
				objectKey: 'residual_risk_action',
				widget: 'FormTextInput',
				text: 'Residual Risk Action',
				widgetProps:{
					disabled: true,
					readonly: true,
					className: 'ecl-spotlighted'
				}

			},
			{
				objectKey: 'adeqacy_existing_controls',
				id: 'adeqacy_existing_controls',
				widget: 'FormDropDown',
				text: 'Adeqacy of Existing Controls / Mitigations',				
				data: [ ]
			}*/

		]

	]
}


export {
	configS7
}