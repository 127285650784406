import React from 'react';
import { dataUIWrapper } from '../../../../../../Common/DataUIWrapper/dataUIWrapper.js';
import { PageHeader } from '../../../../../../Common/Components/PageHeader/PageHeader.js';
import { ListGrid } from '../../../../../../Common/Components/ListGrid/ListGrid.js';
import getApprovalMasterList from '../../Utils/fetchers/getApprovalMasterList.js';

const ModelMasterApprovalList = function(props){

	const { serverData=[]} = props;

	return (<PageHeader
				title={'Master Model Approval List'}
				currentPage={'Master Model Approval List'}
			>
				<ListGrid
					data={serverData}
					editList={["Pending"]}
					viewUrl="/ecl/model-master/view/"
					editUrl="/ecl/model-master/approve/"
				/>
			</PageHeader>);
}

export default dataUIWrapper(ModelMasterApprovalList, {
								dispatchers:{},
								featchers: [
									getApprovalMasterList
								]
							});