import React from 'react';
import { dataUIWrapper } from '../../../../../../Common/DataUIWrapper/dataUIWrapper.js';
import { NewAgeFormBuilder } from '../../../../../../Common/ApplicationFormBuilder/FormBuilder/NewAgeFormBuilder.js';
import { useHistory } from 'react-router-dom';
import { NewAgeFormConfig } from '../../formJSON/newAgeConfig.js';
import { PageHeader } from '../../../../../../Common/Components/PageHeader/PageHeader.js';
import { notificationObj } from '../../../../../../Global/NotificationHandler.js';
import MasterRecord from '../../Utils/fetchers/MasterRecord.js';
import ApproveDispatcher from '../../Utils/dispatchers/ApproveDispatcher.js';
import getClientFields from '../../Utils/fetchers/getClientFields.js';


const ModelDefinitionApprove = function(props){

	const [value, setValue] = React.useState({});
	const [componentExts, setComponentExts] = React.useState({});

	const history = useHistory();

	const { dispatchRequest, serverData, clients} = props;

	const _handleApprove = React.useCallback(function(_value){
		dispatchRequest('approve', {data: {
			"approved_user_comment": _value.approved_user_comment,
			"approval_status": _value.approval_status,
			"model_definition_name": _value.model_definition_name,
			"model_name": _value.model_name
		}} ).then(function(){
			notificationObj.showNotification('success', 'Master Definition Approval Status Updated Successfully'); 
			history.push('/rating/model-definition/approve-list/');
		});
	},[dispatchRequest]);

	React.useEffect(function(){
		if(clients){
			setComponentExts((_ogExts)=>{
				return ({
					..._ogExts,
					'client_fields': {
						data: clients
					}
				});
			})
		}
	},[clients]);


	return (<PageHeader
				title={'Edit Master Definition'}
				previousLinks={[
					{
						href: "/rating/model-definition/approve-list/",
						text: 'Master Definition (Approval List)'
					}
				]}
				currentPage={'Approve'}
			>
				<NewAgeFormBuilder 
					mode="approve"
					incomingData={serverData}
					config={NewAgeFormConfig} 
					value={value}
					onChange={setValue}
					dispatchRequest={props.dispatchRequest}
					onSave={_handleApprove}
					componentExts={componentExts}
					onUpdateComponentExts={setComponentExts}
				/>
			</PageHeader>);
}

export default dataUIWrapper(ModelDefinitionApprove, {
								dispatchers:{
									approve: ApproveDispatcher
								},
								featchers: [
									MasterRecord,
									getClientFields
								]
							});