const onLikelihoodSeverityUpdate = function(options, likelihood, severity){

	if(likelihood && severity){
		options.dispatchRequest('calculateRisk', {data: {riskLikelihood: likelihood, riskSeverity: severity}} ).then(function(e){
		
			options.context.onUpdateValue({
				inherent_risk: e.data.RiskResult.risk,
				inherent_risk_factor: e.data.RiskResult.factor
			});
	
		});	
	}
}

export default [
    [
        {
			objectKey: 'impact',
			widget: 'FormDropDown',
			text: 'Risk Impact',
			data: [],
			widgetProps: {
				dataTextField: 'impact',
				dataValueField: 'impact'
			},
			validation: [
				{
					type: 'required',
					message: 'Enter Risk Impact'
				}
			]
		},
        {
			objectKey: 'likelihood',
			widget: 'FormDropDown',
			text: 'Likelihood',
			data: [],
			widgetProps: {
				dataTextField: 'likelihood',
				dataValueField: 'likelihood'
			},
			onAfterUpdate: function(options){

				var severity = options.context.componentValue.severity,
					likelihood = options.value;

				onLikelihoodSeverityUpdate(options, likelihood,  severity)
			},
			validation: [
				{
					type: 'required',
					message: 'Enter Likelihood'
				}
			]
		},
        {
			objectKey: 'severity',
			widget: 'FormDropDown',
			text: 'Severity',
			data: [],
			widgetProps: {
				dataTextField: 'severity',
				dataValueField: 'severity'
			},
			onAfterUpdate: function(options){

				var severity = options.value,
					likelihood = options.context.componentValue.likelihood;

				onLikelihoodSeverityUpdate(options, likelihood,  severity)
			},
			validation: [
				{
					type: 'required',
					message: 'Enter Severity'
				}
			]
		},
    ],
    [
        
        {
			objectKey: 'inherent_risk',
			widget: 'FormTextInput',
			text: 'Inherent Risk Score',
			editableModes: [],
            widgetProps: {
                highlighted: true
            }
		},
        {
			objectKey: 'inherent_risk_factor',
			widget: 'FormTextInput',
			text: 'Inherent Risk Level',
			editableModes: [],
            widgetProps: {
                highlighted: true
            }
		},
    ]
]
