const onLikelihoodSeverityUpdate = function(options, likelihood, severity){

	if(likelihood && severity){
		options.dispatchRequest('calculateRisk', {data: {riskLikelihood: likelihood, riskSeverity: severity}} ).then(function(e){
		
			options.context.onUpdateValue({
				residual_risk_outcome: e.data.RiskResult.risk,
				residual_risk_factor: e.data.RiskResult.factor
			});
	
		});	
	}
}


export default [
    [
        {
			objectKey: 'residual_risk_type',
			widget: 'FormDropDown',
			text: 'Residual Risk Type',
			data: [],
			widgetProps: {
				dataTextField: 'riskType',
				dataValueField: 'riskType'
			},
			validation: [
				{
					type: 'required',
					message: 'Enter Residual Risk Type'
				}
			]
		},
        {
			objectKey: 'residual_risk_description',
			widget: 'FormTextInput',
			text: 'Residual Risk Description',
			validation: [
				{
					type: 'required',
					message: 'Enter Residual Risk Description'
				}
			]
		},
        {
			objectKey: 'residual_likelihood',
			widget: 'FormDropDown',
			text: 'Residual Risk Likelihood',
			data: [],
			widgetProps: {
				dataTextField: 'likelihood',
				dataValueField: 'likelihood'
			},
			onAfterUpdate: function(options){

				var severity = options.context.componentValue.residual_severity,
					likelihood = options.value;

				onLikelihoodSeverityUpdate(options, likelihood,  severity)
			},
			validation: [
				{
					type: 'required',
					message: 'Enter Residual Risk Likelihood'
				}
			]
		},
        {
			objectKey: 'residual_severity',
			widget: 'FormDropDown',
			text: 'Residual Risk Severity',
			data: [],
			widgetProps: {
				dataTextField: 'severity',
				dataValueField: 'severity'
			},
			onAfterUpdate: function(options){

				var severity = options.value,
					likelihood = options.context.componentValue.residual_likelihood;

				onLikelihoodSeverityUpdate(options, likelihood, severity);
			},
			validation: [
				{
					type: 'required',
					message: 'Enter Residual Risk Severity'
				}
			]
		},
    ],
    [
        {
			objectKey: 'residual_risk_action',
			widget: 'FormDropDown',
			text: 'Residual Risk Action',
			data: [],
			widgetProps: {
				dataTextField: 'residualRiskActions',
				dataValueField: 'residualRiskActions'
			},
			validation: [
				{
					type: 'required',
					message: 'Enter Residual Risk Action'
				}
			]
		},
        {
			objectKey: 'residual_risk_action_description',
			widget: 'FormMultiLineInput',
			text: 'Action Description',
			validation: [
				{
					type: 'required',
					message: 'Enter Action Description'
				}
			]
		},
        {
			objectKey: 'person_responsible_Residual_Risk',
			widget: 'FormDropDown',
			text: 'Person responsible',
			data: [],
			widgetProps: {
				dataTextField: 'name',
				dataValueField: 'name'
			},
			validation: [
				{
					type: 'required',
					message: 'Enter Person responsible'
				}
			]
		},
        {
			objectKey: 'residual_risk_outcome',
			widget: 'FormTextInput',
			text: 'Residual Risk Outcome',
			editableModes: [],
            widgetProps: {
                highlighted: true
            },
			validation: [
				{
					type: 'required',
					message: 'Enter Residual Risk Outcome'
				}
			]
		},
    ]
]