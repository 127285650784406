import TextField from '@material-ui/core/TextField';
import React from 'react';

function FormTextInput(props) {
	
	const [value, setValue] = React.useState(props.defaultValue);

	const handleInput = function(e){
		setValue(e.target.value);
	}

	const handleChange = function(event){
		if(props.onUpdateValue){
			props.onUpdateValue(event.target.value, props.objectKey);
		}
	}

	
	var _errorObj = {};
	if(props.errorObj && props.toValidate){
		_errorObj = {
			error: true,
			helperText: props.errorObj.message
		}
	}
	
	return (

		<div className="MuiInputBase-root MuiFilledInput-root MuiFilledInput-underline MuiInputBase-formControl">
			<input aria-invalid="false" 
					id={(props.id || props.objectKey)} 
					type="text" 
					value={value}
					{...props.widgetProps}
					className={"MuiInputBase-input MuiFilledInput-input "+((props.widgetProps && props.widgetProps.className)?props.widgetProps.className:'')}
					onInput={handleInput} 
					onBlur = {handleChange}
					{..._errorObj}
			/>
		</div>
	);
}

export {
	FormTextInput
}