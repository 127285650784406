import React from 'react';
import GovernanceCreate from './Routes/Create/GovernanceCreate';
import GovernanceApprovalList from './Routes/ApprovalList/GovernanceApprovalList';
import { Route} from 'react-router-dom';	


function GovernanceRoutes() {
	
	
	return (<React.Fragment>
                <Route path="/irien/governance/approve-list">
                    <GovernanceApprovalList />
                </Route>
                <Route path="/irien/governance/create">
                    <GovernanceCreate />
                </Route>
            </React.Fragment>);
}

export default GovernanceRoutes;
