import APIConfig from "../../../APIConfig";

export default {
    url: APIConfig.getRiskTestparameters,
    method: 'get',
    key: 'riskTestParams',
    dataObject: {
        riskLikelihood: ['likelihood',"residual_likelihood"],
        riskSeverity: ['severity',"residual_severity"],
        riskcontroleffectivness: ['control_effectiveness']
    }
}