import APIConfig from "../../APIConfig";
import { parseServerObject } from "../serverObjectParser";

export default {
    url: APIConfig.getMasterRecord,
    method: 'get',
    postProcessor: function(dataObj, result){
        if(result){
            dataObj.serverData = parseServerObject(result);
        }
    },
    preprocessor: function(_data){
        var currentModel = window.location.pathname.split('/').splice(-1);
        _data.url = _data.url+currentModel+'/';
        return _data;
    }
}