import React from 'react';
import { dataUIWrapper } from '../../../../../../Common/DataUIWrapper/dataUIWrapper.js';
import { PageHeader } from '../../../../../../Common/Components/PageHeader/PageHeader.js';
import { ListGrid } from '../../../../../../Common/Components/ListGrid/ListGrid.js';
import getApprovalMasterList from '../../Utils/fetchers/getApprovalMasterList.js';

const ScenarioDefinitionApprovalList = function(props){

	const { serverData=[]} = props;

	return (<PageHeader
				title={'Scenario Definition Approval List'}
				currentPage={'Scenario Definition Approval List'}
			>
				<ListGrid
					data={serverData}
					editList={["Pending"]}
					nameField={"scenario_definition_name"}
					nameTitle={"Scenario Definition"}
					columns={[
						{ field: 'model_definition_name', headerName: 'Model Definition Name', width: 280, index: 1}
					]}
					viewUrl="/ecl/scenario-definition/view/"
					editUrl="/ecl/scenario-definition/approve/"
				/>
			</PageHeader>);
}

export default dataUIWrapper(ScenarioDefinitionApprovalList, {
								dispatchers:{},
								featchers: [
									getApprovalMasterList
								]
							});