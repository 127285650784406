import React from 'react';
import EasyECLDashboard from './Routes/Dashboard/EasyECLDashboard';
import { Route} from 'react-router-dom';	


function DashboardRoutes() {
	
	
	return (<React.Fragment>
                <Route path="/ecl/dashboard">
                    <EasyECLDashboard />
                </Route>
            </React.Fragment>);
}

export default DashboardRoutes;
