var parseServerObject = function(result){

    var data = result.configurationDetails;

    data.ConfigurationName = data.configuration_name;


    return data;
}


export {parseServerObject}