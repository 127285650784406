import React from 'react';
import DSSReport from './Routes/Report/DSSReport';
import { Route} from 'react-router-dom';	


function DSSReportRouter() {
	
	
	return (<React.Fragment>
                <Route path="/irien/dss-reports/">
                    <DSSReport />
                </Route>
            </React.Fragment>);
}

export default DSSReportRouter;
