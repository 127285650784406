import React from 'react';
import DSSCreate from './Routes/Create/DSSCreate';
import DSSEdit from './Routes/Edit/DSSEdit';
import DSSView from './Routes/View/DSSView';
import DSSApprove from './Routes/Approve/DSSApprove';
import DSSApprovalList from './Routes/ApprovalList/DSSApprovalList';
import DSSList from './Routes/List/DSSList';
import { Route} from 'react-router-dom';	


function DSSRoutes() {
	
	
	return (<React.Fragment>
                <Route path="/irien/decision-support-system/list">
                    <DSSList />
                </Route>
                <Route path="/irien/decision-support-system/approve-list">
                    <DSSApprovalList />
                </Route>
                <Route path="/irien/decision-support-system/create">
                    <DSSCreate />
                </Route>
                <Route path="/irien/decision-support-system/edit/:id">
                    <DSSEdit />
                </Route>
                <Route path="/irien/decision-support-system/view/:id">
                    <DSSView />
                </Route>
                <Route path="/irien/decision-support-system/approve/:id">
                    <DSSApprove/>
                </Route>
            </React.Fragment>);
}

export default DSSRoutes;
