import React from 'react';
import ModelMasterRoutes from './Modules/ModelMaster/ModelMasterRoutes';
import ModelDefinitionRoutes from './Modules/ModelDefinition/ModelDefinitionRoutes.js';
import ScenarioDefinitionRoutes from './Modules/ScenarioDefinition/ScenarioDefinitionRoutes.js';
import DashboardRoutes from './Modules/DashBoard/DashboardRoutes.js';
import AuditRoutes from './Modules/AuditTrail/AuditsRoutes.js';
import ComputationRoutes from './Modules/Computation/ComputationRoutes.js';
import ECLReportsRouter from './Modules/Report/ECLReportsRouter.js';
/*Push Import*/

const ApplicationRoutes = function(){

    return (<React.Fragment>
                <ModelMasterRoutes/>
                <ModelDefinitionRoutes/>
				<ScenarioDefinitionRoutes/>
                <DashboardRoutes/>
                <AuditRoutes/>
				<ComputationRoutes/>
                <ECLReportsRouter/>
				{/*Push*/}
            </React.Fragment>)

}

export default ApplicationRoutes;