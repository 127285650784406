var approvalConfig = {
	config: [
		[
            {
                objectKey: 'approval_status',
                widget: 'FormRadio',
                text: 'Approval Status',
                data: [
                    {
                        value: 'Approved',
                        text: 'Approved',
                        tooltip: 'Approved model cannot be edited'
                    },
                    {
                        value: 'Rejected',
                        text: 'Rejected',
                        tooltip: 'Rejected model will change status to Draft'
                    }
                ]
            },
            {
                objectKey: 'approved_user_comment',
                widget: 'FormMultilineInput',
                text: 'Approved Comments'
            }
        ],
        [
            {
				objectKey: 'created_user',
				widget: 'FormTextInput',
				text: 'Created User',
				widgetProps: {
					disabled: true
				},
			},
			{
				objectKey: 'created_time',
				widget: 'FormDateTimeInput',
				preprocessors:'date',
				text: 'Created Time',
				widgetProps: {
					disabled: true
				},
			},
			{
				objectKey: 'created_user_ip_address',
				widget: 'FormTextInput',
				text: 'IP Address',
				widgetProps: {
					disabled: true
				},
			},
			{
				widget: 'Spacer',
			},
			{
				objectKey: 'updated_user',
				widget: 'FormTextInput',
				text: 'Updated User',
				widgetProps: {
					disabled: true
				},
			},
			{
				objectKey: 'updated_time',
				preprocessors:'date',
				widget: 'FormDateTimeInput',
				text: 'Updated Time',
				widgetProps: {
					disabled: true
				},
			}


        ]
    ]
}


export {
	approvalConfig
}