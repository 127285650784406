import React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DownloadIcon from '@mui/icons-material/Download';

const FileList = function(props){

    const {rows, onUpdate} = props;

    const columns = [{
            field: 'itemname',
            headerName: 'Document Name',
            width: 300,
            renderCell : function(params){
                 
                 return (
                    <div className="row-icon-container">
                        <div className="row-label">
                            {params.row.itemname}
                        </div>
                            {!props.view && (<IconButton title="Delete" variant="contained" onClick={()=>{props.handleDelete(params.row)}}>
                                <DeleteIcon className="ecl-delete-icon"/>
                            </IconButton>)}
                            <IconButton title="View" variant="contained" onClick={()=>{props.handleDownload(params.row)}}>
                                <DownloadIcon  className="ecl-view-icon"/>
                            </IconButton>
                    </div>
                )
            }
        },{
            field: 'iteminfo',
            headerName: 'Description',
            width: 700
        }
    ];


    return (<DataGrid rows={rows} columns={columns}/>)

}

export default FileList;