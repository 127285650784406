import parseHistoryData from "../../../../../Common/Utils/parseHistoryData";

var parseServerObject = function(result){
    var strategies = [];
    
    result.Strategies.forEach(function(_stra){
        var obj ={};
        obj.statement = _stra.Strategy;
        _stra.risks.forEach(function(_r, idx){
            obj['risk_'+idx] = _r.category_name
        });
        strategies.push(obj);
    });

    var goalsMap ={

        },
        vor = [];
    
    result.RASObjective.forEach(function(obj){
        vor.push({
            statement: obj.Strategy,
            roi: obj.ROI   
        });
        obj.goals.forEach(function(_goal){
            if(!goalsMap[_goal.missiongoal]){
                goalsMap[_goal.missiongoal]={};
            }
            if(!goalsMap[_goal.missiongoal][obj.Strategy]){
                goalsMap[_goal.missiongoal][obj.Strategy]=_goal.value;
            }
        });
    });



    return {
        raroc: result.DSSConfResult,
        ...result.DSSConfStatus[0],
        MissionGoalWeight: result.MissionGoalWeight.map(function(_mg){
            return {
                missiongoal: _mg.mission_goal,
                Weight: _mg.weight
            }
        }),
        Strategies: strategies,
        strategy_vor: vor,
        RASObjective: Object.keys(goalsMap).map(function(_goal){
            return {
                ...goalsMap[_goal],
                missiongoal: _goal
            }
        }),
        id: undefined,
        approval_history: parseHistoryData(result.DSSConfStatus[0].history) 
    };
}


export {parseServerObject}