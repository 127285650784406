var configS3 = {
	config: [
		[
			/*{
				objectKey: 'risk_id',
				widget: 'FormTextInput',
				text: 'Risk Id'
			},*/
			{
				objectKey: 'risk_category',
				id: 'risk_category',
				widget: 'FormDropDown',
				text: 'Risk Category',
				data: [	]
			},
			{
				objectKey: 'risk_subcategory',
				id: 'risk_subcategory',
				widget: 'FormDropDown',
				text: 'Risk Sub Category',
				data: [ ]
			},	
			{
				objectKey: 'risk_Statement',
				widget: 'FormMultilineInput',
				text: 'Strategy Statement',
				widgetProps:{
					disabled: true,
					readonly: true,
					className: 'ecl-spotlighted'
				}

			},	
			{
				objectKey: 'risk_description',
				widget: 'FormMultilineInput',
				text: 'Risk Description'
			}
			

		],
		[
			
			{
				objectKey: 'key_risk_indicator',
				id: 'risk_key_indicators',
				widget: 'FormDropDown',
				text: 'Risk Key Indicators',
				data: [
					
				]
			},
			
			{
				objectKey: 'key_risk_indicator_description',
				widget: 'FormMultilineInput',
				text: 'Key Risk Indicators - Description'
			},
			{
				objectKey: 'loss_tolerance_amount',
				widget: 'FormTextInput',
				text: 'Loss Tolerance Amount (CUR)',
				type: 'number',
				widgetProps:{
					disabled: true,
					readonly: true,
					className: 'ecl-spotlighted'
				}

			},{
				objectKey: 'risk_appetite_level',
				widget: 'FormTextInput',
				text: 'Risk Appetite Level',
				widgetProps:{
					disabled: true,
					readonly: true,
					className: 'ecl-spotlighted'
				}

			},

		]

	]
}


export {
	configS3
}