import SendForApprovalSwitch from "../../../../../Common/FormConfig/SendForApprovalSwitch";

export default [
    [
        {
			objectKey: 'risk_treatment',
			widget: 'FormDropDown',
			text: 'Risk Mode',
			data: [],
			widgetProps: {
				dataTextField: 'risk_treatment',
				dataValueField: 'risk_treatment'
			},
			validation: [
				{
					type: 'required',
					message: 'Enter Risk Mode'
				}
			]
		},
        {
			objectKey: 'mitigation_plan',
			widget: 'FormDropDown',
			text: 'Risk Treatment',
			data: [],
			validation: [
				{
					type: 'required',
					message: 'Enter Risk Treatment'
				}
			],
			widgetProps: {
				dataTextField: 'residualRiskTreatements',
				dataValueField: 'residualRiskTreatements'
			}
		},
        {
			objectKey: 'mitigation_description',
			widget: 'FormMultiLineInput',
			text: 'Mitigation Description',
			validation: [
				{
					type: 'required',
					message: 'Enter Mitigation Description'
				}
			],
		},
        {
			objectKey: 'mitigation_cost',
			widget: 'FormTextInput',
			text: 'Risk Treatement Cost',
			validation: [
				{
					type: 'required',
					message: 'Enter Risk Treatement Cost'
				}
			],
			widgetProps: {
				type: "number",
			}
		},
        SendForApprovalSwitch
    ],
    [
        {
			objectKey: 'person_responsible',
			widget: 'FormDropDown',
			text: 'Person responsible',
			data: [],
			widgetProps: {
				dataTextField: 'name',
				dataValueField: 'name'
			},
			validation: [
				{
					type: 'required',
					message: 'Enter Person responsible'
				}
			]
		},
        {
			objectKey: 'target_date',
			widget: 'FormDateTimeInput',
			text: 'Target Date',
			validation: [
				{
					type: 'required',
					message: 'Enter Target Date'
				}
			]
		},
        {
			objectKey: 'risk_status',
			widget: 'FormDropDown',
			text: 'Risk Status',
			data: [],
			widgetProps: {
				dataTextField: 'risk_status',
				dataValueField: 'risk_status'
			},
			validation: [
				{
					type: 'required',
					message: 'Enter Risk Status'
				}
			]
		},
    ]
]