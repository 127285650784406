import React from 'react';
import appNavConfig from './Config/AppNavConfig';
import '../../Applications/EasyECL/NavData';
import '../../Applications/IRIEN/NavData';
import '../../Applications/Rating/NavData';

import { useHistory, useLocation } from 'react-router-dom';

const AppNavContext = React.createContext({currentSuite: null});

const AppNavProvider = function(props){

    const [currentSuite, setCurrentSuite] = React.useState(null);
    const history = useHistory();
    const location = useLocation();

    const navData = appNavConfig.getNavData(currentSuite);

    const _travelToHomepage = React.useCallback(function(){
        var homePage = appNavConfig.getHomepage(currentSuite);
        history.push(homePage);
    },[currentSuite])

    const _handleSetCurrentState = React.useCallback(function(e){
        setCurrentSuite(e);
        var homePage = appNavConfig.getHomepage(e);
        history.push(homePage);
    },[]);

    const _handleRevertToHome = React.useCallback(function(){
        setCurrentSuite(null);
        history.push('/');
    },[]);

    const _handleAdminPannelClick = React.useCallback(function(){
        setCurrentSuite('admin');
        history.push('/admin');
    },[]);
  
    const _handleUserProfileClick = React.useCallback(function(){
        setCurrentSuite('admin');
        history.push('/user-profile');
    },[]);

    React.useEffect(function(){

        var pathName = location.pathname;
        if(pathName ==='/' && currentSuite){
            setCurrentSuite(null);
            return;
        }

            
        var currentApp = appNavConfig.getCurrentSuite(pathName);
        if(currentApp !== currentSuite){
            setCurrentSuite(currentApp);
        }

    },[location, currentSuite])



    return (<AppNavContext.Provider 
                value={{currentSuite: currentSuite, 
                        setCurrentSuite: _handleSetCurrentState, 
                        navData, revertToHome: _handleRevertToHome, 
                        gotoAdminPannel:_handleAdminPannelClick,
                        gotoUserProfile:_handleUserProfileClick,
                        travelToHomepage: _travelToHomepage
                    }}>
                    {props.children}
            </AppNavContext.Provider>)
}


export {AppNavProvider, AppNavContext};


