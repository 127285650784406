
const NewAgeFormConfig = {
	layoutItems: [
		{
			stepperName: '',
			itemsLayout : [
				{
					items : [
						{
							header: 'Select Model',
							componentConfig: [[
								{
									objectKey: 'model_name',
									widget: 'FormDropDown',
									text: 'Model Name',
									data: [],
									widgetProps: {
										dataTextField: 'model_name',
										dataValueField: 'model_name'
									},
									onAfterUpdate: function(options){
										options.dispatchRequest('getReport', {model_name: options.value} ).then(function(e){
						
											options.context.onUpdateValue({
												report_grid: e.data.eclAggregateDetail
											 })
										});	
									}
								},
							]]
						}
					
					]
				},
				{
					items : [
						{
							header: '',
							componentConfig: [[
								{
									objectKey: 'report_grid',
									widget: 'FormGridComponent',
									data: [],
									widgetProps: {
										enableFooter: true,
										columns: [
											{ field: 'configuration_name', headerName: 'Configuration Name',  width: 250,  minWidth: 250, flex: 1 },	
											{ field: 'scenario_definition_name', headerName: 'Scenerio Definition',  width: 250,  minWidth: 250, flex: 1 },
											{ field: 'model_definition_name', headerName: 'Model Definition',  width: 250,  minWidth: 250, flex: 1 },
											{ field: 'ecl_twelve_month_amount', headerName: '12-Month ECL',  width: 250,  minWidth: 250, flex: 1 },
											{ field: 'lifetime_ecl_amount', headerName: 'Lifetime ECL',  width: 250,  minWidth: 250, flex: 1 },
										]
									}
								},
							]]
						}
					],
					renderIf: function(context){
						return !!context.componentValue.model_name;
					}
				},
			]
		}
	],
	finalSaveParsing: function(_value){
		return _value;
	},
	defaultValue: {
	}
}


export {NewAgeFormConfig}