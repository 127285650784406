import React from 'react';
import { dataUIWrapper } from '../../../../../../Common/DataUIWrapper/dataUIWrapper.js';
import { PageHeader } from '../../../../../../Common/Components/PageHeader/PageHeader.js';
import IRIENDashboardView from './IRIENDashboardView.js';
import getRiskFactorCountReport from '../../Utils/fetchers/getRiskFactorCountReport.js';
import getRiskCategoryCountReport from '../../Utils/fetchers/getRiskCategoryCountReport.js';
import getDashboardReportByRiskValues from '../../Utils/fetchers/getDashboardReportByRiskValues.js';


const IRIENDashboard = function(props){

	return (<PageHeader
				title={'IRiEn Dashboard'}
				previousLinks={[]}
				currentPage={'Dashboard View'}
			>
				<IRIENDashboardView {...props}/>
			</PageHeader>);
}

export default dataUIWrapper(IRIENDashboard, {
								dispatchers:{
								},
								featchers: [
									getRiskFactorCountReport,
									getRiskCategoryCountReport,
									getDashboardReportByRiskValues
								]
							});