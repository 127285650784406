var mainUrl=window.riskMac.mainURL;


export default {	
		getMasterList: mainUrl+'/getECLComputationRecords/all/',
		getMasterRecord: mainUrl+'/getECLComputationRecord/',
		create: mainUrl+'/uploadCSVforScenario/',
		/**** Download */
		download: mainUrl+'/geteclconfigurationReportECLAggregate/',
		getCSV:  mainUrl+'/getsamplecsv/',
		/***** Fetchers */
		getScenerios: mainUrl+'/getScenarioDefinitionRecords/approved/',
		getDefinions: mainUrl+'/getDefinitionRecords/approved/',
		getModels: mainUrl+'/getMasterRecords/approved/'

}


