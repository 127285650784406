import React from 'react';
import {
    Chart,
    PieSeries,
    Legend,
    Tooltip,
} from '@devexpress/dx-react-chart-material-ui';
import { Animation } from '@devexpress/dx-react-chart';
import { EventTracker } from '@devexpress/dx-react-chart';
import { getPalette } from './Utils/getPalette';



const SimplePieChart = function(props){

    const {
        valueField="value",
        argumentField="title",
        data=[],
        palette,
        legendPosition="right"
    } = props;

    return (<Chart
                className={'main-model-chart'}
                data={data} 
            >
                {getPalette(palette)}
                <PieSeries
                    valueField={valueField}
                    argumentField={argumentField}
                />
                <Legend  position={legendPosition}/>
                <Animation />
                <EventTracker />
                <Tooltip />
            </Chart>)
}

export {SimplePieChart}