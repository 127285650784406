import React from 'react';
import ScenarioDefinitionCreate from './Routes/Create/ScenarioDefinitionCreate';
import ScenarioDefinitionEdit from './Routes/Edit/ScenarioDefinitionEdit';
import ScenarioDefinitionView from './Routes/View/ScenarioDefinitionView';
import ScenarioDefinitionApprove from './Routes/Approve/ScenarioDefinitionApprove';
import ScenarioDefinitionApprovalList from './Routes/ApprovalList/ScenarioDefinitionApprovalList';
import ScenarioDefinitionList from './Routes/List/ScenarioDefinitionList';
import { Route} from 'react-router-dom';	


function ScenarioDefinitionRoutes() {
	
	
	return (<React.Fragment>
                <Route path="/ecl/scenario-definition/list">
                    <ScenarioDefinitionList />
                </Route>
                <Route path="/ecl/scenario-definition/approve-list">
                    <ScenarioDefinitionApprovalList />
                </Route>
                <Route path="/ecl/scenario-definition/create">
                    <ScenarioDefinitionCreate />
                </Route>
                <Route path="/ecl/scenario-definition/edit/:id">
                    <ScenarioDefinitionEdit />
                </Route>
                <Route path="/ecl/scenario-definition/view/:id">
                    <ScenarioDefinitionView />
                </Route>
                <Route path="/ecl/scenario-definition/approve/:id">
                    <ScenarioDefinitionApprove/>
                </Route>
            </React.Fragment>);
}

export default ScenarioDefinitionRoutes;
