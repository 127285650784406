import APIConfig from "../../../APIConfig";

export default {
    url: APIConfig.getRiskOwnersSuperVisorLocations,
    method: 'get',
    key: 'ownerLocation',
    dataObject: {
        risklocations: ['location'],
        riskowners: ['risk_owner'],
        risksupervisor: ['supervisior']
    }
}