var RiskConf = {
	config: [
		[
			{
				objectKey: 'model_name',
				id: 'model_name',
				widget: 'FormDropDown',
				text: 'Type of Report',
				data: [
                    {
                        value: 'Risk View',
                        text: 'Risk View'
                    },                    
                    // {
                    //     value: 'Configuration Wise Report',
                    //     text: 'Configuration Wise Risk Rating'
                    // },
                    {
                        value: 'Division Risk Status',
                        text: 'Division Wise Risk Status'
                    },
                    {
                        value: 'Risk Configurations Info',
                        text: 'Risk Configurations Info'
                    }
                   
                ]
			}
		]
	]
}


export {
	RiskConf
}