import APIConfig from "../../APIConfig";

export default {
    method: 'get',
    url:  APIConfig.getReport,
    preprocessor: function(_obj, data){
        _obj.url = _obj.url + _obj.conf+'/';
        return _obj;
    }
}

