var RiskConf = {
	config: [
		[
			{
				objectKey: 'configuration_name',
				id: 'configuration_name',
				widget: 'FormDropDown',
				text: 'Select Configuration Name',
				data: [

                ]
			}
		]
	]
}


export default RiskConf;
