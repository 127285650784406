import React from 'react';
import APIConfig from '../../../../APIConfig.js';
import riskViewConf from './reportConf/riskViewConf.js';
import Card from '@material-ui/core/Card';
import {FormBuilder} from '../../../Global/FormBuilder/FormBuilderNew.js';
import { DataGrid } from '@material-ui/data-grid';
import  {  useHistory } from 'react-router-dom';
import {
  Chart,
  PieSeries,
  Legend,
  ArgumentAxis,
  ValueAxis,
  BarSeries,
  Tooltip,
} from '@devexpress/dx-react-chart-material-ui';
import { Stack, Animation } from '@devexpress/dx-react-chart';
import { EventTracker } from '@devexpress/dx-react-chart';
import Modal from '@mui/material/Modal';
import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';
import Box from '@mui/material/Box';
import { dataUIWrapper } from '../../../Common/DataUIWrapper/dataUIWrapper.js';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '10px',
    p: 4,
  };
const columns = [
    {
        field: 'key',
        headerName: 'Risk Configuration',
        width: 300
    },{
        field: 'value',
        headerName: 'Value',
        width: 200,
        type: 'number'
    }
];

const RiskDashboard = function(props){
    const [formData, setFormData] = React.useState({});
    const [apiConf, setApiRef] = React.useState({});
    const [formBuilderConfig, setFormBuilderConfig] = React.useState(riskViewConf);
    const [details, setDetails] = React.useState(null);
    const [_value, setValue] = React.useState(null);

	const history = useHistory();

    const parseMap = {
        RiskCategories: {
            request: 'getRiskCategorys',
            parser: function(_d){
                return _d.data.map(function(_data){
                    return {
                        text: _data.risk_category,
                        value: _data.risk_category,
                    }
                });
            }
        },
        Division: {
            request: 'getDivisions',
            parser: function(_d){
                return _d.data.map(function(_data){
                    return {
                        text: _data.division,
                        value: _data.division,
                    }
                });
            }
        },
        Location: {
            request: 'getRiskOwnersSuperVisorLocations',
            parser: function(_d){
                return _d.data.risklocations.map(function(_data){
                    return {
                        text: _data.location,
                        value: _data.location,
                    }
                });
            }
        },
        RiskOwner: {
            request: 'getRiskOwnersSuperVisorLocations',
            parser: function(_d){
                return _d.data.riskowners.map(function(_data){
                    return {
                        text: _data.risk_owner,
                        value: _data.risk_owner,
                    }
                });
            }
        },
        Supervisior: {
            request: 'getRiskOwnersSuperVisorLocations',
            parser: function(_d){
                return _d.data.risksupervisor.map(function(_data){
                    return {
                        text: _data.supervisor,
                        value: _data.supervisor,
                    }
                });
            }
        }
    }

    const handleScreenUpdate = React.useCallback(function(key, value){
        if(key==='configuration_name'){
            setDetails(null);
            setValue(value);
            var _c = {
                    config: [...formBuilderConfig.config]
                }
                _c.config[1][0] = {
                    objectKey: 'sub_value',
                    id: 'sub_value',
                    widget: 'FormDropDown',
                    text: value,
                    data: [ ]
                }
                if(parseMap[value]){
                    props.dispatchRequest(parseMap[value].request).then(function(_d){
                        var _data = parseMap[value].parser(_d);
                        _c.config[1][0].data = _data;
                        setFormBuilderConfig(_c);
                    });
                }
        }else{
            props.dispatchRequest('getReportsByValues', { data: {"reportUnit": _value,"value" : value
            }}).then(function(_d){ 
                var grid1Rows = [
                    {
                        id: 0,
                        key: 'Inherent Risk',
                        value: _d.data.Total_inherentRisk
                    },{
                        id: 1,
                        key: 'Residual Risk',
                        value: _d.data.Total_residualRisk
                    },
                ];
                var grid2Rows = [
                    {
                        id: 0,
                        key: 'Risk Control Cost',
                        value: _d.data.Total_riskControlCost,
                        type: 'number'
                    },{
                        id: 1,
                        key: 'Risk Treatment Cost',
                        value: _d.data.Total_riskTreatmentCost,
                        type: 'number'
                    },
                ];

                var riskTreatmentModes = Object.keys(_d.data.RiskTreatementModes).map(function(_key){
                    return  {key: _key, value: _d.data.RiskTreatementModes[_key]}
                });

                var residualRiskActions =  Object.keys(_d.data.RiskResidualActions).map(function(_key){
                    return  {key: _key, value: _d.data.RiskResidualActions[_key]}
                });


                var riskControlStatus =  Object.keys(_d.data.RiskControlStatus).map(function(_key){
                    return  {key: _key, value: _d.data.RiskControlStatus[_key]}
                });

                var riskControlEffectiveNess =  Object.keys(_d.data.RiskControlEffectiveNess).map(function(_key){
                    return  {key: _key, value: _d.data.RiskControlEffectiveNess[_key]}
                });

                var riskImpactTypes =  Object.keys(_d.data.RiskImpactTypes).map(function(_key){
                    return  {key: _key, value: _d.data.RiskImpactTypes[_key]}
                });

                var reverseMap = {};
                _d.data.RiskTreatementCostvsReductionCosts.forEach(function(_item){
                    reverseMap[_item.Risk] = _item;
                })
                debugger;
                setDetails({
                    grid1: grid1Rows,
                    grid2: grid2Rows,
                    inheritRisk: _d.data.Top_inherentRisk,
                    residualRisk: _d.data.Top_residualRisk,
                    riskTreatmentModes: riskTreatmentModes,
                    residualRiskActions: residualRiskActions,
                    riskControlEffectiveNess: riskControlEffectiveNess,
                    riskControlStatus: riskControlStatus,
                    riskImpactTypes: riskImpactTypes,
                    riskTreatementCostvsReductionCosts: _d.data.RiskTreatementCostvsReductionCosts,
                    rTCreverseMap: reverseMap,
                    riskInherentHeatMap: _d.data.RiskInherentHeatMap,
                    riskResidualHeatMap: _d.data.RiskResidualHeatMap,
                    riskControlActions: _d.data.RiskControlActions.map(function(_item, idx){
                        return {
                            id: idx,
                            ..._item
                        }
                    })
                })
            
            })

        }
    },[_value]);

    const [selectedRiskMap, setSelectedRiskMap] = React.useState([]);


    return (<div>
                <Card className="main-ui-card">
                    <FormBuilder apiConf={apiConf} {...formBuilderConfig}  data = { formData } onUpdate={handleScreenUpdate}  />
                </Card>
                {details && (<div>
                                
                                 <div className="card-flex-column">
                                    <Card className="main-ui-card graph-card-div">
                                        <Typography variant="h6" className="app-sub-title ecl-header-title report-heading">
                                         Inherent Risk Heat Map
                                        </Typography>
                                        <HeatMap data={details.riskInherentHeatMap} onCellClick={(e)=>setSelectedRiskMap(e.items)} />
                                    </Card>

                                    <Card className="main-ui-card  graph-card-div">
                                        <Typography variant="h6" className="app-sub-title ecl-header-title report-heading">
                                            Residual Risk Heat Map
                                        </Typography>
                                         <HeatMap data={details.riskResidualHeatMap} onCellClick={(e)=>setSelectedRiskMap(e.items)} />
                                    </Card>  
                                </div>
                                
                                <Modal
                                    open={!!selectedRiskMap.length}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={style}>
                                        <Typography className="app-sub-title ecl-header-title report-heading" variant="h6">
                                            Risks
                                        </Typography>
                                        <Typography id="modal-modal-title" variant="h6" component="h4">
                                            Click to View
                                        </Typography>
                                        <div className="erm-config-list">
                                            {selectedRiskMap.map(function(_r, idx){
                                                return (<div onClick={function(){
                                                    		history.push('/integated-risk/risk-details/view/'+_r.config_name);
                                                }} className="erm-list-item erm-clickable" key={idx}><span className=" report-heading list-index">{idx+1} - </span>{_r.name}</div>)
                                            })}
                                        </div>
                                        <Button variant="contained"onClick={()=>setSelectedRiskMap([])}>
                                            Done
                                        </Button>
                                    </Box>
                                </Modal>
                                
                                
                                
                                <div className="card-flex-column">
                                    <Card className="main-ui-card">
                                        <Typography variant="h6" className="app-sub-title ecl-header-title report-heading">
                                                Residual vs Inherent Risk Cost
                                        </Typography> 
                                        <div className={"dss-grid-container grid-no-header report-grid report-grid-compar "}>
                                            <Chart
                                                data={details.grid1 || []}
                                                rotated
                                                className={'chart-100-per'}
                                            >
                                                <ValueAxis />
                                                <ArgumentAxis />
                                                <BarSeries
                                                    name=""
                                                    valueField='value'
                                                    argumentField='key'
                                                />
                                                <Stack/>
                                                <EventTracker />
                                                <Animation />
                                                    <Tooltip />
                                            </Chart>
                                           
                                        </div>
                                    </Card>
                                    <Card className="main-ui-card">
                                        <Typography variant="h6" className="app-sub-title ecl-header-title report-heading">
                                                Risk Treatment vs Risk Control Cost
                                        </Typography>                                         
                                        <div className={"dss-grid-container grid-no-header report-grid  report-grid-compar"}>
                                            <Chart
                                                data={details.grid2 || []}
                                                rotated
                                                className={'chart-100-per'}
                                            >
                                                <ValueAxis />
                                                <ArgumentAxis />
                                                <BarSeries
                                                    name=""
                                                    valueField='value'
                                                    argumentField='key'
                                                />
                                                <Stack/>
                                                <EventTracker />
                                                <Animation />
                                                    <Tooltip />
                                            </Chart>
                                            {/*<DataGrid rows={details.grid2} columns={columns}  />*/}
                                        </div>
                                    </Card>
                                </div>

                                <div className="card-flex-column">
                                    <Card className="main-ui-card graph-card-div">
                                        <Typography variant="h6" className="app-sub-title ecl-header-title report-heading">
                                            Risk Treatment Modes
                                        </Typography>
                                       
                                           <Chart
                                                data={details.riskTreatmentModes || []}
                                                rotated
                                                className={'chart-100-per'}
                                            >
                                                <ValueAxis />
                                                <ArgumentAxis />
                                                <BarSeries
                                                    name=""
                                                    valueField='value'
                                                    argumentField='key'
                                                />
                                                <Stack/>
                                                <EventTracker />
                                                <Animation />
                                                    <Tooltip />
                                            </Chart>
                                    </Card>

                                    <Card className="main-ui-card  graph-card-div">
                                        <Typography variant="h6" className="app-sub-title ecl-header-title report-heading">
                                            Residual Risk Actions
                                        </Typography>
                                          <Chart
                                                data={details.residualRiskActions || []}
                                                rotated
                                                className={'chart-100-per'}
                                            >
                                                <ValueAxis />
                                                <ArgumentAxis />
                                                <BarSeries
                                                    name=""
                                                    valueField='value'
                                                    argumentField='key'
                                                />
                                                <Stack/>
                                                <EventTracker />
                                                <Animation />
                                                    <Tooltip />
                                            </Chart>
                                    </Card>  
                                </div>

                                <div className="card-flex-column">
                                    <Card className="main-ui-card graph-card-div">
                                        <Typography variant="h6" className="app-sub-title ecl-header-title report-heading">
                                            Risk Control Status
                                        </Typography>
                                       
                                           <Chart
                                                data={details.riskControlStatus || []}
                                                rotated
                                                className={'chart-100-per'}
                                            >
                                                <ValueAxis />
                                                <ArgumentAxis />
                                                <BarSeries
                                                    name=""
                                                    valueField='value'
                                                    argumentField='key'
                                                />
                                                <Stack/>
                                                <EventTracker />
                                                <Animation />
                                                    <Tooltip />
                                            </Chart>
                                    </Card>

                                    <Card className="main-ui-card graph-card-div">
                                        <Typography variant="h6" className="app-sub-title ecl-header-title report-heading">
                                            Risk Control Effectiveness
                                        </Typography>
                                          <Chart
                                                data={details.riskControlEffectiveNess || []}
                                                rotated
                                                className={'chart-100-per'}
                                            >
                                                <ValueAxis />
                                                <ArgumentAxis />
                                                <BarSeries
                                                    name=""
                                                    valueField='value'
                                                    argumentField='key'
                                                />
                                                <Stack/>
                                                <EventTracker />
                                                <Animation />
                                                    <Tooltip />
                                            </Chart>
                                    </Card>  
                                </div>

                                <div className="card-flex-column">
                                    <Card className="main-ui-card graph-card-div" style={{"height": (details.riskImpactTypes.length*75<350?350:details.riskImpactTypes.length*75)}}>
                                        <Typography variant="h6" className="app-sub-title ecl-header-title report-heading">
                                            Risk Impact Types
                                        </Typography>
                                       
                                           <Chart
                                                data={details.riskImpactTypes || []}
                                                rotated
                                                className={'chart-100-per'}
                                            >
                                                <ValueAxis />
                                                <ArgumentAxis />
                                                <BarSeries
                                                    name=""
                                                    valueField='value'
                                                    argumentField='key'
                                                />
                                                <Stack/>
                                                <EventTracker />
                                                <Animation />
                                                    <Tooltip />
                                            </Chart>
                                    </Card>
                                </div>

                                <div className="card-flex-column" >
                                    <Card className="main-ui-card graph-card-div" style={{"height": (details.riskTreatementCostvsReductionCosts.length*75<350?350:details.riskTreatementCostvsReductionCosts.length*75)}}>
                                        <Typography variant="h6" className="app-sub-title ecl-header-title report-heading">
                                           Risk Reduced v/s Risk Reduction Cost
                                        </Typography>
                                       
                                           <Chart
                                                data={details.riskTreatementCostvsReductionCosts || []}
                                                rotated
                                                className={'chart-100-per'}
                                            >
                                                <ValueAxis />
                                                <ArgumentAxis labelComponent={function(props){
                                                    var ddy = parseFloat(props.dy) + 1.2 + 'em';
                                                    return (
                                                        <>
                                                        <text class="Component-root-12" x={props.x} y={props.y} dy={props.dy} text-anchor={props.textAnchor}>{props.text}</text>
                                                        <text class="Component-root-12 reduction-cost" 
                                                            x={props.x} dx={(-(details.rTCreverseMap[props.text].RiskControlandMitigationCost+'').length)*0.7+'em'} 
                                                            y={props.y} dy={ddy} text-anchor={props.textAnchor}>{details.rTCreverseMap[props.text].RiskReducedCost}</text>
                                                          <text class="Component-root-12" 
                                                            x={props.x + 8} dx={(-(details.rTCreverseMap[props.text].RiskControlandMitigationCost+'').length)*0.7+'em'}
                                                            y={props.y} dy={ddy} text-anchor={props.textAnchor}> / </text>   
                                                        <text class="Component-root-12 control-cost" x={props.x} y={props.y} dy={ddy} text-anchor={props.textAnchor}>{details.rTCreverseMap[props.text].RiskControlandMitigationCost}</text>
                                                        </>)
                                                }}/>
                                                <BarSeries
                                                    name="Risk Reduction Cost"
                                                    valueField='RiskControlandMitigationCost'
                                                    argumentField='Risk'
                                                />
                                                 <BarSeries
                                                    name="Risk Reduced"
                                                    valueField='RiskReducedCost'
                                                    argumentField='Risk'
                                                />
                                                <Legend position="top"/>
                                                <Stack/>
                                                <EventTracker />
                                                <Animation />
                                                <Tooltip />
                                            </Chart>
                                    </Card>
                                </div>


                                
                                <div className="card-flex-column" >
                                    <Card className="main-ui-card" >
                                        <Typography variant="h6" className="app-sub-title ecl-header-title report-heading">
                                            Action to be Taken Targets | Controls
                                        </Typography>

                                        <div className="dss-grid-container">
                                             <DataGrid rows={details.riskControlActions || []} columns={[
                                                    {
                                                        field: 'risk_conf_name',
                                                        headerName: 'Risk Name',
                                                        width: 200,
                                                    },{
                                                        field: 'control_persone_Responsible',
                                                        headerName: 'Person Responsible',
                                                        width: 300,
                                                    },{
                                                        field: 'control_action',
                                                        headerName: 'Control Action',
                                                        width: 800,
                                                    },{
                                                        field: 'control_status',
                                                        headerName: 'Control Status',
                                                        width: 200,
                                                    }
                                                ]} />
                                        </div>
                                    </Card>
                                </div>

                                {/*<div className="card-flex-column">
                                    <Card className="main-ui-card ">
                                        <Typography variant="h6" className="app-sub-title ecl-header-title report-heading">
                                            Top 5 Inherent Risks
                                        </Typography>
                                       
                                        <List data={details.inheritRisk} />
                                    </Card>

                                    <Card className="main-ui-card">
                                        <Typography variant="h6" className="app-sub-title ecl-header-title report-heading">
                                            Top 5 Residual Risks
                                        </Typography>
                                    <List data={details.residualRisk} />
                                    </Card>  
                                </div>*/}

                            </div>)}
            </div>) ;

}


var createDispatchers = {
    getRiskCategorys:{
        url: APIConfig.erm.getRiskCategorys,
        method: 'get'
    },
    getDivisions:{
        url: APIConfig.erm.getDivisions,
        method: 'get'
    },
    getRiskDepartments:{
        url: APIConfig.erm.getRiskDepartments,
        method: 'get'
    },
    getRiskOwnersSuperVisorLocations:{
        url: APIConfig.erm.getRiskOwnersSuperVisorLocations,
        method: 'get'
    },
    getReportsByValues: {
        url: APIConfig.erm.getReportsByValues,
        method: 'put'
    }
};


var fetchingData = [
    {
        url: APIConfig.erm.getReportRiskRegistersNames,
        method: 'get',
        key: 'riskRegistersNames'
    }
];


const HeatMap = function(props){

    return (<div className="erm-heat-map">
                <div className="erm-heat-map-row">

                    <div className="erm-heat-map-cell" intencity="0"
                        onClick={()=>{props.onCellClick(props.data[0][0])}}
                    >
                        {props.data[0][0].value}
                    </div>
                    <div className="erm-heat-map-cell" intencity="1"
                        onClick={()=>{props.onCellClick(props.data[0][1])}}
                    >
                        {props.data[0][1].value}
                    </div>
                    <div className="erm-heat-map-cell" intencity="2"
                        onClick={()=>{props.onCellClick(props.data[0][2])}}
                    >
                        {props.data[0][2].value}
                    </div>
                    <div className="erm-heat-map-cell" intencity="2"
                        onClick={()=>{props.onCellClick(props.data[0][3])}}
                    >
                        {props.data[0][3].value}
                    </div>
                    <div className="erm-heat-map-cell" intencity="3"
                        onClick={()=>{props.onCellClick(props.data[0][4])}}
                    >
                        {props.data[0][4].value}
                    </div>
                
                </div>
                <div className="erm-heat-map-row">
                    
                    <div className="erm-heat-map-cell" intencity="0"
                        onClick={()=>{props.onCellClick(props.data[1][0])}}
                    >
                        {props.data[1][0].value}
                    </div>
                    <div className="erm-heat-map-cell" intencity="1"
                        onClick={()=>{props.onCellClick(props.data[1][1])}}
                    >
                        {props.data[1][1].value}
                    </div>
                    <div className="erm-heat-map-cell" intencity="1"
                        onClick={()=>{props.onCellClick(props.data[1][2])}}
                    >
                        {props.data[1][2].value}
                    </div>
                    <div className="erm-heat-map-cell" intencity="2"
                        onClick={()=>{props.onCellClick(props.data[1][3])}}
                    >
                        {props.data[1][3].value}
                    </div>
                    <div className="erm-heat-map-cell" intencity="2"
                        onClick={()=>{props.onCellClick(props.data[1][4])}}
                    >
                        {props.data[1][4].value}
                    </div>

                </div>
                <div className="erm-heat-map-row">
                    
                    <div className="erm-heat-map-cell" intencity="0"
                        onClick={()=>{props.onCellClick(props.data[2][0])}}
                    >
                        {props.data[2][0].value}
                    </div>
                    <div className="erm-heat-map-cell" intencity="0"
                        onClick={()=>{props.onCellClick(props.data[2][1])}}
                    >
                        {props.data[2][1].value}
                    </div>
                    <div className="erm-heat-map-cell" intencity="1"
                        onClick={()=>{props.onCellClick(props.data[2][2])}}
                    >
                        {props.data[2][2].value}
                    </div>
                    <div className="erm-heat-map-cell" intencity="1"
                        onClick={()=>{props.onCellClick(props.data[2][3])}}
                    >
                        {props.data[2][3].value}
                    </div>
                    <div className="erm-heat-map-cell" intencity="2"
                        onClick={()=>{props.onCellClick(props.data[2][4])}}
                    >
                        {props.data[2][4].value}
                    </div>
                    
                </div>
                <div className="erm-heat-map-row">
                    
                    <div className="erm-heat-map-cell" intencity="0"
                        onClick={()=>{props.onCellClick(props.data[3][0])}}
                    >
                        {props.data[3][0].value}
                    </div>
                    <div className="erm-heat-map-cell" intencity="0"
                        onClick={()=>{props.onCellClick(props.data[3][1])}}
                    >
                        {props.data[3][1].value}
                    </div>
                    <div className="erm-heat-map-cell" intencity="0"
                        onClick={()=>{props.onCellClick(props.data[3][2])}}
                    >
                        {props.data[3][2].value}
                    </div>
                    <div className="erm-heat-map-cell" intencity="1"
                        onClick={()=>{props.onCellClick(props.data[3][3])}}
                    >
                        {props.data[3][3].value}
                    </div>
                    <div className="erm-heat-map-cell" intencity="1"
                        onClick={()=>{props.onCellClick(props.data[3][4])}}
                    >
                        {props.data[3][4].value}
                    </div>
                    
                </div>
                <div className="erm-heat-map-row">
                    
                    <div className="erm-heat-map-cell" intencity="0"
                        onClick={()=>{props.onCellClick(props.data[4][0])}}
                    >
                        {props.data[4][0].value}
                    </div>
                    <div className="erm-heat-map-cell" intencity="0"
                        onClick={()=>{props.onCellClick(props.data[4][1])}}
                    >
                        {props.data[4][1].value}
                    </div>
                    <div className="erm-heat-map-cell" intencity="0"
                        onClick={()=>{props.onCellClick(props.data[4][2])}}
                    >
                        {props.data[4][2].value}
                    </div>
                    <div className="erm-heat-map-cell" intencity="0"
                        onClick={()=>{props.onCellClick(props.data[4][3])}}
                    >
                        {props.data[4][3].value}
                    </div>
                    <div className="erm-heat-map-cell" intencity="0"
                        onClick={()=>{props.onCellClick(props.data[4][4])}}
                    >
                        {props.data[4][4].value}
                    </div>
                    
                </div>
            </div>)

}


var RiskDashboardModule = dataUIWrapper(RiskDashboard);

const ExportModule = function(props){
    return (<RiskDashboardModule fetchingData={fetchingData}  dispatcher={createDispatchers} />)
}

export default ExportModule;