export default [
    [
        {
            objectKey: 'raroc',
            widget: 'FormGridComponent',
            widgetProps: {
                columns: [
                    {
                        field: 'Strategy',
                        headerName: 'Strategy Statement',
                        width: 500,
                        headerAlign: 'center',
                    },{
                        field: 'risk_wtd_score',
                        headerName: 'Strategy Statement Score',
                        width: 200,
                        headerAlign: 'center',
                        align: 'center',
                    },{
                        field: 'amt_at_risk_pre_controls',
                        headerName: 'Amt at Risk (At Pre-Controls & Mitigation Stage)',
                        width: 200,
                        type: 'number',
                        headerAlign: 'center',
                        align: 'center',
                    },{
                        field: 'amt_at_risk_post_controls',
                        headerName: 'Amt at Risk (At Post-Controls & Mitigation Stage)',
                        width: 200,
                        type: 'number',
                        headerAlign: 'center',
                        align: 'center',
                    },
                    {
                        field: 'risk_control_mitigation_cost',
                        headerName: 'Risk Control and Mitigation Cost',
                        width: 200,
                        type: 'number',
                        headerAlign: 'center',
                        align: 'center',
                    },{
                        field: 'return_from_objective',
                        headerName: 'Return from Objective',
                        width: 200,
                        type: 'number',
                        headerAlign: 'center',
                        align: 'center',
                    },{
                        field: 'risk_adjusted_return_pre_control',
                        headerName: 'Risk Adjusted Return at Pre-Control & Mitigation stage (%)',
                        width: 250,
                        headerAlign: 'center',
                        align: 'center',
                    },{
                        field: 'risk_adjusted_return_post_control',
                        headerName: 'Risk Adjusted Return at Post-Control & Mitigation stage (%)',
                        width: 250,
                        type: 'number',
                        headerAlign: 'center',
                        align: 'center',
                    }
        
                ]
            }
        }
    ]
]