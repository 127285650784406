var mainUrl=window.riskMac.mainURL;


export default {	
		getMasterList: mainUrl+'/getDssConfsResult/all/',
		getMasterRecord: mainUrl+'/getDssConfs/',
		create: mainUrl+'/createDssConf/',
		delete: mainUrl+'/deleteDssConf/',
		edit: mainUrl+'/createDssConf/',
		approve: mainUrl+'/updateDSSResultApproval/',
		approvalList: mainUrl+'/getDssConfsResult/allaction/',
		/*** Fetchers Data */
		getMisssionGoals: mainUrl + '/getOrgMisssionGoals/',
		getRAS: mainUrl + '/getRAStatements/',
		getStrategies:mainUrl + '/getStrategies/',
		getERMConfigs: mainUrl + '/getERMConfigs/all/',
		/** Dispatchers */
		getRASConfigs: mainUrl + '/getRASConfigs/',
		
}


