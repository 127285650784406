import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {NavLink} from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import logo from '../../rmicon.png';
import logo1 from '../../irienicon.png';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import { AppNavContext } from './ApplicationNavProvider/AppNavProvider';
import { LoginContext } from '../../Context/LoginContext/LoginContext';

function NavigationBar() {
	
	const {loginStatus} = React.useContext(LoginContext);
	
	const {currentSuite, navData, travelToHomepage} = React.useContext(AppNavContext);

	var getSubItems,
		accordianData=navData;
	
	const [expanded, setExpanded] = React.useState(['panel-modelling', 'panel-modal-master','integrated-enterprise-risk-risk-register']);
  
	React.useEffect(function(){
		var expanded = [];
		
		const checkForExpanded = function(items){
			if(items.items){
				expanded.push(items.id);
				items.items.forEach(checkForExpanded);
			}
			
		}
		
		accordianData.forEach(function(_data){
			_data.items.forEach(checkForExpanded);
		});
	
		setExpanded(expanded);

	},[accordianData]);

	const handleChange = React.useCallback((panel) => () => {
		
		var index = expanded.indexOf(panel);
		var _e = expanded.slice();
		if(index>=0){
			_e.splice(index, 1);
		}else{
			_e.push(panel);
		}
		setExpanded(_e);
	},[expanded, setExpanded]);
	
	
	const makeAccordian = React.useCallback(function(data){
		
		return data.map(function(_d){
			
			if(!_d.items){
				return  (
				<NavLink key={_d.link} to={_d.link} exact className="accordian-link navbar-link-flex" activeClassName="link-active">
					<Typography className="navbar-link">
						{_d.title}
					</Typography>
					{_d.plusIconLink && (<NavLink to={_d.plusIconLink} exact activeClassName="link-active">
						<IconButton title="Download" variant="contained">
							<AddIcon/>
						</IconButton>
					</NavLink>)}
				</NavLink>									
				)
			}
			
			return (
				<Accordion key={_d.id} expanded={expanded.indexOf(_d.id)>=0} onChange={handleChange(_d.id)} className="navigation-accorian">
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
					>
						<Typography>{_d.title}</Typography>
					</AccordionSummary>
					<AccordionDetails className="navbar-details">
						{ getSubItems(_d) }
					</AccordionDetails>
				</Accordion>
			)
		});
		
		
	},[expanded, getSubItems, handleChange]);
	
	getSubItems = React.useCallback(function(_d){
		return _d.items.map(function(_i, idx){
				if(_i.items){
					return makeAccordian([_i])
				}
				return (
				<NavLink key={_i.link} to={_i.link} exact activeClassName="link-active" className="navbar-link-flex">
					<Typography className="navbar-link">
						{_i.title}
					</Typography>
					{_i.plusIconLink && (<NavLink to={_i.plusIconLink} exact activeClassName="link-active">
						<IconButton title="Download" variant="contained">
							<AddIcon/>
						</IconButton>
					</NavLink>)}
				</NavLink>									
				)
		});
	},[makeAccordian]);
	
	const AccordianItems = React.useMemo(function(){
		return accordianData.map(function(_data){
			return (<><div className="nav-header">{_data.header}</div>
						{makeAccordian(_data.items)}
					</>)
			
		});
	},[makeAccordian, accordianData]);
	
	if(!currentSuite || currentSuite==='admin' || loginStatus !== 'logged-in'){
		return null;
	}
	
	return (
		<div className="navigation-bar">
			<AppBar className={"app-header-title-section suite-"+currentSuite}>
				<img  className="navigation-logo" src={logo} alt="Logo" onClick={travelToHomepage}/>
				{currentSuite==='iriene' && (<img  className="navigation-logo1" src={logo1} alt="Logo1"/>)}
			</AppBar>
			<div className="full-container padding-10">
				{AccordianItems}
			</div>
		</div>
	);
}

export {
	NavigationBar
}