import React from 'react';
import { dataUIWrapper } from '../../../../../../Common/DataUIWrapper/dataUIWrapper.js';
import { NewAgeFormBuilder } from '../../../../../../Common/ApplicationFormBuilder/FormBuilder/NewAgeFormBuilder.js';
import { useHistory } from 'react-router-dom';
import { NewAgeFormConfig } from '../../formJSON/newAgeConfig.js';
import { PageHeader } from '../../../../../../Common/Components/PageHeader/PageHeader.js';
import CreateDispatchers from '../../Utils/dispatchers/CreateDispatchers.js';
import getApprovedDefinitions from '../../Utils/fetchers/getApprovedDefinitions.js';
import fetchDefinition from '../../Utils/dispatchers/fetchDefinition.js';

const ScenarioDefinitionCreate = function(props){

	const [value, setValue] = React.useState({});
	const [componentExts, setComponentExts] = React.useState({});
	
	const history = useHistory();

	const {dispatchRequest, definitions} = props;

	React.useEffect(function(){
		if(definitions){
			setComponentExts((_ogExts)=>{
				return ({
					..._ogExts,
					'model_definition_name': {
						data: definitions
					}
				});
			})
		}
	},[definitions]);

	const _handleSave = React.useCallback(function(_value){
		dispatchRequest('create', {data: _value} ).then(function(){
			history.push('/ecl/scenario-definition/list/');
		});
	},[dispatchRequest]);


	return (<PageHeader
					title={'Create Scenario Definition'}
					previousLinks={[
						{
							href: "/ecl/scenario-definition/list/",
							text: 'Scenario Definition (All List)'
						}
					]}
					currentPage={'Create'}
				>
				<NewAgeFormBuilder 
					config={NewAgeFormConfig} 
					value={value}
					onChange={setValue}
					dispatchRequest={props.dispatchRequest}
					onSave={_handleSave}
					componentExts={componentExts}
					onUpdateComponentExts={setComponentExts}
				/>
			</PageHeader>);
}



export default dataUIWrapper(ScenarioDefinitionCreate, {
								dispatchers:{
									create: CreateDispatchers,
									fetchDefinition: fetchDefinition
								},
								featchers: [
									getApprovedDefinitions
								]
							});