import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { Link} from "react-router-dom";
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';


const AppApproveIcon = function(props){

    const {link='#', onClick, title="Approve"} = props;

    return (<Link to={link}>
                <IconButton title={title} variant="contained" onClick={onClick}>
                    <PlaylistAddCheckIcon className="ecl-view-icon ecl-edit-icon"/>
                </IconButton>
            </Link>)
}

export {AppApproveIcon}