import React from 'react';
import {widgets} from './FormWidgetsNew.js';
import preprocessors from './Preprocessors/preprocessors.js';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';


var useFormObject = function(data){

	const [object, setObject] = React.useState(data || {});
	const [lastUpdated, setLastUpdated] = React.useState(data || {});
	
	const setObj = function(obj){
		
		setObject({
			...object,
			...obj
		}); 
		setLastUpdated(Date.now());
	}
	
	
	const set = React.useCallback(function(key, value){
		var _obj = {
			...object,
		}
		_obj[key] = value;
		setObject(_obj);
		//  
		setLastUpdated(Date.now());
	},[object, lastUpdated]);
	
	return [object, set, setObj];
	
}




const FormBuilder = React.forwardRef((props, ref)=> {

  const [refFormObject, setOnFormObj, setFormObj] = useFormObject(props.data);
  const [configState, setConfigState] = React.useState(props.config);
  
  
  React.useEffect(()=>{
		setFormObj(props.data);
  },[props.data]);
  
  React.useEffect(function(){
	  setConfigState(props.config);
  },[props.config]);
    
  React.useEffect(function(){
	  if(props.apiConf){
			props.apiConf.getData = function(data){
				return refFormObject;
			}
	  }
  },[props.apiConf, refFormObject]);
  
  const updateObject = function(value, key){
	setOnFormObj(key, value);
	if(typeof props.onUpdate === 'function'){
		props.onUpdate(key, value)
	}
  }
  
  const getTooltip = function(tt){
	  if(tt){
		 return( <Tooltip title={tt}>
			<InfoIcon/>
		  </Tooltip> )
	  }else{
		return null;  
	  }
  }
  
  const GetForm = function(_props){
	var config = _props.config
	return config.map(function(_conf, idx){

			var widgetName = _conf.widget;
			if(props.mode==='view' && widgetName =='FormDropDown'){
				widgetName = 'FormTextInput'
			}
				
			var TagName = widgets[widgetName],
				key = (_conf.id || _conf.objectKey|| idx),
				defaultValue = (refFormObject)[_conf.objectKey];
		
		if(_conf.preprocessors && preprocessors[_conf.preprocessors]){
			defaultValue = preprocessors[_conf.preprocessors](defaultValue)
		}

		if(props.mode==="view"){
			if(!_conf.widgetProps)
			{
				_conf.widgetProps = {
					InputProps:{ readOnly: true }
				}

			}else{
				if(!_conf.widgetProps.InputProps){
					_conf.widgetProps.InputProps = { readOnly: true }
				}else{
					_conf.widgetProps.InputProps.readOnly= true
				}
					
			}
		}

		var _title;
		if(typeof defaultValue!=='object' && typeof defaultValue !== 'undefined'){
			_title = String(defaultValue);
		}
		
		return (
			<div key={key} className="form-builder-form-wrapper" title={_title}>
				<div className="form-label">
				{_conf.text}
				</div>
				<div className={"form-input " + (_conf.tooltip?'tooltip-form':'')}>
					<TagName {..._conf} defaultValue={defaultValue} onUpdateValue={updateObject}/>
					{getTooltip(_conf.tooltip)}
				</div>
			</div>
		)
	});
	
		
  }
  
  
  const processForm = function(config){
	  
	 var _map = config.map(function(_c, idx){
		 return (
			<div key={idx} className={"ecl-form-builder-column "+(props.mode==='view'?" form-read-only":'')}>
				<GetForm config={_c} mode={props.mode}/>			
			</div>
		 )
		  
	  });
	  return _map;
	  
  }
  
  return (
    <div ref={ref} className="ecl-form-builder-wrapper">
		{processForm(configState)}
	</div>
  );

})

export {
	FormBuilder
}