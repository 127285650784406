var mainUrl=window.riskMac.mainURL;


export default {	
		getMasterList: mainUrl+'/getratingconfigurationReport/all/',
		getMasterRecord: mainUrl+'/getratingconfigurationReport/',
		create: mainUrl+'/ratinguploadCSVforComputaion/',
		/**** Download */
		download: mainUrl+'/getratingconfigurationReport/',
		getCSV:  mainUrl+'/ratinggetsamplecsv/',
		/***** Fetchers */
		getDefinions: mainUrl+'/ratinggetDefinitionRecords/approved/',
		getModels: mainUrl+'/ratinggetMasterRecords/approved/'

}


