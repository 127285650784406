import APIConfig from "../../../APIConfig";

export default {
    url: APIConfig.getRAS,
    method: 'get',
    postProcessor: function(dataObj, result){
        if(result){
            dataObj.ras = result;
        }
    }
}