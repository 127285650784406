import TextField from '@material-ui/core/TextField';
import React from 'react';

function FormMultilineInput(props) {
	
	const [value, setValue] = React.useState(props.defaultValue);

	const {widgetProps={}}= props;

	const handleInput = function(e){
		setValue(e.target.value);
	}

	const handleChange = function(event){
		if(props.onUpdateValue){
			props.onUpdateValue(event.target.value, props.objectKey);
		}
	}

	
	var _errorObj = {};
	if(props.errorObj && props.toValidate){
		_errorObj = {
			error: true,
			helperText: props.errorObj.message
		}
	}
	
	var _className = ('MuiInputBase-input MuiFilledInput-input '+ (widgetProps.className?widgetProps.className:''));

	return (

		<div class="MuiInputBase-root MuiFilledInput-root MuiFilledInput-underline MuiInputBase-formControl">
			<textarea aria-invalid="false" 
					id={(props.id || props.objectKey)} 
					type="text" 
					value={value}
					onInput={handleInput} 
					onBlur = {handleChange}
					{...props.widgetProps}
					{..._errorObj}
					className={_className}
			/>
		</div>
	);
}

export {
	FormMultilineInput
}