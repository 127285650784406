import React from 'react';
import { dataUIWrapper } from '../../../Common/DataUIWrapper/dataUIWrapper';
import getAgreementInfo from './Utils/fetchers/getAgreementInfo';
import getAgreement from './Utils/dispatchers/getAgreement';
import verifyAgreement from './Utils/dispatchers/verifyAgreement';
import { LoginContext } from '../../../../Context/LoginContext/LoginContext';
import Button from '@material-ui/core/Button';
import rmIcon from '../resources/rmicon-min.png';

const AgreementPage = dataUIWrapper(function(props){

    const {dispatchRequest, info, refetch} = props

    const {acceptAgreement, performLogout} = React.useContext(LoginContext);
    const [agreement, setAgreement] = React.useState('');

    React.useEffect(function(){
        if(info){
            if(info.status){
                acceptAgreement();
            }else{
                dispatchRequest('getAgreement').then(function(res){
                   setAgreement(res.data)
                })
            }
        }
    },[info, acceptAgreement, dispatchRequest]);

    const _acceptAgreement = React.useCallback(function(){
        dispatchRequest('verifyAgreement',{
            data:{
                LicenseVerified: true
            }
        }).then(function(){
            refetch()
         })
    },[dispatchRequest, refetch]);

    if(!agreement){
        return (<div><img className="static-page-logo" src={rmIcon} alt="Logo" /></div>)
    }

    return (<div className="app-flex app-flex-center overflow-auto">
                <div className="liscence-agreemnent-div">
                    {agreement || 'Checking..'}
                </div>
               <div className="app-form-button-bar">
                    <Button 
                        variant="contained" 
                        color="primary"
                        onClick = {_acceptAgreement}>
                            Accept
                    </Button>
                    <Button 
                        variant="contained" 
                        color="primary"
                        onClick = {performLogout}>
                            Cancel
                    </Button>
                </div>
            </div>)
},{
    skipWrapping: true,
    skipError: true,
    dispatchers:{
        getAgreement: getAgreement,
        verifyAgreement: verifyAgreement
    },
	featchers: [
		getAgreementInfo
	]
});

export {AgreementPage}