import { newAgeFormConfig } from "../Configs/NewAgeFormConfig";


const switchValueParser = function(value, options){

    const {onValue="True", offValue="False"} = options;

    if(value){
        return onValue;
    }else{
        return offValue;
    }

}




newAgeFormConfig.addValueParser('switchValue', switchValueParser);