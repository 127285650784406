import React from 'react';
import ModelMasterCreate from './Routes/Create/ModelMasterCreate';
import ModelMasterEdit from './Routes/Edit/ModelMasterEdit';
import ModelMasterView from './Routes/View/ModelMasterView';
import ModelMasterApprove from './Routes/Approve/ModelMasterApprove';
import ModelMasterApprovalList from './Routes/ApprovalList/ModelMasterApprovalList';
import ModelMasterList from './Routes/List/ModelMasterList';
import { Route} from 'react-router-dom';	


function ModelMasterRoutes() {
	
	
	return (<React.Fragment>
                <Route path="/rating/model-master/list">
                    <ModelMasterList />
                </Route>
                <Route path="/rating/model-master/approve-list">
                    <ModelMasterApprovalList />
                </Route>
                <Route path="/rating/model-master/create">
                    <ModelMasterCreate />
                </Route>
                <Route path="/rating/model-master/edit/:id">
                    <ModelMasterEdit />
                </Route>
                <Route path="/rating/model-master/view/:id">
                    <ModelMasterView />
                </Route>
                <Route path="/rating/model-master/approve/:id">
                    <ModelMasterApprove/>
                </Route>
            </React.Fragment>);
}

export default ModelMasterRoutes;
