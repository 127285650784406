export default {
    objectKey: 'send_for_approval',
    widget: 'FormSwitch',
    text: 'Send for Approval',
    tooltip: 'Send for Approval will change approval status to Pending for approval',
    viewValueParser: {
        parser: 'switchValue',
        onValue: 'Yes',
        offValue: 'No'
    }
}