import React from 'react';
import { dataUIWrapper } from '../../../../../../Common/DataUIWrapper/dataUIWrapper.js';
import { NewAgeFormBuilder } from '../../../../../../Common/ApplicationFormBuilder/FormBuilder/NewAgeFormBuilder.js';
import { useHistory } from 'react-router-dom';
import { NewAgeFormConfig } from '../../formJSON/newAgeConfig.js';
import { PageHeader } from '../../../../../../Common/Components/PageHeader/PageHeader.js';
import CreateDispatchers from '../../Utils/dispatchers/CreateDispatchers.js';
import allDataFetchers from '../../Utils/fetchers/allDataFetchers.js';
import { useFetcherDataMapping } from '../../../../../../Common/ApplicationHooks/FormHooks/useFetcherDataMapping.js';
import Button from '@material-ui/core/Button';

const DSSCreate = function(props){

	const [value, setValue] = React.useState({});
	const [componentExts, setComponentExts] = React.useState({
		Strategies: {
			strategiesList:[],
			risksList: []
		}
	});
	
	const history = useHistory();

	const {dispatchRequest, strategies, ermconfigs} = props;

	const _handleSave = React.useCallback(function(_value, options){
		dispatchRequest('create', {data: _value} ).then(function(_d){
			options.incrementStep();
			setValue(function(_values){
				return {
					..._values,
					raroc: _d.data.Result
				}
			});
		});
	},[dispatchRequest]);

	useFetcherDataMapping(props, allDataFetchers, setComponentExts, setValue);

	React.useEffect(function(){
		if(strategies){

			var data = strategies.map(function(e){
				return {
					statement: e.strategy
				}
			});

			setValue(function(vals){
				return {
					...vals,
					Strategies: data
				}
			});


			setComponentExts(function(ogExt){
				var _strategies = {
					...ogExt.Strategies,
					strategiesList: strategies
				}
				return {
					...ogExt,
					Strategies: _strategies
				}

			})
		}
	},[strategies]);

	React.useEffect(function(){
		if(ermconfigs){
			setComponentExts(function(ogExt){
				var _strategies = {
					...ogExt.Strategies,
					risksList: ermconfigs
				}
				return {
					...ogExt,
					Strategies: _strategies
				}

			})
		}
	},[ermconfigs]);

	const customButtonRenderer = React.useCallback(function(options){
		const {saveButtonIndex, stepperSelected} = options;
		if(saveButtonIndex<stepperSelected){
			var buttons = [];


			buttons.push(<Button 
							key={'edit'}
							variant="contained" 
							color="primary"
							onClick = {()=>{
								history.push('/irien/decision-support-system/edit/'+value.conf_name);
							}}>
								Edit
						</Button>);
			
			buttons.push(<Button 
							key={'finish'}
							variant="contained" 
							color="primary"
							onClick = {()=>{
								history.push('/irien/decision-support-system/list/');
							}}>
								Finish
						</Button>);

			buttons.push(<Button 
							key={'finish_and_upload'}
							variant="contained" 
							color="primary"
							onClick = {()=>{
								history.push('/decision-support-system/upload-files/'+value.conf_name);
							}}>
								Finish and Upload
						</Button>)
			return buttons;
		}
		return null;
	},[value])


	return (<PageHeader
					title={'Create Decision Support System'}
					previousLinks={[
						{
							href: "/irien/decision-support-system/list/",
							text: 'Decision Support System (All List)'
						}
					]}
					currentPage={'Create'}
				>
				<NewAgeFormBuilder 
					config={NewAgeFormConfig} 
					value={value}
					onChange={setValue}
					dispatchRequest={props.dispatchRequest}
					onSave={_handleSave}
					componentExts={componentExts}
					onUpdateComponentExts={setComponentExts}
					saveButtonIndex={3}
					customButtonRenderer={customButtonRenderer}
				/>
			</PageHeader>);
}



export default dataUIWrapper(DSSCreate, {
								dispatchers:{
									create: CreateDispatchers,
								},
								featchers: allDataFetchers
							});