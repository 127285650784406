import React from 'react';
import { newAgeFormConfig } from "../Configs/NewAgeFormConfig";
import TextField from '@material-ui/core/TextField';

const FormTextInput = function(props){

    const {id, value='', error, onChange, updateOnBlur, ...extProps} = props;

    const [internalValue, setInternalValue] = React.useState(value);

    const _handleChange = React.useCallback(function(event){
        var _value = event.target.value;
        
        if(updateOnBlur){
            setInternalValue(_value)
        }else{
            onChange({
                value: _value
            });
        }        

    },[onChange, updateOnBlur]);

    const _handleBlur = React.useCallback(function(){
        
        if(updateOnBlur){
            onChange({
                value: internalValue
            });
        }       

    },[internalValue, onChange, updateOnBlur]);

    React.useEffect(function(){
        setInternalValue(value);
    },[value])

    return (<TextField
                id={id}
                value={updateOnBlur?internalValue:value}
                onChange={_handleChange}
                onBlur={_handleBlur}
                {...extProps}
                variant="filled"
                error={!!error}
                helperText={error}
            ></TextField>);


}


newAgeFormConfig.pluginComponent('FormTextInput', FormTextInput);