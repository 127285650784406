var config = {
	config: [
		[
			{
				objectKey: 'model_name',
				id: 'model_name',
				widget: 'FormTextInput',
				text: 'Configuration Name',
				// data: []
			},
			{
				objectKey: 'division',
				id: 'division',
				widget: 'FormDropDown',
				text: 'Division / SBU',
				data: []
			},
			{
				objectKey: 'department',
				id: 'Department',
				widget: 'FormDropDown',
				text: 'Department',
				data: []
			},{
				objectKey: 'sub_department',
				id: 'sub_department',
				widget: 'FormDropDown',
				text: 'Sub Department / Activity',
				data: [
					{ text: 'NA', value: 'NA' },
				]
			},
			{
				objectKey: 'project',
				id: 'Main_Activity',
				widget: 'FormTextInput',
				text: 'Main Activity / Project'
			},

		],
		[
			{
				objectKey: 'location',
				id: 'Location',
				widget: 'FormDropDown',
				text: 'Location',
				data: [		]
			},
			{
				objectKey: 'objective',
				id: 'Objective',
				widget: 'FormTextInput',
				text: 'Objective'
			},
			{
				objectKey: 'risk_owner',
				id: 'Risk_Owner',
				widget: 'FormDropDown',
				text: 'Risk Owner',
				data: [ ]
			},
			{
				objectKey: 'supervisior',
				widget: 'FormDropDown',
				text: 'Supervisor',
				data: [ ]
			}
		]
	]
}


export {
	config
}