import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { Link} from "react-router-dom";
import DownloadIcon from '@mui/icons-material/Download';

const AppDownloadIcon = function(props){

    const {link='#', onClick, title="Download"} = props;

    return (<Link to={link}>
                <IconButton title={title} variant="contained" onClick={onClick}>
                    <DownloadIcon  className="ecl-download-icon"/>
                </IconButton>
            </Link>)
}

export {AppDownloadIcon}