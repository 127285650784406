import parseHistoryData from "../../../../../Common/Utils/parseHistoryData";

var parseServerObject = function(result){
    
    var obj = {
        ...result[0],
        variables: result[1],
        approval_history: parseHistoryData(result[0].history) 
    }
    
    return obj;
}


export {parseServerObject}