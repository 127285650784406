

export default [
    [
		{
			objectKey: 'risk_category',
			widget: 'FormDropDown',
			text: 'Risk Category',
			data: [],
			widgetProps: {
				dataTextField: 'risk_category',
				dataValueField: 'risk_category'
			},
			onAfterUpdate: function(options){

				if(!options.isEditExplicit){
					options.context.onUpdateValue({
						risk_subcategory: undefined,
						key_risk_indicator: undefined,
						loss_tolerance_amount: undefined,
						risk_Statement: undefined,
						risk_appetite_level: undefined
					});
				}

				options.dispatchRequest('getRiskSubCategory', {category: options.value} ).then(function(e){
                    options.context.onUpdateExts({
                        risk_subcategory:{
                            data: e.data
                        }
                    });
				});	
			},
			explicitAfterUpdate: true,
			validation: [
				{
					type: 'required',
					message: 'Enter Risk Category'
				}
			]
		},
        {
			objectKey: 'risk_subcategory',
			widget: 'FormDropDown',
			text: 'Risk Sub Category',
			data: [],
			widgetProps: {
				dataTextField: 'risk_subcategory',
				dataValueField: 'risk_subcategory'
			},
			onAfterUpdate: function(options){

				var categoryValue = options.context.componentValue.risk_category,
					subCategoryValue = options.value

				if(!options.isEditExplicit){

					var categoryData = options.context.componentExtProps.risk_category.data,
						subCategoryData = options.context.componentExtProps.risk_subcategory.data;
					
					var categoryObj = categoryData.find((e)=>e.risk_category === categoryValue) || {},
						subCategoryObj = subCategoryData.find((e)=>e.risk_subcategory === subCategoryValue) || {};


					options.context.onUpdateValue({
						loss_tolerance_amount: subCategoryObj.loss_tolerance_amount || categoryObj.loss_tolerance_amount,
						risk_Statement: categoryObj.risk_Statement,
						risk_appetite_level: categoryObj.risk_appetite_level
					});
					
				}	

				options.dispatchRequest('getRiskIndicator', {category: categoryValue, subCategory: subCategoryValue} ).then(function(e){
                    options.context.onUpdateExts({
                        key_risk_indicator:{
                            data: e.data
                        }
                    });
				});	
			},
			explicitAfterUpdate: true,
			validation: [
				{
					type: 'required',
					message: 'Enter CRisk Sub Category'
				}
			]
		},
        {
			objectKey: 'risk_Statement',
			widget: 'FormTextInput',
			text: 'Strategy Statement',
			editableModes: [],
            widgetProps: {
                highlighted: true
            }
		},
        {
			objectKey: 'risk_description',
			widget: 'FormMultiLineInput',
			text: 'Risk Description',
			validation: [
				{
					type: 'required',
					message: 'Enter Risk Description'
				}
			],
		}
    ],
    [
        {
			objectKey: 'key_risk_indicator',
			widget: 'FormDropDown',
			text: 'Risk Key Indicators',
			data: [],
			widgetProps: {
				dataTextField: 'risk_indicator',
				dataValueField: 'risk_indicator'
			},
			validation: [
				{
					type: 'required',
					message: 'Enter Risk Key Indicators'
				}
			]
		},
        {
			objectKey: 'key_risk_indicator_description',
			widget: 'FormMultiLineInput',
			text: 'Key Risk Indicators - Description',
			validation: [
				{
					type: 'required',
					message: 'Enter Key Risk Indicators - Description'
				}
			]
		},
        {
			objectKey: 'loss_tolerance_amount',
			widget: 'FormTextInput',
			text: 'Loss Tolerance Amount (CUR)',
			editableModes: [],
            widgetProps: {
                highlighted: true
            }
		},
        {
			objectKey: 'risk_appetite_level',
			widget: 'FormTextInput',
			text: 'Risk Appetite Level',
			editableModes: [],
            widgetProps: {
                highlighted: true
            }
		},
    ]
]