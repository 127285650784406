import React from 'react';
import { RMCard } from '../WrappedComponents/RMCards/RMCards';
import { RMAppGrid } from '../WrappedComponents/AppGrid/AppGrid';

const FullPageCardGrid = function(props){

    return (<div className="app-full-overflow">
                <RMCard className="app-flex">
                    <div className="app-full-container">
                        <RMAppGrid 
                            {...props}
                        /> 
                    </div>
                </RMCard> 
            </div>)    
}

export {FullPageCardGrid}