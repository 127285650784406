import React from 'react';
import { dataUIWrapper } from '../../../../../../Common/DataUIWrapper/dataUIWrapper.js';
import { NewAgeFormBuilder } from '../../../../../../Common/ApplicationFormBuilder/FormBuilder/NewAgeFormBuilder.js';
import { useHistory } from 'react-router-dom';
import { NewAgeFormConfig } from '../../formJSON/newAgeConfig.js';
import { PageHeader } from '../../../../../../Common/Components/PageHeader/PageHeader.js';
import { notificationObj } from '../../../../../../Global/NotificationHandler.js';
import MasterRecord from '../../Utils/fetchers/MasterRecord.js';
import EditDispatchers from '../../Utils/dispatchers/EditDispatchers.js';
import allDataFetchers from '../../Utils/fetchers/allDataFetchers.js';
import { useFetcherDataMapping } from '../../../../../../Common/ApplicationHooks/FormHooks/useFetcherDataMapping.js';
import Button from '@material-ui/core/Button';

const DSSEdit = function(props){

	const [value, setValue] = React.useState({});

	const history = useHistory();

	const {dispatchRequest, serverData, strategies, ermconfigs} = props;
	const [componentExts, setComponentExts] = React.useState({
		Strategies: {
			strategiesList:[],
			risksList: []
		}
	});

	const _handleSave = React.useCallback(function(_value, options){
		dispatchRequest('edit', {data: _value} ).then(function(_d){
			notificationObj.showNotification('success', 'DSS confguration updated successfully'); 
			options.incrementStep();
			setValue(function(_values){
				return {
					..._values,
					raroc: _d.data.Result
				}
			});
		});
	},[dispatchRequest]);

	useFetcherDataMapping(props, allDataFetchers, setComponentExts, setValue, {skipValue: true});

	React.useEffect(function(){
		if(strategies){

			setComponentExts(function(ogExt){
				var _strategies = {
					...ogExt.Strategies,
					strategiesList: strategies
				}
				return {
					...ogExt,
					Strategies: _strategies
				}

			})
		}
	},[strategies]);

	React.useEffect(function(){
		if(ermconfigs){
			setComponentExts(function(ogExt){
				var _strategies = {
					...ogExt.Strategies,
					risksList: ermconfigs
				}
				return {
					...ogExt,
					Strategies: _strategies
				}

			})
		}
	},[ermconfigs]);


	const customButtonRenderer = React.useCallback(function(options){
		const {saveButtonIndex, stepperSelected, setStepperSelected} = options;
		if(saveButtonIndex<stepperSelected){
			var buttons = [];


			buttons.push(<Button 
							key={'edit'}
							variant="contained" 
							color="primary"
							onClick = {()=>{
								setStepperSelected(0);
							}}>
								Edit
						</Button>);
			
			buttons.push(<Button 
							key={'finish'}
							variant="contained" 
							color="primary"
							onClick = {()=>{
								history.push('/irien/decision-support-system/list/');
							}}>
								Finish
						</Button>);

			buttons.push(<Button 
							key={'finish_and_upload'}
							variant="contained" 
							color="primary"
							onClick = {()=>{
								history.push('/decision-support-system/upload-files/'+value.conf_name);
							}}>
								Finish and Upload
						</Button>)
			return buttons;
		}
		return null;
	},[value])

	return (<PageHeader
				title={'Edit Decision Support System'}
				previousLinks={[
					{
						href: "/irien/decision-support-system/list/",
						text: 'Decision Support System (All List)'
					}
				]}
				currentPage={'Edit'}
			>
				<NewAgeFormBuilder 
					mode="edit"
					incomingData={serverData}
					config={NewAgeFormConfig} 
					value={value}
					onChange={setValue}
					dispatchRequest={props.dispatchRequest}
					onSave={_handleSave}
					componentExts={componentExts}
					onUpdateComponentExts={setComponentExts}
					saveButtonIndex={3}
					customButtonRenderer={customButtonRenderer}
				/>
			</PageHeader>);
}

export default dataUIWrapper(DSSEdit, {
								dispatchers:{
									edit: EditDispatchers
								},
								featchers: [
									MasterRecord,
									...allDataFetchers
								]
								
							});