import React from 'react';
import { dataUIWrapper } from '../../../../../../Common/DataUIWrapper/dataUIWrapper.js';
import { NewAgeFormBuilder } from '../../../../../../Common/ApplicationFormBuilder/FormBuilder/NewAgeFormBuilder.js';
import { useHistory } from 'react-router-dom';
import { NewAgeFormConfig } from '../../formJSON/newAgeConfig.js';
import { PageHeader } from '../../../../../../Common/Components/PageHeader/PageHeader.js';
import CreateDispatchers from '../../Utils/dispatchers/CreateDispatchers.js';
import { useFetcherDataMapping } from '../../../../../../Common/ApplicationHooks/FormHooks/useFetcherDataMapping.js';
import allFetcherObject from '../../Utils/fetchers/allFetcherObject.js';
import getCSV from '../../Utils/dispatchers/getCSV.js'


const ComputationCreate = function(props){

	const [value, setValue] = React.useState({});
	const [componentExts, setComponentExts] = React.useState({});

	const history = useHistory();

	const {dispatchRequest} = props;

	const _handleSave = React.useCallback(function(_value){
		dispatchRequest('create', {data: _value} ).then(function(){
			history.push('/rating/computations/list/');
		});
	},[dispatchRequest]);

	useFetcherDataMapping(props, allFetcherObject, setComponentExts, setValue);

	return (<PageHeader
					title={'Create Rating Computations'}
					previousLinks={[
						{
							href: "/rating/computations/list/",
							text: 'Rating Computations (All List)'
						}
					]}
					currentPage={'Create'}
				>
				<NewAgeFormBuilder 
					config={NewAgeFormConfig} 
					value={value}
					onChange={setValue}
					dispatchRequest={props.dispatchRequest}
					onSave={_handleSave}
					componentExts={componentExts}
					onUpdateComponentExts={setComponentExts}
				/>
			</PageHeader>);
}



export default dataUIWrapper(ComputationCreate, {
								dispatchers:{
									create: CreateDispatchers,
									getCSV: getCSV
								},
								featchers: allFetcherObject
							});