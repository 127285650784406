export default [
    [

        {
            objectKey: 'RASObjective',
            widget: 'FormGridComponent',
            widgetProps: {
                columns: [
                    { 	
                        field: 'missiongoal', 
                        headerName: 'Mission Goal', 
                        width: 600,
                        minWidth: 600,
                        flex: 1
                    }
        
                ]
            }
        }
    ]
]