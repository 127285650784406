import React from 'react';
import APIConfig from './Utils/APIConfig';

const AdminView = function(){
    
    return (<div className="right-section-contents admin-contents">
				<iframe src={APIConfig.adminURL} title="admin-view" className="admin-iframe"/>
			</div>);
}

export default AdminView;