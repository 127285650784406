import React from 'react';
import { newAgeFormConfig } from "../Configs/NewAgeFormConfig";

const FormTextDisplay = function(props){

    const {id, value='', onChange, viewValueParser={}, highlighted, ...extProps} = props;
   

    const displayValue = React.useMemo(function(){
        const {parser} = viewValueParser;
        if(parser){
            const parsingFn = newAgeFormConfig.getValueParser(parser);
            return parsingFn(value, viewValueParser, extProps);
        }
        return value
    },[value, viewValueParser, extProps]);

    var _className="app-form-text-display"

    if(typeof displayValue === 'undefined' || value === null || !String(displayValue)){
        _className = _className + ' empty-form-display'
    }

    if(highlighted){
        _className = _className + ' app-display-text-highlighted'
    }

    return (<div 
                id={id}
                className={_className}
            >
                {displayValue}
            </div>);


}


newAgeFormConfig.pluginComponent('FormTextDisplay', FormTextDisplay);