import React from 'react';
import { AppCloseIcon } from '../../../Icons/AppCloseIcon';

const MultiGridIncrementalCell = function(props){

    const {viewOnlyMode, value='', onClose} = props;

    const closeIcon = React.useMemo(function(){
        if(viewOnlyMode){
            return null;
        }

        return <AppCloseIcon onClick={onClose}/>

    },[onClose, viewOnlyMode]);

    return (
        <div className="row-icon-container hover-icon">
            <div className="row-label" title={value}>
                {value}
            </div>
            {closeIcon}
        </div>
    )

}

export {MultiGridIncrementalCell}