var mainUrl=window.riskMac.mainURL;

export default {
	getReport: mainUrl+'/getDssConfs/',
	getCostControl: mainUrl+'/getDSSRiskCostVsReturn/',
	getModelData: mainUrl+'/getDssConfsResult/allaction/',

}


