import React from 'react';
import Switch from '@material-ui/core/Switch';
import { newAgeFormConfig } from '../Configs/NewAgeFormConfig';

const FormSwitchGroup = function(props){
    
    const {id, value={}, data=[], valueField, textField,  onChange, ...extProps} = props;

    const _handleChange = React.useCallback(function(_valueField){
        
        onChange({
            value: {
                ...value,
                [_valueField]: !value[_valueField]
            }
        });

    },[onChange, value]);

    const component = React.useMemo(function(){
        return  data.map(function(_data){
                    return (<div key={_data[valueField]} className="app-flex-col-nogrow app-form-switch-group-item">
                                <div class="form-label">{_data[textField]}</div>
                                <div class="form-input">
                                    <Switch
                                        id={(id)+'_'+_data[valueField]}
                                        checked = {value[_data[valueField]]}
                                        onChange = {()=>_handleChange(_data[valueField])}
                                        {...extProps}
                                    ></Switch>
                                </div>
                            </div>)
                });
    },[valueField, data, textField, value, _handleChange, id])
   

    return (component);
}



newAgeFormConfig.pluginComponent('FormSwitchGroup', FormSwitchGroup);