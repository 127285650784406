import React from 'react';
import { dataUIWrapper } from '../../DataUIWrapper/dataUIWrapper';
import { AppDeleteIcon } from '../../Icons/AppDeleteIcon';
import { AppDownloadIcon } from '../../Icons/AppDownloadIcon';
import { RMAppGrid } from '../WrappedComponents/AppGrid/AppGrid';

function download(filename, text, url) {
    const href = URL.createObjectURL(text);

    // create "a" HTLM element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', filename); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
}

const FileListGrid = function(props){

    const {
            documents=[], 
            columns=[],
            mainHeaderTitle="Document Name",
            mainNameField="itemname",
            onDownload, 
            moduleName,
            modelName,
            onDelete, 
            deletable,
            refetch,
            downloadURL= window.riskMac.APIConfig.downloadFile
        } = props;

    const _handleDownload = React.useCallback(function(row){

        var name = row[mainNameField];

        props.dispatchRequest('download', {
            responseType: 'blob',
			data: {
				"config_name": modelName,
				"module": moduleName,
				"itemname": name
			}
		} ).then(function(res){
			download(name, res.data, downloadURL);
            if(onDownload){
                onDownload(name, res.data, downloadURL)
            }
		});
    },[modelName, moduleName, mainNameField, downloadURL, onDownload]);
    

    const _handleDelete = React.useCallback(function(row){

        var name = row[mainNameField];

        props.dispatchRequest('delete', {
			data: {
				"config_name": modelName,
				"module": moduleName,
				"itemname": name
			}
		} ).then(function(res){
            if(onDelete){
                onDelete();
            }
			refetch();
		});
    },[modelName, moduleName, mainNameField, downloadURL, onDelete]);

    const _columns = React.useMemo(function(){
        return [{
                    field: mainNameField,
                    headerName: mainHeaderTitle,
                    width: 300,
                    renderCell : function(params){
                        return (
                            <div className="row-icon-container">
                                <div className="row-label">
                                    {params.row[mainNameField]}
                                </div>
                                {deletable && (<AppDeleteIcon onClick={()=>{_handleDelete(params.row)}} />)}
                                <AppDownloadIcon onClick={()=>{_handleDownload(params.row)}}/>
                            </div>
                        )
                    }
                },
                ...columns
            ];
    },[_handleDelete, columns, _handleDownload, deletable, mainHeaderTitle, mainNameField]);


    return (<RMAppGrid rows={documents} columns={_columns}/>);

}

export default dataUIWrapper(FileListGrid, {skipWrapping: true});