var configS5 = {
	config: [
		[
			{
				objectKey: 'net_risk',
				widget: 'FormTextInput',
				text: 'Net Risk',
				widgetProps:{
					disabled: true,
					readonly: true,
					className: 'ecl-spotlighted'
				}

			},		
			{
				objectKey: 'mitigation_priority',
				widget: 'FormTextInput',
				text: 'Mitigation Priority',
				widgetProps:{
					disabled: true,
					readonly: true,
					className: 'ecl-spotlighted'
				}

			}
		
			/*{
				objectKey: 'principal_impact',
				id: 'principal_impact',
				widget: 'FormDropDown',
				text: 'Principal Impact',				
				data: [ ]
			},
			{
				objectKey: 'likelihood_post_control',
				id: 'likelihood_post_control',
				widget: 'FormDropDown',
				text: 'Likelihood - Post Control',				
				data: [ ]
			},

			{
				objectKey: 'severity_post_control',
				id: 'severity_post_control',
				widget: 'FormDropDown',
				text: 'Severity - Post Control',				
				data: [ ]
			}*/

		],[
			{
                objectKey: 'mitigation_comments',
                widget: 'FormMultilineInput',
                text: 'Comments'
            },
			// {
			// 	objectKey: 'mitigation_cost',
			// 	id: 'mitigation_cost',
			// 	widget: 'FormTextInput',
			// 	text: 'Mitigation Cost ',
			// 	// data: []
			// },
			/*{
				objectKey: 'residual_risk',
				widget: 'FormTextInput',
				text: 'Residual Risk Rating',
				widgetProps:{
					disabled: true,
					readonly: true,
					className: 'ecl-spotlighted'
				}

			},
			{
				objectKey: 'residual_risk_factor',
				widget: 'FormTextInput',
				text: 'Residual Risk Factor',
				widgetProps:{
					disabled: true,
					readonly: true,
					className: 'ecl-spotlighted'
				}

			},
			{
				objectKey: 'residual_risk_action',
				widget: 'FormTextInput',
				text: 'Residual Risk Action',
				widgetProps:{
					disabled: true,
					readonly: true,
					className: 'ecl-spotlighted'
				}

			},
			{
				objectKey: 'adeqacy_existing_controls',
				id: 'adeqacy_existing_controls',
				widget: 'FormDropDown',
				text: 'Adeqacy of Existing Controls / Mitigations',				
				data: [ ]
			}*/

		]

	]
}


export {
	configS5
}