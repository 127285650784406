var mainUrl=window.riskMac.mainURL;


export default {
		approvedModelList: mainUrl+'/getMasterRecords/approved/',
		getModel: mainUrl+'/getMasterRecord/',
		getMasterList: mainUrl+'/getDefinitionRecords/all/',
		getMasterRecord: mainUrl+'/getDefinitionRecord/',
		create: mainUrl+'/createDefinitionEntry/',
		delete: mainUrl+'/deleteDefinitionRecord/',
		edit: mainUrl+'/createDefinitionEntry/',
		approve: mainUrl+'/updateDefinitionApproval/',
		approvalList: mainUrl+'/getDefinitionRecords/allaction/',
}


