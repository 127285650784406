import React from 'react';
import { Link} from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import { AppViewIcon } from '../../Icons/AppViewIcon';


const NameCell = function(props){

    const {status, name, onDelete, viewUrl, editUrl,editList, deleteList, customIconRenderer} = props;

    var customIcons = null;

    const _deletButton = React.useMemo(function(){
            if(deleteList.indexOf(status) > -1){
                return (<Link to="#">
                            <IconButton title="Delete" variant="contained" onClick={onDelete}>
                                <DeleteIcon className="ecl-delete-icon"/>
                            </IconButton>
                        </Link>)
            }
            return null;
    },[deleteList, status, onDelete]);
    
    const _editButton = React.useMemo(function(){
        if(editList.indexOf(status) > -1){
            return (<Link title="Edit" to={editUrl+name}>
                        <IconButton variant="contained">
                            <CreateIcon className="ecl-edit-icon"/>
                        </IconButton>
                    </Link>)
        }
        return null;
    },[deleteList, status, editUrl, name]);

    if(customIconRenderer){
        customIcons = customIconRenderer(props);
    }
     
    return (
        <div className="row-icon-container">
            <div className="row-label">
                {name}
            </div>
            {_deletButton}
            {_editButton}
            {customIcons}
            {viewUrl && <AppViewIcon link={viewUrl+name}/>}
        </div>
    )
}


export {NameCell}