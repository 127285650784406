export default [
	[
		{
			objectKey: 'model_name',
			widget: 'FormTextInput',
			text: 'Configuration Name',
			explicitUpdateModes: ['approve','view'],
			onExplicitEditUpdate: function(options){
				var value = options.value;
				options.context.onUpdateExts({
					form_documents:{
						modelName: value
					}
				});
			},
			editableModes: ['create'],
			validation: [
				{
					type: 'required',
					message: 'Enter Configuration Name'
				}
			]
		},
		{
			objectKey: 'division',
			widget: 'FormDropDown',
			text: 'Division / SBU',
			data: [],
			widgetProps: {
				dataTextField: 'division',
				dataValueField: 'division'
			},
			onAfterUpdate: function(options){
                
				options.dispatchRequest('getDepartment', {division: options.value} ).then(function(e){
                    options.context.onUpdateExts({
                        department:{
                            data: e.data
                        }
                    });
				});	
			},
			explicitAfterUpdate: true,
			validation: [
				{
					type: 'required',
					message: 'Enter Division / SBU'
				}
			]
            
		},
		{
			objectKey: 'department',
			widget: 'FormDropDown',
			text: 'Department',
			data: [{ department: 'NA' }],
			widgetProps: {
				dataTextField: 'department',
				dataValueField: 'department'
			},
			validation: [
				{
					type: 'required',
					message: 'Enter Department'
				}
			]
		},
		{
			objectKey: 'sub_department',
			widget: 'FormDropDown',
			text: 'Sub Department / Activity',
			data: [{ text: 'NA', value: 'NA' }],
			widgetProps: {
				dataTextField: 'text',
				dataValueField: 'value'
			},
			validation: [
				{
					type: 'required',
					message: 'Enter Sub Department / Activity'
				}
			]
		},
		{
			objectKey: 'project',
			widget: 'FormTextInput',
			text: 'Main Activity / Project',
			validation: [
				{
					type: 'required',
					message: 'Enter Main Activity / Project'
				}
			]
		},
	],
	[
		{
			objectKey: 'location',
			widget: 'FormDropDown',
			text: 'Location',
			data: [],
			widgetProps: {
				dataTextField: 'location',
				dataValueField: 'location'
			},
			validation: [
				{
					type: 'required',
					message: 'Enter Location'
				}
			]
		},
		{
			objectKey: 'objective',
			widget: 'FormTextInput',
			text: 'Objective',
			validation: [
				{
					type: 'required',
					message: 'Enter Objective'
				}
			]
		},{
			objectKey: 'risk_owner',
			widget: 'FormDropDown',
			text: 'Risk Owner',
			data: [],
			widgetProps: {
				dataTextField: 'risk_owner',
				dataValueField: 'risk_owner'
			},
			validation: [
				{
					type: 'required',
					message: 'Enter Risk Owner'
				}
			]
		},{
			objectKey: 'supervisior',
			widget: 'FormDropDown',
			text: 'Supervisor',
			data: [],
			widgetProps: {
				dataTextField: 'supervisor',
				dataValueField: 'supervisor'
			},
			validation: [
				{
					type: 'required',
					message: 'Enter Supervisor'
				}
			]
		},
	]
]