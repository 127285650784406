import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { newAgeFormConfig } from '../Configs/NewAgeFormConfig';

const FormDropDown = function(props){

    const {
            id, 
            value='', 
            error, 
            onChange, 
            data=[],
            dataTextField='text',
            dataValueField='value',
            ...extProps
        } = props;

    const menuItems = React.useMemo(function(){
        return data.map(function(_d){
			return ( <MenuItem 
                        key={_d[dataValueField]} 
                        value={_d[dataValueField]}>
                            {_d[dataTextField]}
                        </MenuItem>)
		});
    },[data,dataValueField, dataTextField]);

    const _handleChange = React.useCallback(function(event){
        var _value = event.target.value;
        
        onChange({
            value: _value
        });

    },[onChange]);


    return (<Select
                id={id}
                value = {value}
                onChange = {_handleChange}
                {...extProps}
                variant="filled"
                error={!!error}
                helperText={error}
            >
			    {menuItems}
		    </Select>);
}


newAgeFormConfig.pluginComponent('FormDropDown', FormDropDown);