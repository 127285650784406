import React from 'react';
import {NavigationBar} from './Widgets/Global/NavigationBar.js';
import ApplicationHeader from './Widgets/Global/ApplicationHeader.js';
import {MainRouter} from './Widgets/Global/MainRouter.js';
import {withNotificationHandler} from './Widgets/Global/NotificationHandler.js';
import {  BrowserRouter as Router } from "react-router-dom";
import './App.css';
import {printContainer} from './Widgets/Global/PrintHandler.js';
import { AppNavProvider } from './Widgets/Global/ApplicationNavProvider/AppNavProvider.js';
import LoginWrapper from './Context/LoginContext/LoginContext.js';
import { RMFooter } from './Widgets/Common/ApplicationComponents/Footer/RMFooter.js';


function App() {
	
	React.useEffect(function(){
		window.addEventListener('beforeprint', (event) => {
			event.stopImmediatePropagation();
			event.stopPropagation();
			event.preventDefault();
			printContainer();
		  });
	},[]);
 
	return (
		<div className="full-container">	
			<Router>
				<AppNavProvider>
					<LoginWrapper>
						<div className="app-flex-col">
							<NavigationBar/>
							<div className="right-section-bar">
								<div className="app-flex">
										<ApplicationHeader/>
										<MainRouter/>
									<RMFooter/>
								</div>
							</div>
						</div>
					</LoginWrapper>
				</AppNavProvider>
			</Router>
		</div>
	);
}

export default withNotificationHandler(App);
                       