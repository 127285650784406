import React from 'react';
import IRIENDashboard from './Routes/Dashboard/IRIENDashboard';
import { Route} from 'react-router-dom';	


function DashboardRoutes() {
	
	
	return (<React.Fragment>
                <Route path="/irien/dashboard">
                    <IRIENDashboard />
                </Route>
            </React.Fragment>);
}

export default DashboardRoutes;
