import { dateValueParser } from "../../../../../../Common/ApplicationFormBuilder/Parsers/dateValueParser";
import { downloadText } from "../../../../../../Common/Utils/downloadText";

export default function(data, fileName){

	var clientFieldsHeader = data.ratingAggregate[0]?Object.keys(JSON.parse(data.ratingAggregate[0].clientFields.replace(/'/g,'"'))):[];
	var configurationName = data.ratingAggregate[0]?data.ratingAggregate[0].configuration_name:'';

	var _csvData = 'Reporting Time, '+ dateValueParser(data.reporttime,{}) +'\n'+
	'Configuration Name, '+ configurationName+'\n\n'+

	'Rating Details\n'+

	clientFieldsHeader.join(',')+',Score,Grade,PD%,Application Status\n'+

	data.ratingAggregate.map(function(_item){
		var clientFields = JSON.parse((_item.clientFields.replace(/'/g,'"')));
		return clientFieldsHeader.map(function(_key){
			return clientFields[_key];
		}).join(',')+','+_item.score+','+_item.grade+','+_item.PD_inPercent+','+_item.Application_Status
	}).join('\n');
		
	downloadText(fileName+'.csv', _csvData );
}