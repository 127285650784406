import React from 'react';
import RiskRegisterCreate from './Routes/Create/RiskRegisterCreate';
import RiskRegisterEdit from './Routes/Edit/RiskRegisterEdit';
import RiskRegisterView from './Routes/View/RiskRegisterView';
import RiskRegisterApprove from './Routes/Approve/RiskRegisterApprove';
import RiskRegisterApprovalList from './Routes/ApprovalList/RiskRegisterApprovalList';
import RiskRegisterList from './Routes/List/RiskRegisterList';
import { Route} from 'react-router-dom';	


function RiskRegisterRoutes() {
	
	
	return (<React.Fragment>
                <Route path="/irien/risk-register/list">
                    <RiskRegisterList />
                </Route>
                <Route path="/irien/risk-register/approve-list">
                    <RiskRegisterApprovalList />
                </Route>
                <Route path="/irien/risk-register/create">
                    <RiskRegisterCreate />
                </Route>
                <Route path="/irien/risk-register/edit/:id">
                    <RiskRegisterEdit />
                </Route>
                <Route path="/irien/risk-register/view/:id">
                    <RiskRegisterView />
                </Route>
                <Route path="/irien/risk-register/approve/:id">
                    <RiskRegisterApprove/>
                </Route>
            </React.Fragment>);
}

export default RiskRegisterRoutes;
