import React from 'react';
import { dataUIWrapper } from '../../../../../../Common/DataUIWrapper/dataUIWrapper.js';
import { NewAgeFormBuilder } from '../../../../../../Common/ApplicationFormBuilder/FormBuilder/NewAgeFormBuilder.js';
import { NewAgeFormConfig } from '../../formJSON/newAgeConfig.js';
import { PageHeader } from '../../../../../../Common/Components/PageHeader/PageHeader.js';
import MasterRecord from '../../Utils/fetchers/MasterRecord.js';

const ModelMasterView = function(props){

	const [value, setValue] = React.useState({});
	const [componentExts, setComponentExts] = React.useState({});

	const { serverData} = props;

	return (<PageHeader
				title={'Master Model View'}
				previousLinks={[
					{
						href: "/rating/model-master/list/",
						text: 'Master Model (All List)'
					}
				]}
				currentPage={'View'}
			>
				<NewAgeFormBuilder 
					mode="view"
					incomingData={serverData}
					config={NewAgeFormConfig} 
					value={value}
					onChange={setValue}
					componentExts={componentExts}
					onUpdateComponentExts={setComponentExts}
				/>
			</PageHeader>);
}

export default dataUIWrapper(ModelMasterView, {
								dispatchers:{
								},
								featchers: [
									MasterRecord
								]	
							});