import parseHistoryData from "../../../../../Common/Utils/parseHistoryData";

var parseServerObject = function(result){
    return {
        ...result,
        approval_history: parseHistoryData(result.history) 
    };
}


export {parseServerObject}