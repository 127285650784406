import React from 'react';
import { Route} from 'react-router-dom';
import APIConfig from '../../APIConfig.js';
import FilesUploadComponent from '../Global/UploadComponent/FilesUploadComponent.js';

function MainRouter() {

	return (
		<React.Fragment>
			
			<Route exact path="/decision-support-system/upload-files/:id">
			    <FilesUploadComponent
					homeUrl={'/irien/decision-support-system/list'}
					homeText={'Decision Support System (All List)'}
					fetchingUrl={APIConfig.getFiles}
					saveUrl={APIConfig.uploadFiles}
					module={'dss'}
					deleteUrl={APIConfig.deleteFile}
					downloadUrl = {APIConfig.downloadFile}
				/>
			</Route>

		</React.Fragment>
	);
}

export default MainRouter;
