import ApprovalHistoryObject from "../../../../../Common/FormConfig/ApprovalHistoryObject";

var noOfGradeData = [];

for(var i=2;i<=20;++i){
	noOfGradeData.push({
		text: i,
		value: i	
	})
}


const ModelDefinitionConfig = [
	[
		{
			objectKey: 'model_definition_name',
			widget: 'FormTextInput',
			text: 'Definition Name',
			editableModes: ['create'],
			validation: [
				{
					type: 'required',
					message: 'Enter model definition'
				}
			]
		},
		{
			objectKey: 'model_name',
			widget: 'FormDropDown',
			text: 'Model Name',
			editableModes: ['create'],
			data: [],
			widgetProps: {
				dataTextField: 'model_name',
				dataValueField: 'model_name'
			},
			onAfterUpdate: function(options){
				options.dispatchRequest('getModelMasterData', {model_name: options.value} ).then(function(e){
					var variableData = e.data[1].filter(function(_var){
						return _var.variable_type && _var.variable_name;
					});
					options.context.onUpdateValue({
						variables: variableData   
					 })
				});	
			},
			onExplicitEditUpdate: function(options){

				var variableMap = {}
				options.context.componentValue.variables.forEach(function(e){
					variableMap[e.variable_id] = e
				})

				options.dispatchRequest('getModelMasterData', {model_name: options.value} ).then(function(e){

					var variableData = e.data[1].filter(function(_var){
						return _var.variable_type && _var.variable_name;
					});
					variableData.forEach(function(_var){
						var ogVariable = variableMap[_var.variable_id];
						if(ogVariable){
							_var.variable_selected_yn = ogVariable.variable_selected_yn;
							_var.variable_selected_value = ogVariable.variable_selected_value;
						}
					});

					options.context.onUpdateValue({
						variables:   variableData
					 })
				});	
			},
			validation: [
				{
					type: 'required',
					message: 'Enter model name'
				}
			]
		},
		
		{
			objectKey: 'model_type',
			widget: 'FormTextInput',
			text: 'Model Type',
			validation: [
				{
					type: 'required',
					message: 'Enter model type'
				}
			]
		},
		{
			objectKey: 'intercept_y_value',
			widget: 'FormTextInput',
			text: 'Intercept Y Value',
			tooltip: 'Any numeric value, positive or negative',
			widgetProps: {
				type: "number",
			},
			validation: [
				{
					type: 'required',
					message: 'Enter intercept Y value'
				}
			]
		},
		
		
	],
	[
		
		{
			objectKey: 'no_of_grades',
			widget: 'FormDropDown',
			text: 'No of Grades',
			data: noOfGradeData,
			onAfterUpdate: function(options){

				var _grades = window.structuredClone(options.context.componentValue.Grades || []);
				var rejectionValue = options.context.componentValue.rejection_threshhold_grade;

				if(_grades.length > options.value ){
					_grades = _grades.slice(0, options.value);
				}else if(_grades.length < options.value){
					for(var i = _grades.length;i<options.value;++i){
						_grades.push({});
					}
				}

				if(rejectionValue && rejectionValue>options.value){
					rejectionValue = options.value;
				}

				var length = _grades.length; 
				_grades.forEach(function(_g, idx){
					_g.grade_number = length-idx;
				})

				options.context.onUpdateValue({
					Grades: _grades,
					rejection_threshhold_grade: rejectionValue
				});

				var rejectionData = [];

				for(var i=1;i<=options.value;++i){
					rejectionData.push({
						text: i,
						value: i
					})
				}

				options.context.onUpdateExts({
					rejection_threshhold_grade:{
						data: rejectionData
					}
				});
					
			},
			validation: [
				{
					type: 'required',
					message: 'Enter Number of Grades'
				}
			]
		},
		{
			objectKey: 'rejection_threshhold_grade',
			widget: 'FormDropDown',
			text: 'Rejection Threshold of Grades',
			data: [],
			isDisabled: function(context){
				return !context.componentValue.no_of_grades;
			},
			validation: [
				{
					type: 'required',
					message: 'Enter Number of Grades'
				}
			]
		},
		{
			objectKey: 'send_for_approval',
			widget: 'FormSwitch',
			text: 'Send for Approval',
			tooltip: 'Send for Approval will change approval status to Pending for approval',
			viewValueParser: {
				parser: 'switchValue',
				onValue: 'Yes',
				offValue: 'No'
			}
		},
		
	]
]

const ClientsFieldConfig = [
	[
		{
			objectKey: 'client_fields',
			widget: 'FormSwitchGroup',
			widgetProps: {
				valueField: 'client_field_name',
				textField: 'client_field_name'
			},
			viewValueParser: {
				parser: 'switchGroupValues',
				onValue: 'Selected',
				offValue: 'Not Selected'
			}
		}
	],
	[]
]

const variableGridConfig = {
	objectKey: 'variables',
	widget: 'FormGridComponent',
	widgetProps: {
		columns: [
			{ 	
				field: 'variable_id', 
				headerName: 'Variable ID', 
				width: 180
			},
			{ 
				field: 'variable_name', 
				headerName: 'Variable Name', 
				width: 300
			},
			{ 
				field: 'variable_type', 
				headerName: 'Variable Type', 
				width: 150
			},
			{ 
				field: 'variable_selected_yn', 
				headerName: 'Select Variable',  
				width: 160,
				cellClassName: 'ecl-checkbox-col',
				viewValueParser: {
					parser: 'checkboxValue',
					trueValue: 'Yes',
					falseValue: 'No',
					componentTrueValue: 'TRUE',
					componentFalseValue: 'FALSE',
				},
				editable: {
					isEditable: function(){
						return true;
					},
					widget: 'FormCheckbox',
					widgetProps: {
						trueValue: 'TRUE',
						falseValue: 'FALSE'
					}
				}
			},
			{ 
				field: 'variable_selected_value', 
				headerName: 'Variable Value (Numeric - Positive/negative value, Categorical - MIN = 0, MAX = 20)',  
				width: 300, 
				type: 'number',
				editable: {
					isEditable: function(param){
						return param.row.variable_selected_yn==='TRUE';
					},
					widget: 'FormTextInput',
					widgetProps: {
						type: "number",
					},
					valueParser: function(value, params){
						return params.row.variable_selected_yn === "TRUE"?value:'';
					}
				},
			},

		]
	},
	validation: [
		{
			type:'custom',
			fn: function(value){
				value = value || [];
				var message, anySelected;
				value.forEach(function(_row){
					if(message){
						return;
					}

					if(_row.variable_selected_yn === "TRUE"){
						anySelected=true;
					}
							
					if(_row.variable_selected_yn === "TRUE" && typeof _row.variable_selected_value==='undefined'){					
						message = 'Enter variable value for selected variables';
					}else if(_row.variable_type === "categorical" && ( _row.variable_selected_value > 20 || _row.variable_selected_value < 0 )){
						message = 'Categorical value should be between 0 to 20';
					}
				});

				if(!anySelected){
					message = 'At least one variable should be selected.';
				}

				return message;
			}
		}
	],
	saveParsing: function(value){
		return (value || []).map(function(_d){
			return {
					id: _d.id,
					"variable_id": _d.variable_id,
					"variable_name": _d.variable_name,
					"variable_type":  _d.variable_type,
					"variable_selected_value": _d.variable_selected_yn === "TRUE"?Number(_d.variable_selected_value):'',
					"variable_selected_yn":_d.variable_selected_yn || 'FALSE'
				}
		})
	}
}

const gradesGridConfig = {
	objectKey: 'Grades',
	widget: 'FormGridComponent',
	widgetProps: {
		columns: [
			{ 	
				field: 'grade_number', 
				headerName: 'Grade Number', 
				width: 300
			},
			{ 
				field: 'grade_minRange', 
				headerName: 'Score Min Range', 
				width: 180,
				editable: {
					widget: 'FormTextInput',
					widgetProps: {
						type: "number",
					}
				}
			},
			{ 
				field: 'grade_maxRange', 
				headerName: 'Score Max Range', 
				width: 180,
				editable: {
					widget: 'FormTextInput',
					widgetProps: {
						type: "number",
					}
				}
			},
			{ 
				field: 'grade_pdpecent', 
				headerName: 'PD Percentage',  
				width: 200,
				editable: {
					widget: 'FormTextInput',
					widgetProps: {
						type: "number",
					}
				}
			},
		]
	},
	onAfterUpdate: function(options){
		var value = window.structuredClone(options.value),
			ogValue = options.ogValue,
			updatedKey, updatedIndex;
		
		for(var i=0;i<value.length;++i){
			if(ogValue[i].grade_minRange !== value[i].grade_minRange){
				updatedIndex=i;
				updatedKey='minRange';
				break;
			}else if(ogValue[i].grade_maxRange !== value[i].grade_maxRange){
				updatedIndex=i;
				updatedKey='maxRange';
				break;
			}
		}

		if(updatedKey==='minRange'){
			if(value[updatedIndex-1]){
				value[updatedIndex-1].grade_maxRange = value[updatedIndex].grade_minRange;
			}
		}else if(updatedKey==='maxRange'){
			if(value[updatedIndex+1]){
				value[updatedIndex+1].grade_minRange = value[updatedIndex].grade_maxRange;
			}
		}

		if(updatedKey){
			setTimeout(function(){
				options.context.onUpdateValue({
					Grades: value
				});
			},10);
		}

	},
	validation: [
		{
			type: 'gridRequiredFields',
			gridFields: [
				{
					field: 'grade_minRange',
					message: 'Enter Score Min Range'
				},{
					field: 'grade_maxRange',
					message: 'Enter Score Max Range'
				},{
					field: 'grade_pdpecent',
					message: 'Enter Score Percentage'
				},
			]
		},
        {
            type: 'custom',
            fn: function(value){

                var message, totalPercentage=0;

                (value || []).forEach(function(_v){
					if(!message && Number(_v.grade_minRange)<0){
						message = 'Score Min Range should be greater than or Equal to 0';
					}

					if(!message && Number(_v.grade_minRange) >= Number(_v.grade_maxRange)){
						message = 'Score Max Range should be Greater than Score Min range';
					}

					totalPercentage = totalPercentage + Number(_v.grade_pdpecent);

                });

				if(totalPercentage!==100){
					message = 'Total PD percentage should be equal to 100%';
				}

                return message;

            }
        }
	]
}


const NewAgeFormConfig = {
	layoutItems: [
		{
			stepperName: 'Organization Details',
			itemsLayout : [
				{
					items : [
						{
							header: 'Model Definition',
							componentConfig: ModelDefinitionConfig
						}
					
					]
				},
				{
					items : [
						{
							header: 'Client Fields',
							componentConfig: ClientsFieldConfig
						}
					]
				},
				{
					items : [
						{
							header: 'Model Definition Variables',
							componentConfig: [
								[
									variableGridConfig
								]
							]
						},
						{
							header: 'Grades',
							componentConfig: [
								[
									gradesGridConfig
								]
							]
						}
					]
				},
				...ApprovalHistoryObject
				
			],
            stepValidation: function(value){

                if(value.rejection_threshhold_grade > value.no_of_grades){
                    return 'Rejecttion Threshold should be less than Number of grades';
                }

				var message = 'Atleast one Client Field should be selected';

				Object.keys(value.client_fields || {}).forEach(function(key){
					if(value.client_fields[key]){
						message='';
					}
				});

                if(message){
                    return message;
                }
            }

		},
        
	],
	finalSaveParsing: function(_value){

		_value.ClientFields = Object.keys(_value.client_fields).map(function(key){
			return {
				"client_variable_name": key,
				"variable_selected": !!_value.client_fields[key]
			}
		})
        _value.intercept_y_value = Number(_value.intercept_y_value);
		return _value;
	},
	defaultValue: {
		"send_for_approval": false
	}

}


export {NewAgeFormConfig}