import APIConfig from "../../../APIConfig";

export default {
    url: APIConfig.getStrategies,
    method: 'get',
    postProcessor: function(dataObj, result){
        if(result){
            dataObj.strategies = result;
        }
    }
}