import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { Link} from "react-router-dom";
import SettingsIcon from '@mui/icons-material/Settings';

const AppSettingsIcon = function(props){

    const {link='#', onClick, title="Settings"} = props;

    return (<Link to={link}>
            <IconButton title={title} variant="contained" onClick={onClick}>
                <SettingsIcon className="ecl-delete-icon"/>
            </IconButton>
        </Link>)
}

export {AppSettingsIcon}