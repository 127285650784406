export default [
    [
        {
			objectKey: 'conf_name',
			widget: 'FormTextInput',
			text: 'Configuration Name',
            editableModes: ['create'],
			validation: [
				{
					type: 'required',
					message: 'Enter Configuration Name'
				}
			],
			explicitUpdateModes: ['approve','view'],
			onExplicitEditUpdate: function(options){
				var value = options.value;
				options.context.onUpdateExts({
					form_documents:{
						modelName: value
					}
				});
			}
		},
        {
            objectKey: 'MissionGoalWeight',
            widget: 'FormGridComponent',
            widgetProps: {
                columns: [
                    { 	
                        field: 'missiongoal', 
                        headerName: 'Mission Goal', 
                        width: 600,
                        minWidth: 600,
                        flex: 1
                    },
                    { 
                        field: 'Weight', 
                        headerName: 'Goal Weight', 
                        width: 300,
                        editable: {
                            isEditable: function(){
                                return true;
                            },
                            widget: 'FormTextInput',
                            widgetProps: {
                                type: 'number'
                            }
                        }
                    }
        
                ]
            },
            validation: [
                {
                    type:'custom',
                    fn: function(value){
                        value = value || [];
                        var total=0;
                        value.forEach(function(_row){
                            total = total + Number(_row.Weight);
                        });
                        
                        if(total!==100){
                            return 'Total Mission Goal Weights should be equal to 100.'
                        }
                    }
                }
            ],
            saveParsing: function(value){
                var _value = value;
                _value.forEach(function(_v){
                    _v.Weight = Number(_v.Weight);
                });

                return _value;
            }
        }
    ]
]