import appNavConfig from "../../Global/ApplicationNavProvider/Config/AppNavConfig";

var navData = [

	{
		header: 'Integrated Enterprise Risk',
		items: [
			{
				id: 'integrated-enterprise-risk-Dashboard',
				title: 'Dashboard',
				link: '/irien/dashboard',
			},
			{
				id: 'ras-statement-dahboard',
				title: 'RAS Statement',
				link: '/irien/ras-statement',
			},
			{
				id: 'integrated-enterprise-risk-risk-register',
				title: 'ERM',
				items: [
					{
						id: 'integrated-enterprise-risk-risk-details',
						title: 'Risk Configuration',
						link: '/irien/risk-register/list',
						plusIconLink: '/irien/risk-register/create/'
					},
					{
						id: 'integrated-enterprise-risk-risk-approve',
						title: 'Approval',
						link: '/irien/risk-register/approve-list',
					},
					{
						id: 'integrated-enterprise-risk-risk-governance',
						title: 'Governance',
						items: [
							{
								id: 'integrated-risk-governance-create',
								title: 'Create',
								link:'/irien/governance/create'

							},
							{
								id: 'integrated-risk-governance-create',
								title: 'Approve',
								link:'/irien/governance/approve-list'

							}
						]						
					}					
				]
				
			},
			{
				id: 'decision-support-system',
				title: 'Decision Support System',
				items: [
					{
						id: 'decision-support-system-risk-details',
						title: 'DSS Configuartions',
						link: '/irien/decision-support-system/list',
						plusIconLink: '/irien/decision-support-system/create'
					},
					{
						id: 'decision-support-system-risk-approve',
						title: 'Approval',
						link: '/irien/decision-support-system/approve-list',
					}
				]
				
			},
			{
				id: 'integrated-enterprise-risk-report',
				title: 'Reports',
				link: '',
				items: [
					{
						id: 'risk-register-reporting',
						title: 'ERM',
						link: '/integated-risk/report'
					},
					{
						id: 'decision-support-system-reporting',
						title: 'Decision Support System',
						link: '/irien/dss-reports'
					}
				]
			},
			{
				id: 'integrated-enterprise-risk-audittrail',
				title: 'Audit Trail',
				link: '/irien/audit',
			}
		]

	}
];

var pagesRegex = [/\/integated-risk\/*/,/\/irien\/*/, /\/decision-support-system\/*/] ;

var homePage = '/irien/home';

appNavConfig.plugin('iriene',{
    nav: navData,
    regex: pagesRegex,
    homePage: homePage
});
