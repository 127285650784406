import React from 'react';
import { dataUIWrapper } from '../../../../../../Common/DataUIWrapper/dataUIWrapper.js';
import { NewAgeFormBuilder } from '../../../../../../Common/ApplicationFormBuilder/FormBuilder/NewAgeFormBuilder.js';
import { useHistory } from 'react-router-dom';
import { NewAgeFormConfig } from '../../formJSON/newAgeConfig.js';
import { PageHeader } from '../../../../../../Common/Components/PageHeader/PageHeader.js';
import CreateDispatchers from '../../Utils/dispatchers/CreateDispatchers.js';
import getModelMasterData from '../../Utils/dispatchers/getModelMasterData.js';
import getModelMaster from '../../Utils/fetchers/getModelMaster.js';
import getClientFields from '../../Utils/fetchers/getClientFields.js';

const ModelDefinitionCreate = function(props){

	const [value, setValue] = React.useState({});
	const [componentExts, setComponentExts] = React.useState({});
	
	const history = useHistory();

	const {models, dispatchRequest, clients} = props;

	React.useEffect(function(){
		if(models){
			setComponentExts((_ogExts)=>{
				return ({
					..._ogExts,
					'model_name': {
						data: models
					}
				});
			})
		}
	},[models]);

	React.useEffect(function(){
		if(clients){
			setComponentExts((_ogExts)=>{
				return ({
					..._ogExts,
					'client_fields': {
						data: clients
					}
				});
			})
		}
	},[clients]);


	const _handleSave = React.useCallback(function(_value){
		dispatchRequest('create', {data: _value} ).then(function(){
			history.push('/rating/model-definition/list/');
		});
	},[dispatchRequest]);


	return (<PageHeader
					title={'Create Master Definition'}
					previousLinks={[
						{
							href: "/rating/model-definition/list/",
							text: 'Master Definition (All List)'
						}
					]}
					currentPage={'Create'}
				>
				<NewAgeFormBuilder 
					config={NewAgeFormConfig} 
					value={value}
					onChange={setValue}
					dispatchRequest={props.dispatchRequest}
					onSave={_handleSave}
					componentExts={componentExts}
					onUpdateComponentExts={setComponentExts}
				/>
			</PageHeader>);
}



export default dataUIWrapper(ModelDefinitionCreate, {
								dispatchers:{
									create: CreateDispatchers,
									getModelMasterData: getModelMasterData
								},
								featchers: [
									getModelMaster,
									getClientFields
								]
							});