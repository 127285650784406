import React from 'react';


const _handleDataObject = function(value, dataObject, setComponentExts){
	var obj={};

	if(Array.isArray(value)){
		dataObject.forEach(function(_model){
			obj[_model] = {
				data: window.structuredClone(value)
			}
		});
	}else{
		Object.keys(dataObject).forEach(function(_obj){
			var data = value[_obj],
				_models = dataObject[_obj];
			_models.forEach(function(_m){
				obj[_m]=  {
					data: window.structuredClone(data)
				};
			})	
		});
	}


	setComponentExts((_ogExts)=>{
		return ({
			..._ogExts,
			...obj
		});
	})
}


const _handleValueObject = function(value, valueObject, setValue){
	var obj={};

	if(Array.isArray(value)){
		valueObject.forEach(function(_model){
			obj[_model] = value;
		});
	}else{
		Object.keys(valueObject).forEach(function(_obj){
			var data = value[_obj],
				_models = valueObject[_obj];
			_models.forEach(function(_m){
				obj[_m] = data;
			})	
		});
	}


	setValue((_ogValue)=>{
		return ({
			..._ogValue,
			...obj
		});
	})
}





const useFetcherDataMapping = function(props, allDataFetchers, setComponentExts, setValue, options){
	
	options = options || {};

	for(var i=0;i<allDataFetchers.length;++i){

		var {key, dataObject, valueObject} = allDataFetchers[i];

		React.useEffect(function(){
			var that=this;
			if(props[that.key]){

				if(that.dataObject){
					_handleDataObject(props[that.key], that.dataObject, setComponentExts);
				}else if(that.valueObject && !options.skipValue){
					_handleValueObject(props[that.key], that.valueObject, setValue);
				}
				
			}
		}.bind({key: key, dataObject: dataObject, valueObject:valueObject}),[props[key]]);

	}

}

export {useFetcherDataMapping}