import React from 'react';
import rmIcon from './resources/rmicon.png';
import { LoginContext } from '../../../Context/LoginContext/LoginContext';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Link } from '@material-ui/core';

const OTPPage = function(){

    const {handleOTPCheck} = React.useContext(LoginContext);
    const [otp, setOTP] = React.useState('');
    const [disabled, setDisabled] = React.useState(false);
    const [error, setError] = React.useState();

    const _handleOkClick = React.useCallback(function(){
        setDisabled(true)
        handleOTPCheck({
            token: otp
        }, function(err){
            setError(err);
            setDisabled(false)
        })
    },[handleOTPCheck, otp]);

    const _handleKeyPress = React.useCallback(function(e){
        if(e.keyCode === 13){
            _handleOkClick();
        }
    },[_handleOkClick])

    const _className ="login-spacer "+(disabled?'app-form-disabled':'');

    return (<div className="app-flex app-flex-center" onKeyDown={_handleKeyPress}>
                <div className="login-spacer">
                    <img className="login-logo" src={rmIcon} alt="Logo" />
                </div>
                <div className={_className}>
                    <TextField id="outlined-basic" label="OTP" variant="outlined" type="password" value={otp} onChange={(e)=>{setOTP(e.target.value)}}/>
				</div>
                <div className={_className}>
                    <Button variant="contained" onClick={_handleOkClick}>
                        Verify
                    </Button>
                </div>
                <div className={_className}>
                    <Link component="button">Scan for OTP</Link>
                </div>

                { ( error )  && <div className="login-error-div">{error}</div>}
            </div>)
}

export {OTPPage}