import React from 'react';
import { ComponentLayout } from '../ComponentRenderer/ComponentLayout';
import { NewAgeContext } from '../../Context/NewAgeFormContext'; 
import { RMCard } from '../../../Components/WrappedComponents/RMCards/RMCards';

var defaultViewModes = ['create','view','edit','approve'];

const NewAgeLayoutCard = function(props){


    const context = React.useContext(NewAgeContext);
    const {mode} = context;

    const {items, viewModes=defaultViewModes, renderIf} = props;

    const isRendered = React.useMemo(function(){

        var rendered = true;
        if(renderIf){
            rendered = renderIf(context);
        }

        if(!rendered  || viewModes.indexOf(mode)===-1){
            return false;
        }
        return true;

    },[viewModes, mode, renderIf, context]);

    var _renderedCards = React.useMemo(function(){

        return items.map(function(_item){
            return (<RMCard key={_item.header} header={_item.header}>
                        <ComponentLayout className={_item.className} components={_item.componentConfig} />
                    </RMCard>)
        });

    },[items]);
    
    if(!isRendered){
        return null;
    }

    return (<div className="app-cards-columns-wrapper">
                {_renderedCards}
            </div>)


}

export {NewAgeLayoutCard}