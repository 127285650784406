import React from 'react';
import { Route} from 'react-router-dom';
import MarketRisk from '../MarketRisk/Router.js';
import DecisionSupportSystem from '../DecisionSupportSystem/Router.js';

import { GlobalModuleRouter } from './Modules/ModuleRouter.js';
import { ApplicationRouter } from '../Applications/ApplicationsRouter.js';

function MainRouter() {
	
	return (
		<React.Fragment>
			
			<GlobalModuleRouter/>
			<ApplicationRouter/>

			<Route path="/integated-risk">
					<MarketRisk/>
			</Route>
			<Route path="/decision-support-system">
					<DecisionSupportSystem/>
			</Route>
		</React.Fragment>
	);
}

export {
	MainRouter
}
