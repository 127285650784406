import React from 'react';
import { dataUIWrapper } from '../../../../../../Common/DataUIWrapper/dataUIWrapper.js';
import { PageHeader } from '../../../../../../Common/Components/PageHeader/PageHeader.js';
import getServerData from '../../Utils/fetchers/getServerData.js';
import EasyECLDashboardView from './EasyECLDashboardView.js';


const EasyECLDashboard = function(props){

	const { chartData=[]} = props;

	return (<PageHeader
				title={'ECL Dashboard'}
				previousLinks={[]}
				currentPage={'Dashboard View'}
			>
				<EasyECLDashboardView {...chartData}/>
			</PageHeader>);
}

export default dataUIWrapper(EasyECLDashboard, {
								dispatchers:{
								},
								featchers: [
									getServerData
								]
							});