import React from 'react';
import IRIEN from './Routes/Audits/IRIENAudits';
import { Route} from 'react-router-dom';	


function AuditRoutes() {
	
	
	return (<React.Fragment>
                <Route path="/irien/audit">
                    <IRIEN />
                </Route>
            </React.Fragment>);
}

export default AuditRoutes;
