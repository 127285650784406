import React from 'react';
import { RMCard } from '../WrappedComponents/RMCards/RMCards';
import { BarChart } from './BarChart';

const CardBarChart = function(props){

    const { headers=[], ...extProps} = props;

    const _dataMap = React.useMemo(function(){

        return headers.map(function(_obj){
            
            return(<div className="rm-bar-card-data">
                        <div className="rm-card-bar-header">
                                {_obj.title}
                        </div>
                        <div className="rm-card-bar-value">
                                {_obj.value || ''}
                        </div>
                    </div>)
        }) 
    },[headers]);

    return (<RMCard
                className={"rm-inline-card"}
            >
                {_dataMap}
                <div className="rm-card-bar-chart-container">
                    <BarChart {...extProps} legendPosition={"bottom"} height={400} width={400}/>
                </div>
            </RMCard>)
}

export {CardBarChart}