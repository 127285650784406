var configS6 = {
	config: [
		[
			{
				objectKey: 'risk_treatment',
				id: 'risk_treatment',
				widget: 'FormDropDown',
				text: 'Risk Mode',				
				data: [	]
			},
			{
				objectKey: 'mitigation_plan',
				id: 'mitigation_plan',
				widget: 'FormDropDown',
				text: 'Risk Treatment',
				data: [	]
			},
			{
				objectKey: 'mitigation_description',
				widget: 'FormTextInput',
				text: 'Mitigation Description'
			},
			{
				objectKey: 'mitigation_cost',
				id: 'mitigation_cost',
				widget: 'FormTextInput',
				text: 'Risk Treatement Cost',
				// data: []
			},
			{
				objectKey: 'send_for_approval',
				widget: 'FormSwitch',
				text: 'Send for Approval',
				tooltip: 'Send for Approval will change approval status to Pending for approval',
			}
		],[
			{
				objectKey: 'person_responsible',
				id: 'Person responsible',
				widget: 'FormDropDown',
				text: 'Person responsible',	
				data: [ ]			
			/*	data: [
					{ text: 'Ali', value: 'AvoAliid' },
					{ text: 'Jane Doe', value: 'Jane Doe' },
					{ text: 'Singh', value: 'Singh' },
					{ text: 'yazuka', value: 'yazuka' }
				]*/
			},
			
			{
				objectKey: 'target_date',
				widget: 'FormDateTimeInput',
				preprocessors:'date',
				text: 'Target Date'
			},
			{
				objectKey: 'risk_status',
				id: 'Risk Status',
				widget: 'FormDropDown',
				text: 'Risk Status',				
				data: [ ]
			}
			

		]

	]
}


export {
	configS6
}