import React from 'react';
import { dateValueParser } from '../../ApplicationFormBuilder/Parsers/dateValueParser.js';
import { StatusCell } from './StatusCell.js';
import { NameCell } from './NameCell.js';
import { FullPageCardGrid } from '../FullPageCardGrid/FullPageCardGrid.js';

var purgeList = [];

const ListGrid = function(props){

    const {
            data=[], 
            columns=[],
            approvalStatusField='approval_status',
            approvalStatusHeader='Approval Status',
            nameField="model_name",
            nameTitle='Model Name',
            onDelete,
            viewUrl,
            editList=[],
            deleteList=[],
            customIconRenderer,
            editUrl,
            purgeColumns=purgeList
        } = props;

    const _parsedData = React.useMemo(function(){
        return data.sort(function(a, b){
            var bTime = new Date(b.updated_time || b.created_time).getTime();
            var aTime = new Date(a.updated_time || a.created_time).getTime();
            return bTime - aTime
        });
    },[data]);


    var defaultColumns = React.useMemo(function(){
        
        var _defaults = [
            { field: nameField, headerName: nameTitle , minWidth: 350, flex: 1, 
              renderCell : function(params){
                  return (<NameCell 
                            status={params.row[approvalStatusField]}
                            name={params.row[nameField]}
                            onDelete={()=>onDelete(params.row)}
                            viewUrl={viewUrl}
                            editList={editList}
                            deleteList={deleteList}
                            editUrl={editUrl}
                            customIconRenderer={customIconRenderer}
                            row={params.row}
                           />);
              }
            },
            { field: 'approval_status', headerName: approvalStatusHeader, width: 200,
                  renderCell: function(params){
                        return (<StatusCell status={params.row[approvalStatusField]} />);
                  }
            },	  
            { field: 'created_user', headerName: 'Created User', width: 200 },	  
            { field: 'created_time', headerName: 'Created Time', type: 'date', width: 200,
                  valueFormatter: (params) => {
                      return dateValueParser(params.value,{});
                  }
            },	  	  
            { field: 'approved_user', headerName: 'Approved User', width: 200 },	  
            { field: 'approved_time', headerName: 'Approved Time', type: 'date', width: 200,
                  valueFormatter: (params) => {
                      return dateValueParser(params.value,{});
                  }
            },
            { field: 'updated_user', headerName: 'Updated User', width: 200 },	  
            { field: 'updated_time', headerName: 'Last Updated Time', type: 'date', width: 200,
                  valueFormatter: (params) => {
                      return dateValueParser(params.value,{});
                  }
            }
          ];

          columns.forEach(function(_c){
            _defaults.splice(_c.index, 0, _c);
          });

          purgeColumns.forEach(function(_purgeIdx){
            _defaults.splice(_purgeIdx, 1);
          });

          return _defaults;
        
    },[approvalStatusField, purgeColumns, nameField, approvalStatusHeader, nameTitle, onDelete, viewUrl, editUrl, deleteList, editList, customIconRenderer])


    return (<FullPageCardGrid rows={_parsedData} columns={defaultColumns}/>);    

}

export {ListGrid}