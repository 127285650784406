import React from 'react';


const StepperLabel = function(props){

    const handleSelect = function(){
        if(props.onSelect){
            props.onSelect(props.idx);
        }
    }

    return (<div className={"ecl-stepper-label-wrapper "+(props.selected?' ecl-stepper-selected':'')+(props.completed?' ecl-stepper-completed':'')}>
                <div className="ecl-stepper-label-container" onClick={handleSelect}>
                  <div className="ecl-stepper-idx">{props.idx+1}</div>
                  <div className="ecl-stepper-label">{props.label}</div>
                </div>
            </div>)

}


const Stepper = function(props){

    return (
        <div className="ecl-stepper-wrapper">
            {props.data.map(function(_item, idx){
                return (<StepperLabel key={_item.id} idx={idx} id={_item.id} label={_item.label} selected={props.selected>=idx}  onSelect={props.onSelect} completed={props.completed[_item.id]} />)
            })}
        </div>
    )


}


export default Stepper;