import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { NewAgeContext } from '../../Context/NewAgeFormContext';
import { newAgeFormConfig } from '../../Configs/NewAgeFormConfig';


const hasComponentTitle = {
    FormGridComponent: false
}

const defaultViewModeComponent = {
    FormGridComponent: 'FormGridComponent',
    Separator: 'Separator',
    FormTabsGridComponent: 'FormTabsGridComponent',
    FormDocument: 'FormDocument',
    FormMultiDimentionalGrid: 'FormMultiDimentionalGrid'
}

const getKabebCase = function(str){
    return str.replace(/[A-Z]/g, function(val){
        return '-'+val.toLowerCase();
    })
}

const defaultEditableModes = ["create","edit"];
const defaultViewModes = ['create','view','edit','approve'];

const ComponentRenderer = function(props){

    const formContext =  React.useContext(NewAgeContext);

    const   {
                id, 
                objectKey, 
                text, 
                tooltip, 
                widget, 
                widgetProps={},
                explicitUpdateModes=["edit"],
                data, 
                onAfterUpdate, 
                onExplicitEditUpdate,
                explicitAfterUpdate,
                editableModes=defaultEditableModes,
                viewModes=defaultViewModes,
                isDisabled,
                renderIf,
                viewValueParser
            } = props;

    const {onUpdateValue, componentValue={}, componentExtProps, mode, componentInvalids={}, didServerUpdate} = formContext;

    var value = componentValue[objectKey];

    const isReadOnly = React.useMemo(function(){
        return editableModes.indexOf(mode)===-1 || mode==='view';
    },[mode, editableModes]);

    const _renderedTooltip = React.useMemo(function(){
        if(tooltip){
           return( <Tooltip title={tooltip}>
                        <InfoIcon/>
                    </Tooltip> )
        }else{
          return null;  
        }
    },[tooltip]);

    const ComponentType = React.useMemo(function(){
        var _widget = widget
        if(isReadOnly){
            _widget = defaultViewModeComponent[widget] || 'FormTextDisplay'
        }
        return newAgeFormConfig.getComponent(_widget);
    },[widget, isReadOnly]);

    const _handleChange = React.useCallback(function(e){
        
        var ogValue = formContext.componentValue[objectKey];
        
        const updateObject = {
            key: objectKey,
            value: e.value,
            ogValue: ogValue,
            context: formContext,
            dispatchRequest: formContext.dispatchRequest,
            isEditExplicit: e.isEditExplicit
        }

        if(isReadOnly){
            if(e.isEditExplicit){
                if(explicitAfterUpdate){
                    onAfterUpdate(updateObject);
                }else if(onExplicitEditUpdate){
                    onExplicitEditUpdate(updateObject, onAfterUpdate);
                }
            }
            return;
        }

        onUpdateValue({
            [objectKey]: e.value
        });

        

        if(e.isEditExplicit && onExplicitEditUpdate){
            onExplicitEditUpdate(updateObject, onAfterUpdate);
        }else if(onAfterUpdate){
            setTimeout(function(){
                onAfterUpdate(updateObject);
            },20);
        }

    },[isReadOnly, objectKey, onUpdateValue, onAfterUpdate, formContext, onExplicitEditUpdate, explicitAfterUpdate]);

    var contextExtProps = componentExtProps[objectKey] || {};

    const [explicitEditFetch, setExplicitEditFetch] = React.useState(false);

    React.useEffect(function(){
       if(value && explicitUpdateModes.indexOf(mode)>-1 && !explicitEditFetch && didServerUpdate){
            setExplicitEditFetch(true);
            _handleChange({
                value: value,
                isEditExplicit: true   
            })
       }
    },[value, mode, explicitEditFetch, _handleChange, didServerUpdate, explicitUpdateModes])

    const isInvalid = React.useMemo(function(){
        return componentInvalids[objectKey];
    },[objectKey, componentInvalids]);

    var title = React.useMemo(function(){
        if(isInvalid){
            return isInvalid;
        }
        if(typeof value !=='undefined' && typeof value!=='object' && hasComponentTitle[widget]!==false){
            return value+'';
        }
    },[value, widget, isInvalid]);


  
    const isRendered = React.useMemo(function(){
        var rendered = true;
        if(renderIf){
            rendered = renderIf(formContext);
        }

        if(!rendered  || viewModes.indexOf(mode)===-1){
            return false;
        }
        return true;
    },[viewModes, mode, renderIf, formContext]);

    const _classNames = React.useMemo(function(){
        var _class = "app-form-wrapper form-builder-form-wrapper" 
         _class = _class + ' app-form-widget'+getKabebCase(widget);//(==='FormGridComponent'?' form-grid-container':'');
         _class = _class + (isReadOnly?' app-form-read-only-component':'');
        _class = _class +(isInvalid?' app-form-invalid':'')
         return _class
    
    },[widget, isReadOnly, isInvalid]);

    const _disabled = React.useMemo(function(){
        if(isDisabled){
            return isDisabled(formContext)   
        }
    },[isDisabled, formContext]);

    const inputTitle = React.useMemo(function(){
        if(text){
            if(typeof text==='function'){
                var _text = text(formContext);
            }else{
                _text = text;
            }

            return (<div className="form-label">
                        {_text}
                    </div>)
        }

        return null;
    },[text, formContext])

    if(!isRendered){
        return null;
    }

    return 	(<div className={_classNames} title={title}>
                {inputTitle}
                <div className={"form-input " + (tooltip?'tooltip-form':'')}>
                    <ComponentType 
                        id={id || objectKey} 
                        onChange={_handleChange} 
                        value={value} 
                        data={data}
                        {...widgetProps}
                        {...contextExtProps}
                        viewValueParser={viewValueParser}
                        viewOnlyMode={isReadOnly}
                        disabled={_disabled}
                    />
                    {_renderedTooltip}
                </div>
            </div>)
}

export {ComponentRenderer};