import React from 'react';
import EasyECLReport from './Routes/Report/EasyECLReport';
import { Route} from 'react-router-dom';	


function ECLReportsRouter() {
	
	
	return (<React.Fragment>
                <Route path="/ecl/model-report">
                    <EasyECLReport />
                </Route>
            </React.Fragment>);
}

export default ECLReportsRouter;
