import React from 'react';
import Typography from '@material-ui/core/Typography';

const SuiteCard = function(props){

    const {onClick, logoSrc} = props;

    return (<div className="ecl-cards" onClick={onClick}>
                <div className="MuiPaper-root MuiCard-root main-ui-card MuiPaper-elevation1 MuiPaper-rounded">
                    <div id="iriene-logo" className="ecl-card-logo-container">
                        <img src={logoSrc} alt="Logo"/>
                    </div>
                
                </div>
            </div>)
}

export {SuiteCard}