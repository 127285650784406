
window.riskMac={}

//window.riskMac.mainURL = window.location.protocol+'//'+window.location.hostname+':8000';
window.riskMac.mainURL = window.location.protocol+'//'+window.location.hostname;

window.riskMac.APIConfig = {
    downloadFile: window.riskMac.mainURL + '/downloadFile/',
    uploadFiles:  window.riskMac.mainURL +'/uploadFile/',
	getFiles:  window.riskMac.mainURL + '/getListofFiles/',
	deleteFile:  window.riskMac.mainURL + '/deleteFile/',
}

