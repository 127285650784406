import React from 'react';
import Rating from './Routes/Audits/RatingAudits';
import { Route} from 'react-router-dom';	


function AuditRoutes() {
	
	
	return (<React.Fragment>
                <Route path="/rating/audit">
                    <Rating />
                </Route>
            </React.Fragment>);
}

export default AuditRoutes;
