import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { Link} from "react-router-dom";
import DeleteIcon from '@material-ui/icons/Delete';

const AppDeleteIcon = function(props){

    const {link='#', onClick, title="Delete"} = props;

    return (<Link to={link}>
            <IconButton title={title} variant="contained" onClick={onClick}>
                <DeleteIcon className="ecl-delete-icon"/>
            </IconButton>
        </Link>)
}

export {AppDeleteIcon}