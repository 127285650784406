import React from 'react';
import { RMCard } from '../WrappedComponents/RMCards/RMCards';
import { SearchBar } from '../SearchBar/Searchbar';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@mui/material/Checkbox';

const ListSelectModal = function(props){

    const {title, show, selected, data=[], textField, onUpdate} = props;
    const [search, setSearch] = React.useState('');

    const [internalSelected, setSelected] = React.useState(selected);

    React.useEffect(function(){
        setSelected(selected);
    },[selected])

    const _handleCheckboxClick = React.useCallback(function(value){
        setSelected(function(ogList){
            var index = ogList.indexOf(value),
                list = [...ogList];
            if(index>-1){
                list.splice(index, 1);
            }else{
                list.push(value);
            }
            return list;
        });
    },[])

    const _renderedData = React.useMemo(function(){

        return data.map(function(_d){
            
            var value = textField?_d[textField]:_d;

            if(value.toLowerCase().indexOf(search.toLowerCase()) === -1){
                return null
            }

            var isChecked = internalSelected.indexOf(value) > -1;

            return (<div key={value} className="app-flex-col-nogrow app-modal-list-item" onClick={()=>_handleCheckboxClick(value)}>
                        <Checkbox checked={isChecked} />
                        <div>
                            {value}
                        </div>
                    </div>)


        });

    },[internalSelected, data, textField, search, _handleCheckboxClick]);


    return (<Modal
                open={!!show}
            >   
                <Box className="app-modal-box">
                    <RMCard header={title} className="app-flex app-modal-card">
                        <SearchBar value={search} onChange={(e)=>setSearch(e.target.value)}/>
                        <div className="app-full-overflow app-list-view">
                            {_renderedData}
                        </div>
                        <div className="app-form-button-bar">
                            <Button variant="contained"onClick={()=>onUpdate({ selected: internalSelected , props: props})}>
                                Update
                            </Button>
                        </div>
                    </RMCard>
                </Box>
            </Modal>)    

}

export {ListSelectModal}