import React from 'react';
import { dataUIWrapper } from '../../../../../../Common/DataUIWrapper/dataUIWrapper.js';
import { PageHeader } from '../../../../../../Common/Components/PageHeader/PageHeader.js';
import { notificationObj } from '../../../../../../Global/NotificationHandler.js';
import { ListGrid } from '../../../../../../Common/Components/ListGrid/ListGrid.js';
import getMasterList from '../../Utils/fetchers/getMasterList.js';
import deleteDispatchers from '../../Utils/dispatchers/deleteDispatchers.js';

const ModelDefinitionList = function(props){

	const { dispatchRequest, refetch, serverData=[]} = props;

	const handleDelete = React.useCallback(function(_row){
		
		notificationObj.showConfirmBox({
			description: 'Do you want to delete?',
			ok: function(){					
				dispatchRequest('delete', {
					name: _row.model_definition_name
				}).then(function(){
					refetch();
				});
			}
		});
		
	},[dispatchRequest]);


	return (<PageHeader
				title={'Model Definition List'}
				currentPage={'Model Definition List'}
				buttonConfig={{
					url: '/ecl/model-definition/create/',
					label: 'Create Model Definition'
				}}
			>
				<ListGrid
					data={serverData}
					nameField={"model_definition_name"}
					nameTitle={"Model Definition"}
					columns={[
						{ field: 'model_name', headerName: 'Model Name', width: 280, index: 1}
					]}
					editList={["Draft","Rejected"]}
					deleteList={["Draft","Rejected"]}
					onDelete={handleDelete}
					viewUrl="/ecl/model-definition/view/"
					editUrl="/ecl/model-definition/edit/"
				/>
			</PageHeader>);
}

export default dataUIWrapper(ModelDefinitionList, {
								dispatchers:{
									delete: deleteDispatchers
								},
								featchers: [
									getMasterList
								]
							});