import React from 'react';
import Stepper from '../../../Components/Stepper/Stepper.js';
import { NewAgeLayoutCard } from './NewAgeLayoutCards.js';
import Button from '@material-ui/core/Button';
import { newAgeFormConfig } from '../../Configs/NewAgeFormConfig.js';

const defaultEditableModes = ["create","edit"];

const extractFromLayoutItems = function(_items, mode){
    var validations = {},
        saveParsing={};

    _items.forEach(function(sections){

        sections.items.forEach(function(cards){
            cards.componentConfig.forEach(function(componentList){
                componentList.forEach(function(component){

                    var _editableModes = component.editableModes || defaultEditableModes;
                    if(_editableModes.indexOf(mode)===-1){
                        return;
                    }

                    if(component.validation){
                        validations[component.objectKey] = component.validation;
                    }
                    if(component.saveParsing){
                        saveParsing[component.objectKey] = [component.saveParsing];
                    }

                    var defaultSaveParser = newAgeFormConfig.getDefaultSaveParser(component.widget);
                    if(defaultSaveParser){
                        if(saveParsing[component.objectKey]){
                            saveParsing[component.objectKey].push(defaultSaveParser);
                        }else{
                            saveParsing[component.objectKey] = [defaultSaveParser];
                        }
                    }

                });                
            });
        });

    });

    return {
        validations,
        saveParsing
    }

}

var $noopValidation = function(){

}

const NewAgeLayoutBuilder = function(props){

    const [stepperSelected, setStepperSelected] = React.useState(0);

    const {
            config, 
            onSave, 
            validate, 
            stepValidate, 
            mode, 
            renderAllLayoutsMode=["view","approve"],
            customButtonRenderer,
            saveButtonIndex,
            disableSave,
            children
        } = props;

    const {layoutItems} = config;

    const enableStepper = React.useMemo(function(){
        if(layoutItems.length > 1){
            if(renderAllLayoutsMode.indexOf(mode)===-1){
                return true;
            }
        }
        return false;
    },[renderAllLayoutsMode, mode]);

    var stepperProps = React.useMemo(function(){

        var complete = [],
            validationsArr = [],
            saveParsingObj = {},
            stepValidations=[];

        var _items = layoutItems.map(function(e){
            
            complete.push(false);    

            var {validations, saveParsing}  = extractFromLayoutItems(e.itemsLayout, mode);
            validationsArr.push(validations);
            stepValidations.push(e.stepValidation || $noopValidation);
            saveParsingObj = {
                ...saveParsingObj,
                ...saveParsing
            }

            return ({label: e.stepperName})
        
        });

        return {
            data: _items,
            completed: complete,
            validations: validationsArr,
            saveParsing: saveParsingObj,
            stepValidations: stepValidations
        }
    },[layoutItems, mode]);


    const _renderSelectedLayout = React.useCallback(function(layoutItems){
        const selectedItemLayout = layoutItems[stepperSelected].itemsLayout;

        return (selectedItemLayout || []).map(function(e, idx){
            return (<NewAgeLayoutCard key={idx} {...e} />)
        });
    },[stepperSelected]);

    const _renderAllLayouts = React.useCallback(function(layoutItems){

        return layoutItems.map(function(_layouts, _layoutIdx){
            var toRenderLayout = _layouts.itemsLayout;

            return (toRenderLayout || []).map(function(e, idx){
                return (<NewAgeLayoutCard key={idx+'-'+_layoutIdx} {...e} />)
            });

        })

    },[])

    const _renderedLayouts = React.useMemo(function(){

        if(renderAllLayoutsMode.indexOf(mode)>-1){
            return _renderAllLayouts(layoutItems);
        }else{
           return _renderSelectedLayout(layoutItems);
        }

    },[stepperSelected, layoutItems,_renderSelectedLayout, _renderAllLayouts, renderAllLayoutsMode, mode]);


    const validateCurrentStep = React.useCallback(function(){
        var isValid = validate(stepperProps.validations[stepperSelected]);
        if(isValid){
            isValid = stepValidate(stepperProps.stepValidations[stepperSelected]);
        }
        return isValid;
    },[stepperSelected, validate, stepperProps])

    const incrementStep = React.useCallback(function(){
        if(stepperSelected === stepperProps.completed.length){
            return;
        }
        
        if(validateCurrentStep()){
            setStepperSelected(stepperSelected + 1);
        }
        
    },[stepperProps, stepperSelected, validateCurrentStep])

    
    const decrementStep = React.useCallback(function(){
        if(stepperSelected===0){
            return;
        }
        
        setStepperSelected(stepperSelected - 1);
    },[stepperSelected])

    const triggerSave = React.useCallback(function(options){
        if(validateCurrentStep()){
            onSave(stepperProps.saveParsing, options);     
        }
    },[onSave, validateCurrentStep, stepperProps]);

    const _saveButtonIndex = React.useMemo(function(){
        if(saveButtonIndex){
            return saveButtonIndex
        }else{
            return stepperProps.completed.length - 1;
        }
    },[stepperProps, saveButtonIndex])
    
    const _buttons = React.useMemo(function(){
        var buttons = [];

        if(enableStepper){
            if(stepperSelected<=_saveButtonIndex){
                buttons.push(<Button 
                    key={'prev'}
                    variant="contained" 
                    color="primary"
                    disabled={stepperSelected===0} 
                    onClick = {decrementStep}>
                        Prev
                </Button>);
            }           

            if(stepperSelected!==_saveButtonIndex && stepperSelected<=_saveButtonIndex){
                buttons.push(<Button 
                                key={'next'}
                                variant="contained" 
                                color="primary"
                                onClick = {incrementStep}>
                                    Next
                            </Button>);
            }

        }

        if((!enableStepper || stepperSelected===_saveButtonIndex ) && !disableSave){
            buttons.push(<Button 
                            key={'save'}
                            variant="contained" 
                            color="primary"
                            onClick = {()=>triggerSave({
                                            incrementStep: incrementStep,
                                            decrementStep: decrementStep
                                        })}>
                                Save
                        </Button>);
        }

        if(customButtonRenderer){
            buttons.push(customButtonRenderer({
                saveButtonIndex: _saveButtonIndex, 
                stepperSelected: stepperSelected,
                incrementStep: incrementStep,
                decrementStep: decrementStep,
                triggerSave: triggerSave,
                setStepperSelected: setStepperSelected
            }))
        }

        return buttons;

    },[enableStepper, stepperSelected, _saveButtonIndex, decrementStep, incrementStep, triggerSave, disableSave])

    return (<div className="app-flex new-age-form-grid-main-wrapper">
                <div className={"app-full-overflow app-form-cards-container app-form-mode-"+mode}>
                    {enableStepper && <Stepper selected={stepperSelected} {...stepperProps} onSelect={(e)=>{}}></Stepper>}
                    {_renderedLayouts}
                    {children}
                </div>
                {mode!=='view' && (<div className="app-form-button-bar">
                    {_buttons}
			    </div>)}
            </div>)

}

export {NewAgeLayoutBuilder}