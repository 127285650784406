
const userDetailsConfig = [
	[

		{
			objectKey: 'created_user',
			widget: 'FormTextInput',
			editableModes: [],
			text: 'Created User'
		},	
		{
			objectKey: 'created_time',
			widget: 'FormTextInput',
			editableModes: [],
			text: 'Created Time',
			viewValueParser: {
				parser: 'dateValueParser'
			}
		},
		{
			objectKey: 'created_user_ip_address',
			widget: 'FormTextInput',
			editableModes: [],
			text: 'IP Address'
		},
		{
			widget: 'Separator'
		},
		{
			objectKey: 'updated_user',
			widget: 'FormTextInput',
			editableModes: [],
			text: 'Updated User'
		},	
		{
			objectKey: 'updated_time',
			widget: 'FormTextInput',
			editableModes: [],
			text: 'Updated Time',
			viewValueParser: {
				parser: 'dateValueParser'
			}
		}
	]
]

const ModelApprovalConfig = [
	[
		{
			objectKey: 'approval_status',
			widget: 'FormRadioGroup',
			text: 'Approval Status',
			editableModes: ['approve'],
			widgetProps: {
				items: [
					{
						value: 'Approved',
						text: 'Approved',
						tooltip: 'Approved model cannot be edited'
					},
					{
						value: 'Rejected',
						text: 'Rejected',
						tooltip: 'Rejected model will change status to Draft'
					}

				]
			},
			validation: [
				{
					type: 'approvalValidation',
					message: 'Approval Status is Required'
				}
			]
		},{
			viewModes: ["edit","view"],
			objectKey: 'approved_user',
			widget: 'FormTextInput',
			text: 'Approved User',
			editableModes: []
		},
		{
			viewModes: ["edit","view"],
			objectKey: 'approved_time',
			widget: 'FormTextInput',
			text: 'Approval Time',
			editableModes: [],
			viewValueParser: {
				parser: 'dateValueParser'
			}
		},
		{
			widget: 'Separator'
		},
		{
			objectKey: 'approved_user_comment',
			widget: 'FormMultiLineInput',
			text: 'Approval Comments',
			editableModes: ['approve'],
			validation: [
				{
					type: 'required',
					message: 'Approval Comments is required'
				}
			]
		}
	]
]

export default {
    viewModes: ['view','edit','approve'],
    items : [
        {
            header: 'Approval Details',
            componentConfig: ModelApprovalConfig
        },
        {
            header: 'User Details',
            componentConfig: userDetailsConfig
        }
    
    ]
}