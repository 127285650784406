import React from 'react';
import { dataUIWrapper } from '../../../../../../Common/DataUIWrapper/dataUIWrapper.js';
import { PageHeader } from '../../../../../../Common/Components/PageHeader/PageHeader.js';
import { notificationObj } from '../../../../../../Global/NotificationHandler.js';
import { ListGrid } from '../../../../../../Common/Components/ListGrid/ListGrid.js';
import getMasterList from '../../Utils/fetchers/getMasterList.js';
import deleteDispatchers from '../../Utils/dispatchers/deleteDispatchers.js';
import { StatusCell } from '../../../../../../Common/Components/ListGrid/StatusCell.js';
import { AppUploadIcon } from '../../../../../../Common/Icons/AppUploadIcon.js';

const RiskRegisterList = function(props){

	const { dispatchRequest, refetch, serverData=[]} = props;

	const handleDelete = React.useCallback(function(_row){
		
		notificationObj.showConfirmBox({
			description: 'Do you want to delete?',
			ok: function(){					
				dispatchRequest('delete', {
					name: _row['model_name']
				}).then(function(){
					refetch();
				});
			}
		});
		
	},[dispatchRequest]);

	const _uploadIconRenderer = React.useCallback(function(_props){
		const {status, name} = _props;
		
		if(status==='Draft' || status==='Rejected' || status==="Approved"){
			return (<AppUploadIcon link={"/integated-risk/upload-files/"+name} />)
		}
		return null;

	},[])

	return (<PageHeader
				title={'Risk Configurations List'}
				currentPage={'Risk Configurations List'}
				buttonConfig={{
					url: '/irien/risk-register/create/',
					label: 'Create Risk Configurations'
				}}
			>
				<ListGrid
					data={serverData}
					nameField={"model_name"}
					nameTitle={"Risk Configurations Name"}
					editList={["Draft","Rejected","Approved"]}
					deleteList={["Draft","Rejected"]}
					onDelete={handleDelete}
					viewUrl="/irien/risk-register/view/"
					editUrl="/irien/risk-register/edit/"
					customIconRenderer={_uploadIconRenderer}
					columns={[
						{ field: 'division', headerName: 'Division', width: 280, index: 1},
						{ field: 'department', headerName: 'Department', width: 280, index: 2},
						{ field: 'risk_status', headerName: 'Risk Status', width: 280, index: 4,
							renderCell: function(params){
								return (<StatusCell status={params.row.risk_status} />);
							}
						},
						{ field: 'residual_risk_factor', headerName: 'Residual Risk Factor', width: 280, index: 5},
						{ field: 'inherent_risk_factor', headerName: 'Inherent Risk Factor', width: 280, index: 6},
					]}
				/>
			</PageHeader>);
}

export default dataUIWrapper(RiskRegisterList, {
								dispatchers:{
									delete: deleteDispatchers
								},
								featchers: [
									getMasterList
								]
							});