
import getERMConfigs from "./allDataFetchers/getERMConfigs"
import getMisssionGoals from "./allDataFetchers/getMisssionGoals"
import getRAS from "./allDataFetchers/getRAS"
import getStrategies from "./allDataFetchers/getStrategies"



export default [
    getERMConfigs,
    getMisssionGoals,
    getRAS,
    getStrategies
]