import React from 'react';
import { AppDeleteIcon } from '../../../Icons/AppDeleteIcon';
import { AppSettingsIcon } from '../../../Icons/AppSettingsIcon';

const MultiGridMainCell = function(props){

    const {viewOnlyMode, value='', onDelete, onSettingsClick} = props;

    const [deleteIcon, settingsIcon] = React.useMemo(function(){
        if(viewOnlyMode){
            return [null, null];
        }

        return [
            (<AppDeleteIcon key={'main-delete'} onClick={onDelete}/>),
            (<AppSettingsIcon key={'main-settings'} onClick={onSettingsClick}/>)
        ]


    },[onDelete, onSettingsClick, viewOnlyMode]);

    return (
        <div className="row-icon-container">
            <div className="row-label" title={value}>
                {value}
            </div>
            {deleteIcon}
            {settingsIcon}
        </div>
    )

}

export {MultiGridMainCell}
