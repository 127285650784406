import React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { AppSettingsIcon } from '../../../Icons/AppSettingsIcon';
import { MultiGridMainCell } from './MultiGridMainCell';
import { MultiGridIncrementalCell } from './MultiGridIncrementalCell';
import { newAgeFormConfig } from '../../Configs/NewAgeFormConfig';
import { ListSelectModal } from '../../../Components/ListSelectModal/ListSelectModal';


const FormMultiDimentionalGrid = function(props){

    const {
        rowField='', 
        columnField='',
        mainColumnField='',
        mainColumnTitle='',
        value=[],
        viewOnlyMode,
        incrementalColumnField='',
        incrementalColumnTitle='',
        rowModalTitle='',
        columnModalTitle='',
        rowTextField = '',
        columnTextField ='',
        onChange
    } = props;

    const [rowDataModal, setRowDataModal] = React.useState(false);
    const [columnDataModal, setColumnDataModal] = React.useState(false);

    const _rowsData = props[rowField] || [],
          _columnData = props[columnField] || [];


    const _handleDeleteRow = React.useCallback(function(row){
        if(row){
            var newValue = window.structuredClone(value);
            var idx = row.___idx;
            newValue.splice(idx, 1);
            onChange({
                value:  newValue   
            });
        }
    },[value, onChange]);
        
    const _mainHeaderColumn = React.useMemo(function(){

        var header = (<div className="row-icon-container app-flex-center">
                        <div className="row-label header-row">
                            {mainColumnTitle}
                        </div>
                        {!viewOnlyMode && (<AppSettingsIcon
                            onClick={(e)=>{setRowDataModal(true);}}
                        />)}
                    </div>)

        return { 
                    field: mainColumnField, headerName: (header), width: 500, 
                    sortable: false,
                    renderCell : function(params){
                            return (<MultiGridMainCell 
                                        value={params.row[mainColumnField]} 
                                        viewOnlyMode={viewOnlyMode}
                                        onSettingsClick={()=>setColumnDataModal(params.row)}
                                        onDelete={()=>_handleDeleteRow(params.row)}
                                    />)
                    }
               }
    },[mainColumnField, viewOnlyMode, mainColumnTitle, _handleDeleteRow]);

    const columnLength = React.useMemo(function(){
        var max = 0;
        value.forEach(function(_v){
            var length = Object.keys(_v).length;
            if(length>max){
                max = length;
            }
        })
        return max-1;
    },[value]);

    const _handleRiskRemove = React.useCallback(function(row, field){
        if(row){
            var newValue = window.structuredClone(value);
            var idx = row.___idx,
                obj=newValue[idx];
            
            var incrementalColumnNumber = Number(field.slice(incrementalColumnField.length)),
                totalColumns = Object.keys(obj).length-2;

            for(var i=incrementalColumnNumber;i<totalColumns;++i){
                obj[incrementalColumnField+i]=obj[incrementalColumnField+(i+1)];
            }

            delete obj[incrementalColumnField+totalColumns];
            
            onChange({
                value:  newValue   
            });
        }
    },[value, onChange])

    var columns = React.useMemo(function(){
        var _cols = [_mainHeaderColumn];
        
        for(var i=0;i<columnLength;++i){
            _cols.push({
                field: incrementalColumnField+(i),
                headerName: incrementalColumnTitle,
                width: 300,
                renderCell : function(params){
                    return (<MultiGridIncrementalCell 
                                value={(params.row[params.field])}
                                onClose={()=>_handleRiskRemove(params.row, params.field)}
                            />)
                }
            })
        }

        return _cols;

    },[_mainHeaderColumn, columnLength, incrementalColumnField, incrementalColumnTitle, viewOnlyMode, _handleRiskRemove]);

    const _parsedValue = React.useMemo(function(){
        return value.map(function(_v, idx){
            return {
                ..._v,
                ___idx: idx,
                id: _v.id || idx
            }
        })
    },[value]);

    const _handleRowsUpdate = React.useCallback(function(e){

        var selected=e.selected, selectedMap = {};
        selected.forEach(function(_s){
            selectedMap[_s]=true;
        });

        var newValue = value.filter(function(e){
            var _text = e[mainColumnField];
            if(selectedMap[_text]){
                delete selectedMap[_text];
                return true;
            }
            return false;
        });

        Object.keys(selectedMap).forEach(function(_text){
            newValue.push({  [mainColumnField]:_text  });
        });

        onChange({
            value: newValue   
        });

        setRowDataModal(false);

    },[value, onChange, mainColumnField]);

    const _handleAddColumnToRow = React.useCallback(function(e){
        var selected=e.selected,
            index=e.props.show.___idx;

        
        var newValue =[...value],
            oldObject = newValue[index],
            obj = {
                [mainColumnField]: oldObject[mainColumnField]
            }

        selected.forEach(function(_val, idx){
            obj[incrementalColumnField+idx] = _val;
        });

        newValue.splice(index, 1, obj);

        onChange({
            value: newValue   
        });
        setColumnDataModal(false);
    },[_columnData, value, mainColumnField, incrementalColumnField, onChange]);

    const _rowsSelected = React.useMemo(function(){

        if(rowDataModal){
            return value.map(function(_val){
                return _val[mainColumnField]; 
            });
        }
        return [];


    },[rowDataModal, value]);

    const _columnSelected = React.useMemo(function(){

        var selected = []
        if(columnDataModal){
            Object.keys(columnDataModal).forEach(function(_key){
                if(_key.indexOf(incrementalColumnField)===0){
                    selected.push(columnDataModal[_key])
                }
            })
        }
        return selected;


    },[columnDataModal, mainColumnField, incrementalColumnField]);

	return (<React.Fragment>
                <DataGrid
                    rows={_parsedValue} 
                    columns={columns}
                    className="rm-form-datagrid"
                />
                <ListSelectModal
                    title={rowModalTitle}
                    show={rowDataModal}
                    selected={_rowsSelected}
                    data={_rowsData}
                    textField={rowTextField}
                    onUpdate={_handleRowsUpdate}
                />
                 <ListSelectModal
                    title={columnModalTitle}
                    show={columnDataModal}
                    selected={_columnSelected}
                    data={_columnData}
                    textField={columnTextField}
                    onUpdate={_handleAddColumnToRow}
                />
            </React.Fragment>);

}




newAgeFormConfig.pluginComponent('FormMultiDimentionalGrid', FormMultiDimentionalGrid);