import React from 'react';
import { Route} from 'react-router-dom';	
import UserProfileView from './UserProfile/UserProfileView';
import AdminView from './AdminView/AdminView';
import { CommonApplicationCards } from './SuiteCards/CommonApplicationCards';
import { AvailableForSubscriberPage } from './AvailableForSubscriberPage/AvailableForSubscriberPage';


function GlobalModuleRouter() {

	
	return (
		<React.Fragment>
            <Route exact path="/">
				<CommonApplicationCards/>
			</Route>
			<Route path="/user-profile/">
				<UserProfileView/>
			</Route>
            <Route path="/admin">
				<AdminView/>
			</Route>
            <Route exact path="/:app/:module/available-for-subscribers">
                <AvailableForSubscriberPage/>
            </Route>
		</React.Fragment>
	);
}

export {GlobalModuleRouter}
