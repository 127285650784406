var mainUrl=window.riskMac.mainURL;


export default {	
		getAgreement: mainUrl+'/getLicenseAgreement/',
        getAgreementInfo: mainUrl+'/getLicenseAgreementInfo/',
        verifyAgreement: mainUrl+'/VerifyLicenseAgreement/'
}


