import React from 'react';
import { NewAgeContext } from '../Context/NewAgeFormContext';
import { NewAgeLayoutBuilder } from './LayoutBuilder/LayoutBuilder';
import { newAgeFormConfig } from '../Configs/NewAgeFormConfig';
import { notificationObj } from '../../../Global/NotificationHandler';
import '../Components/main.js';
import '../Parsers/main.js';
import '../Configs/Validations/BaseValidations';

const parseSaveParser = function(value, saveParser){
    var _obj = {}
    Object.keys(saveParser).forEach(function(_key){

        var parsers = saveParser[_key];
        var _value = value[_key];
        parsers.forEach(function(_parser){
            _value = _parser(_value);
        })

        _obj[_key] = _value;
    });

    return {
        ...value,
        ..._obj
    }
}


const NewAgeFormBuilder = function(props){

    const {
            config, 
            value={}, 
            onChange, 
            mode='create',
            incomingData,
            componentExts={},
            onUpdateComponentExts,
            dispatchRequest,
            customButtonRenderer,
            saveButtonIndex,
            onSave,
            disableSave,
            children
          } = props;

    const [invalids, setInvalids] = React.useState({});
    const [didServerUpdate, setDidServerUpdate] = React.useState(false);
    const [watch, setWatch] = React.useState({});

    const {finalSaveParsing, defaultValue} = config;

    const _setWatchItems = React.useCallback(function(obj){
        setWatch((ogWatch)=>{
               return {
                    ...ogWatch,
                    ...obj
               }
        })
    },[]);

    const checkWatch = React.useCallback(function(_obj){
        var updated = false;
        Object.keys(_obj).forEach(function(key){
            if(_obj[key] !== watch[key]){
                updated=true;
            }
        });

        if(updated){
            _setWatchItems(_obj);
        }
        return updated;
    },[watch, _setWatchItems]);

    React.useEffect(function(){
        if(incomingData){
            onChange(incomingData);
            _setWatchItems(incomingData);
            setDidServerUpdate(true);
        }
    },[incomingData, onChange, _setWatchItems]);

    React.useEffect(function(){
        if(defaultValue)
            onChange(window.structuredClone(defaultValue));
    },[defaultValue, onChange]);

    const _triggerSave = React.useCallback(function(saveParser, options){
        var _newValue = parseSaveParser(value, saveParser);
        if(onSave){
            if(finalSaveParsing){
                _newValue = finalSaveParsing(window.structuredClone(_newValue), {
                    config: config,
                    componentExts: componentExts
                });
            }
            onSave(_newValue, options);
        }
    },[value, finalSaveParsing, componentExts, config]);

    const _updateValue = React.useCallback(function(newValueObject){
    
        onChange(function(_value){
            return {
                ..._value, 
                ...newValueObject
            }
        });

    },[onChange]);

    const _updateExts = React.useCallback(function(newValueObject){
                
        if(onUpdateComponentExts){
            onUpdateComponentExts(function(ogExtras){
                return {
                    ...ogExtras,
                    ...newValueObject
                }
            });
        }

    },[onUpdateComponentExts]);

    const _validate = React.useCallback(function(validObject){

        var obj = {},
            raisedMessage = false;

        Object.keys(validObject).forEach(function(_keys){
            var _value = value[_keys],
                validConfig = validObject[_keys];

            if(!Array.isArray(validConfig)){
                validConfig = [validConfig];
            }

            validConfig.forEach(function(_config){
                var fn = newAgeFormConfig.getValidation(_config.type),
                    message = fn(_value, _config);
        
                if(message){
                    obj[_keys] = message;
                }
                if(message && !raisedMessage){
                    notificationObj.showNotification('error',message);
                    raisedMessage=true;
                }
            });
              
        });

        setInvalids(obj);

        return !Object.keys(obj).length;
        
    },[value]);

    const _setpValidation = React.useCallback(function(fn){
        var message = fn(value);
        if(message){
            notificationObj.showNotification('error',message); 
        }
        return !message;

    },[value]);

    

    return (<NewAgeContext.Provider 
                value={{
                    componentValue: value,
                    onUpdateValue: _updateValue,
                    componentExtProps: componentExts,
                    onUpdateExts: _updateExts,
                    dispatchRequest: dispatchRequest,
                    componentInvalids: invalids,
                    mode: mode,
                    setWatch: _setWatchItems,
                    checkWatch: checkWatch,
                    didServerUpdate: didServerUpdate
                }}>
                    <NewAgeLayoutBuilder 
                        config={config} 
                        mode={mode}
                        onSave={_triggerSave}
                        validate={_validate}
                        stepValidate={_setpValidation}
                        customButtonRenderer={customButtonRenderer}
                        saveButtonIndex={saveButtonIndex}
                        disableSave={disableSave}
                    >{children}</NewAgeLayoutBuilder>
            </NewAgeContext.Provider>);
}

export {NewAgeFormBuilder}