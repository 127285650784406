var mainUrl=window.riskMac.mainURL;


export default {	
		getMasterList: mainUrl+'/ratinggetMasterRecords/all/',
		getMasterRecord: mainUrl+'/ratinggetMasterRecord/',
		create: mainUrl+'/ratingcreateMasterEntry/',
		delete: mainUrl+'/ratingdeleteMasterRecord/',
		edit: mainUrl+'/ratingcreateMasterEntry/',
		approve: mainUrl+'/ratingupdateMasterRecordApproval/',
		approvalList: mainUrl+'/ratinggetMasterRecords/allaction/',
}


