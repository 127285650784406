var mainUrl=window.riskMac.mainURL;


export default {	
		getApprovedModelDefinitions: mainUrl+'/getDefinitionRecords/approved/',
		getModelDefinition: mainUrl+'/getDefinitionRecord/',
		getMasterList: mainUrl+'/getScenarioDefinitionRecords/all/',
		getMasterRecord: mainUrl+'/getScenarioDefinitionRecord/',
		create: mainUrl+'/createScenarioDefinitionEntry/',
		delete: mainUrl+'/deleteScenarioRecord/',
		edit: mainUrl+'/createScenarioDefinitionEntry/',
		approve: mainUrl+'/updateScenarioDefinitionApproval/',
		approvalList: mainUrl+'/getScenarioDefinitionRecords/allaction/',
}


