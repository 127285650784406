import React from 'react';
import ModelMasterRoutes from './Modules/ModelMaster/ModelMasterRoutes.js';
import AuditsRoutes from './Modules/AuditTrail/AuditsRoutes.js';
import ModelDefinitionRoutes from './Modules/ModelDefinition/ModelDefinitionRoutes.js';
import ComputationRoutes from './Modules/Computation/ComputationRoutes.js';
/*Push Import*/

const ApplicationRoutes = function(){

    return (<React.Fragment>
                <ModelMasterRoutes/>
				<AuditsRoutes/>
				<ModelDefinitionRoutes/>
                <ComputationRoutes />
				{/*Push*/}
            </React.Fragment>)

}

export default ApplicationRoutes;