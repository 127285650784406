import React from 'react';
import { RMCard } from '../../../../../../Common/Components/WrappedComponents/RMCards/RMCards';
import { SimplePieChart } from '../../../../../../Common/Components/Charts/SimplePieChart';

function EasyECLDashboardView(props) {
	
	const {aggregateMasterData=[], aggregateDefinitionData=[], aggregateScenarioData=[], reportsData=[]} = props;

	return (
		<div className="app-form-cards-container app-full-overflow">
			<div className="app-cards-columns-wrapper">
				<RMCard variant="h5" header="Master Models">
					<SimplePieChart data={aggregateMasterData} palette="status" />
				</RMCard>
				<RMCard variant="h5" header="Model Definitions">
					<SimplePieChart data={aggregateDefinitionData} palette="status" />
				</RMCard>
				<RMCard variant="h5" header="Scenarios">
					<SimplePieChart data={aggregateScenarioData} palette="status" />
				</RMCard>
			</div>
			<div className="app-cards-columns-wrapper">
				<RMCard variant="h5" header="Scenarios">
					<SimplePieChart data={reportsData}  />
				</RMCard>
			</div>
		</div>
	);
}

export default EasyECLDashboardView;