var parseServerObject = function(result){


    const _aggrmaster = [
        {title: 'Approved(' + result.masterapprovecount+')', value: result.masterapprovecount},
        {title: 'Pending('+result.masterpendingcount+')', value: result.masterpendingcount},
        {title: 'Draft('+result.masterdraftcount+')', value: result.masterdraftcount},
        {title: 'Rejected('+result.masterrejectcount+')', value: result.masterrejectcount}
    ],
    _aggrdefinitions=[
        {title: 'Approved('+result.definitionapprovecount+')', value: result.definitionapprovecount},
        {title: 'Pending('+result.definitionpendingcount+')', value: result.definitionpendingcount},
        {title: 'Draft('+result.definitiondraftcount+')', value: result.definitiondraftcount},
        {title: 'Rejected('+result.definitionrejectcount+')', value: result.definitionrejectcount}
    ],
    _aggrscenario=[
        {title: 'Approved('+result.scenarioapprovecount+')', value: result.scenarioapprovecount},
        {title: 'Pending('+result.scenariopendingcount+')', value: result.scenariopendingcount},
        {title: 'Draft('+result.scenariodraftcount+')', value: result.scenariodraftcount},
        {title: 'Rejected('+result.scenariorejectcount+')', value: result.scenariorejectcount}
    ];

    
    if(result.scenario_completed){
        var _repr = result.scenario_completed.map(function(_model, idx){
                    return {
                        id: idx,
                        title: String(_model.scenario_definition_name)+' ('+Number(_model.scenario_executions)+')', 
                        value:  Number(_model.scenario_executions),
                    }
    
        });
    }

    return {
        aggregateMasterData: _aggrmaster,
        aggregateDefinitionData: _aggrdefinitions,
        aggregateScenarioData: _aggrscenario,
        reportsData: _repr
    }
}


export {parseServerObject}