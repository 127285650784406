import React from 'react';
import './cards.css';
import ecllogo from './resources/ecllogo.png';
import irienelogo from './resources/irienlogo.png';
import ratinglogo from './resources/ratinglogo.png';
import irrbblogo from './resources/irrbblogo.png';
import frtblogo from './resources/frtblogo.png';
import baselcaplogo from './resources/baselcaplogo.png';

/* 4. IRRBB - Interest Rate Risk in Banking Book
5. FRTB - Fundamental Review of Trading Book
6. Basel_Cap - Basel Capital Adequacy Estimation System */

import { AppNavContext } from '../../ApplicationNavProvider/AppNavProvider';
import { SuiteCard } from '../../../Common/ApplicationComponents/SuiteCard/SuiteCard';
import { LoginContext } from '../../../../Context/LoginContext/LoginContext';
import { notificationObj } from '../../NotificationHandler';


var productName={
    'iriene':'irien',
    'ecl': 'easyecl',
    'rating': 'easyrating'
}


const CommonApplicationCards = function(){

    const {setCurrentSuite} = React.useContext(AppNavContext);
    const {liscenceInfo=[]} = React.useContext(LoginContext);

    const testForSuite = React.useCallback(function(_name){
        var name = productName[_name];
        if(name){
            var lis = liscenceInfo.find(function(e){
                return e.verificationStatus==='Active' && e.productKey===name;
            });
            if(lis){
                setCurrentSuite(_name);
                return;
            }
        }
        notificationObj.showNotification('error','License key not found or invalid. Please enter a valid license key.');
    },[liscenceInfo, setCurrentSuite]);

    return (<div className="full-container ecl-pad-30 ecl-app-select">
                <div className='ecl-ov-auto full-container'>
                    <div className="ecl-cards-container">
                       
                        <SuiteCard onClick={()=>testForSuite('iriene')} logoSrc={irienelogo} />
                        <SuiteCard onClick={()=>testForSuite('ecl')} logoSrc={ecllogo} />
                        <SuiteCard onClick={()=>testForSuite('rating')} logoSrc={ratinglogo} />
                    
                        <SuiteCard  logoSrc={irrbblogo} />
                        <SuiteCard  logoSrc={frtblogo} />
                        <SuiteCard  logoSrc={baselcaplogo} />

                    </div>
                </div>
            </div>);
}

export {CommonApplicationCards}