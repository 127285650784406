import React from 'react';
import CookieHandler from '../../Widgets/Common/DataUIWrapper/CookieHandler';
import { dataUIWrapper } from '../../Widgets/Common/DataUIWrapper/dataUIWrapper';
import { StaticPage } from '../../Widgets/Global/Login/StaticPage';
import { RMCard } from '../../Widgets/Common/Components/WrappedComponents/RMCards/RMCards';
import { LoginPage } from '../../Widgets/Global/Login/LoginPage';
import APIConfig from './Config/APIConfig';
import { OTPPage } from '../../Widgets/Global/Login/OTPPage';
import { useHistory, useLocation } from 'react-router-dom';
import getUserStatus from './Config/dispatchers/getUserStatus';
import { notificationObj } from '../../Widgets/Global/NotificationHandler';
import { RMFooter } from '../../Widgets/Common/ApplicationComponents/Footer/RMFooter';
import getUserName from '../../Widgets/Common/Utils/getUserName';
import backgroundImage from './resources/dashboardimage.png';
import { AgreementPage } from '../../Widgets/Global/Login/Agreement/AgreementPage';
import getLiscenceInfo from './Config/dispatchers/getLiscenceInfo';

const axios = require('axios');

const LoginContext = React.createContext({}); 


const ComponentRenderer = {
  'static': StaticPage,
  'init-login': LoginPage,
  'init-otp': OTPPage,
  'agreement': AgreementPage,
  'logging-out': StaticPage
}

var productName={
    irien:'IRiEn',
    easyecl:'Easy ECL',
    easyrating:'Easy Rating'
}

const cardHeaders = {
    'agreement': 'Accept License Agreement'
}

const LoginContainer = function(props){

    const {loginStatus, tokenKey, setLiscenceInfo, setLoginStatus, children,dispatchRequest, setUserInfo} = props;

    const history = useHistory();
    const location = useLocation();

    const Component = React.useMemo(function(){
        return ComponentRenderer[loginStatus];
    },[loginStatus]);

    React.useEffect(function(){
       if(loginStatus==='logged-in'){
            dispatchRequest('getUserInfo').then(function(e){
                var userInfo = e.data.message
                setUserInfo(userInfo);
            }).catch(function(){
                notificationObj.showNotification('error','Unable to Update User Info.');
            });

            dispatchRequest('getLiscenceInfo').then(function(e){
                var result = e.data;
                if(result.message){
                    var liscences = Object.keys(result.message).map(function(_product){
                        var obj = result.message[_product];
        
                        return {
                            productKey: _product,
                            name: productName[_product] || _product,
                            verificationStatus: obj.status?'Active':'Expired',
                            days: obj.days + ' - Days Remaining'
                        }
                    });
                }
                setLiscenceInfo(liscences);
            }).catch(function(){
                notificationObj.showNotification('error','Unable to Fetch Liscense Info.');
            });
       }
    },[loginStatus]);

    React.useEffect(function(){

        var timeoutId;
        if(!tokenKey && loginStatus==='static'){
            timeoutId = setTimeout(function(){
                setLoginStatus('init-login');
            },1000)
        }else if(tokenKey && loginStatus==='static'){
            
            timeoutId = setTimeout(function(){
                dispatchRequest('getUserInfo').then(function(e){
                    setLoginStatus('agreement');
                    var userInfo = e.data.message
                    setUserInfo(userInfo);
                    notificationObj.showNotification('success','User Verification Successful. Welcome: '+getUserName(userInfo)+'.');
                }).catch(function(){
                    CookieHandler.resetCookie();
                    setLoginStatus('init-login');
                });
            },10);
        }

        return function(){
            clearTimeout(timeoutId)
        }
    },[tokenKey, loginStatus, dispatchRequest, setUserInfo]);

    React.useEffect(function(){
        if(loginStatus === 'init-login' && location.pathname!=='/login'){
            history.push('/login');
        }else if(loginStatus==='logged-in' && location.pathname==='/login'){
            history.push('/');
        }
    },[location, loginStatus])

    return React.useMemo(function(){
        if(Component){
            return (<div className="app-flex">
                         <div 
                            className="full-container login-container" 
                            style={{ backgroundImage: `url(${backgroundImage})` }}
                        >
                             <RMCard header={cardHeaders[loginStatus]} className={"login-form-wrapper login-state-"+loginStatus }>
                                 <Component/>
                             </RMCard>
                         </div>
                         <RMFooter/>
                     </div>)
         }else{
            return (children)
         }
    },[Component, children, loginStatus]);

}

const DataLoginContainer = dataUIWrapper(LoginContainer,{
                            skipError: true,
                            skipWrapping: true,
                            dispatchers: {
                                getUserInfo: getUserStatus,
                                getLiscenceInfo: getLiscenceInfo
                            }
                        });

var defaultKey = CookieHandler.get('key');

var LoginWrapper = function(props){
 
    const {children} = props;

    const [loginStatus, setLoginStatus] = React.useState('static');
    const [agreementAccepted, setAgreementAccepted] = React.useState();
    const [key, setCurrentKey] = React.useState(defaultKey);
    const [tempKey, setTempKey] = React.useState(defaultKey);
    const [userInfo, setUserInfo] = React.useState();
    const [liscenceInfo, setLiscenceInfo] = React.useState();
    
    const performBaseLogin = React.useCallback(function(key){
        setTempKey(key);
        setLoginStatus('init-otp')
    },[]);

    const performFinalLogin = React.useCallback(function(){
        CookieHandler.set('key', tempKey);
        setCurrentKey(tempKey);
        setLoginStatus('agreement');
    },[tempKey]);

    const handleLogin = React.useCallback(function(obj, callback){
        axios.post(APIConfig.login, obj)
		.then(function (response) {
			if(response.data.key){
				performBaseLogin(response.data.key);
			}
		})		
		.catch(function (error) {
			if(error && error.response && error.response.data && error.response.data.password){
                callback(error.response.data.password);
			}else if(error && error.response && error.response.data &&  error.response.data.non_field_errors){
                callback(error.response.data.non_field_errors);
			}else{
				callback('Unable To Login')
			}
		});
    },[performBaseLogin]);

    const handleOTPCheck = React.useCallback(function(obj, callback){
        axios.post(APIConfig.otpverify, 
                    obj,
                    {
                        headers: {
                            'Authorization': 'Bearer '+ tempKey,
                            'Content-Type': 'application/json'
                        }
                    }
        ).then(function (response) {
			debugger;
            performFinalLogin();
		})		
		.catch(function (error) {
			callback("TOTP token Invalid")
		});
    },[performFinalLogin, tempKey]);

    const performLogout = React.useCallback(function(){
        setLoginStatus('logging-out');
        axios.post(APIConfig.logout,
            null,
            {
                headers: {
                    'Authorization': 'Bearer '+ key
                }
            }
        ).then(function () {
            CookieHandler.resetCookie();
            setTimeout(function(){
                setTempKey(null);
                setCurrentKey(null);
                setLoginStatus('static');
            });            
        })		
        .catch(function (error) {
           notificationObj.showNotification('error','Unable to Logout');
           CookieHandler.resetCookie();
        });
        
    },[key]);

    const acceptAgreement = React.useCallback(function(){
        setLoginStatus('logged-in')
    },[]) 

    return (<LoginContext.Provider
                value={{
                    loginStatus: loginStatus,
                    tokenKey: key,
                    handleLogin: handleLogin,
                    handleOTPCheck: handleOTPCheck,
                    userInfo: userInfo,
                    performLogout: performLogout,
                    acceptAgreement: acceptAgreement,
                    liscenceInfo:liscenceInfo, 
                }}
            >
                <DataLoginContainer
                    loginStatus={loginStatus}
                    tokenKey={key}
                    setCurrentKey={setCurrentKey}
                    setLoginStatus={setLoginStatus}
                    setUserInfo={setUserInfo}
                    agreementAccepted={agreementAccepted}
                    setAgreementAccepted={setAgreementAccepted}
                    setLiscenceInfo={setLiscenceInfo}
                >
                    {children}
                </DataLoginContainer>
            </LoginContext.Provider>)
}

export default LoginWrapper;

export {LoginContext}