import React from 'react';
import { newAgeFormConfig } from "../Configs/NewAgeFormConfig";
import TextField from '@material-ui/core/TextField';

const FormMultiLineInput = function(props){

    const {id, value='', error, onChange, ...extProps} = props;

    const _handleChange = React.useCallback(function(event){
        var _value = event.target.value;
        
        onChange({
            value: _value
        });

    },[onChange]);


    return (<TextField
                id={id}
                value={value}
                multiline
                onChange={_handleChange}
                {...extProps}
                variant="filled"
                error={!!error}
                helperText={error}
            ></TextField>);


}


newAgeFormConfig.pluginComponent('FormMultiLineInput', FormMultiLineInput);