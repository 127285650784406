var configS4 = {
	config: [
		[
			{
				objectKey: 'impact',
				id: 'impact',
				widget: 'FormDropDown',
				text: 'Risk Impact',				
				data: [ ]
			},
			{
				objectKey: 'likelihood',
				id: 'likelihood',
				widget: 'FormDropDown',
				text: 'Likelihood',				
				data: [ ]
			},

			{
				objectKey: 'severity',
				id: 'severity',
				widget: 'FormDropDown',
				text: 'Severity',				
				data: [ ]
			}
		],[
			{
				objectKey: 'inherent_risk',
				widget: 'FormTextInput',
				text: 'Inherent Risk Score',
				widgetProps:{
					disabled: true,
					readonly: true,
					className: 'ecl-spotlighted'
				}
			},
			{
				objectKey: 'inherent_risk_factor',
				widget: 'FormTextInput',
				text: 'Inherent Risk Level',
				widgetProps:{
					disabled: true,
					readonly: true,
					className: 'ecl-spotlighted'
				}

			},
			// {
			// 	objectKey: 'inherent_risk_action',
			// 	widget: 'FormTextInput',
			// 	text: 'Inherent Risk Action',
			// 	widgetProps:{
			// 		disabled: true,
			// 		readonly: true,
			// 		className: 'ecl-spotlighted'
			// 	}

			// }

		]

	]
}


export {
	configS4
}