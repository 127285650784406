import React from 'react';
import { dataUIWrapper } from '../../../../../../Common/DataUIWrapper/dataUIWrapper.js';
import { NewAgeFormBuilder } from '../../../../../../Common/ApplicationFormBuilder/FormBuilder/NewAgeFormBuilder.js';
import { useHistory } from 'react-router-dom';
import { NewAgeFormConfig } from '../../formJSON/newAgeConfig.js';
import { PageHeader } from '../../../../../../Common/Components/PageHeader/PageHeader.js';
import { notificationObj } from '../../../../../../Global/NotificationHandler.js';
import MasterRecord from '../../Utils/fetchers/MasterRecord.js';
import ApproveDispatcher from '../../Utils/dispatchers/ApproveDispatcher.js';


const DSSApprove = function(props){

	const [value, setValue] = React.useState({});
	const [componentExts, setComponentExts] = React.useState({
		Strategies: {
			strategiesList:[],
			risksList: []
		}
	});
	const history = useHistory();

	const { dispatchRequest, serverData} = props;

	const _handleApprove = React.useCallback(function(_value){
		dispatchRequest('approve', {data: {
			"approved_user_comment": _value.approved_user_comment,
			"approval_status": _value.approval_status,
			"conf_name": _value.conf_name
		}} ).then(function(){
			notificationObj.showNotification('success', 'Decision Support System Approval Status Updated Successfully'); 
			history.push('/irien/decision-support-system/approve-list/');
		});
	},[dispatchRequest]);


	return (<PageHeader
				title={'Edit Decision Support System'}
				previousLinks={[
					{
						href: "/irien/decision-support-system/approve-list/",
						text: 'Decision Support System (Approval List)'
					}
				]}
				currentPage={'Approve'}
			>
				<NewAgeFormBuilder 
					mode="approve"
					incomingData={serverData}
					config={NewAgeFormConfig} 
					value={value}
					onChange={setValue}
					dispatchRequest={props.dispatchRequest}
					onSave={_handleApprove}
					componentExts={componentExts}
					onUpdateComponentExts={setComponentExts}
				/>
			</PageHeader>);
}

export default dataUIWrapper(DSSApprove, {
								dispatchers:{
									approve: ApproveDispatcher
								},
								featchers: [
									MasterRecord
								]
							});