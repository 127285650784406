import appNavConfig from "../../Global/ApplicationNavProvider/Config/AppNavConfig";

var navData = [{
    header: 'EasyECL',
    items: [{
        id: 'panel-ecl-Dashboard',
        title: 'Dashboard',
        link: '/ecl/dashboard',
    },		
    {	id: 'panel-modelling',
        title: 'Modelling',
        items: [{
                id: 'panel-modal-master',
                title: 'Model Master',
                items: [
                    {
                        link: '/ecl/model-master/list',
                        title: 'Models',
                        plusIconLink: '/ecl/model-master/create/'
                    },
                    {
                        link: '/ecl/model-master/approve-list',
                        title: 'Approve'
                    }
                ]
                
            },
            {
                id: 'panel-modal-definition',
                title: 'Model Definition',
                items: [
                    {
                        link: '/ecl/model-definition/list',
                        title: 'Defintions',
                        plusIconLink: '/ecl/model-definition/create/'
                    },
                    {
                        link: '/ecl/model-definition/approve-list',
                        title: 'Approve'
                    }
                ]
                
            },
            {
                id: 'panel-scenerio-definition',
                title: 'Scenario Definition',
                items: [
                    {
                        link: '/ecl/scenario-definition/list',
                        title: 'Scenarios',
                        plusIconLink: '/ecl/scenario-definition/create/'
                    },
                    {
                        link: '/ecl/scenario-definition/approve-list',
                        title: 'Approve'
                    }
                ]
                
            }]
    },
    {
        id: 'panel-ecl-computation',
        title: 'ECL Computation',
        link: '/ecl/computations/list/',
        plusIconLink: '/ecl/computations/create/'
    },			
    {
        id: 'panel-reporting',
        title: 'Reports',
        items: [
            {
                link: '/ecl/model-report',
                title: 'Model Wise ECL'
            },
        ]
        
    },
    {
        id: 'panel-audit-trail',
        title: 'Audit Trail',
        link:'/ecl/audit',			
    }]
}];

var pagesRegex = [/\/ecl\/*/];

var homePage = '/ecl/dashboard';

appNavConfig.plugin('ecl',{
    nav: navData,
    regex: pagesRegex,
    homePage: homePage
});
