import React from 'react';
import rmIcon from './resources/rmicon.png';
import { LoginContext } from '../../../Context/LoginContext/LoginContext';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const LoginPage = function(){

    const {handleLogin} = React.useContext(LoginContext);
    const [id, setId] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [disabled, setDisabled] = React.useState(false);
    const [error, setError] = React.useState();

    const _handleOkClick = React.useCallback(function(){
        setDisabled(true)
        handleLogin({
            username: id,
            password: password
        }, function(err){
            setError(err);
            setDisabled(false)
        })
    },[handleLogin, id, password]);

    const _handleKeyPress = React.useCallback(function(e){
        if(e.keyCode === 13){
            _handleOkClick();
        }
    },[_handleOkClick])

    const _className ="login-spacer "+(disabled?'app-form-disabled':'');

    return (<div className="app-flex app-flex-center" onKeyDown={_handleKeyPress}>
                <div className="login-spacer">
                    <img className="login-logo" src={rmIcon} alt="Logo" />
                </div>
                <div className={_className}>
                    <TextField id="outlined-basic" label="Username" variant="outlined" value={id} onChange={(e)=>{  setId(e.target.value) }}/>
                </div>
                <div className={_className}>
                    <TextField id="outlined-basic" label="Password" variant="outlined" type="password" value={password} onChange={(e)=>{ setPassword(e.target.value) }}/>
                </div>
                <div className={_className}>
                    <Button variant="contained" onClick={_handleOkClick}>
                        Login
                    </Button>
                </div>
                { ( error )  && <div className="login-error-div">{error}</div>}
            </div>)
}

export {LoginPage}