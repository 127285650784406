export default [
    [
        {
			objectKey: 'net_risk',
			widget: 'FormTextInput',
			text: 'Net Risk',
			editableModes: [],
            widgetProps: {
                highlighted: true
            }
		},
        {
			objectKey: 'mitigation_priority',
			widget: 'FormTextInput',
			text: 'Mitigation Priority',
			editableModes: [],
            widgetProps: {
                highlighted: true
            }
		},
    ],
    [
        {
			objectKey: 'mitigation_comments',
			widget: 'FormMultiLineInput',
			text: 'Comments',
			validation: [
				{
					type: 'required',
					message: 'Enter Comments'
				}
			],
		}
    ]
]