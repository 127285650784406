import React from 'react';
import APIConfig from '../../../APIConfig.js';
import { DataGrid } from '@material-ui/data-grid';
import Typography from '@material-ui/core/Typography';
import {FormBuilder} from '../../Global/FormBuilder/FormBuilderNew.js';
import {
    Chart,
    Legend,
      ArgumentAxis,
    ValueAxis,
    BarSeries,
    Tooltip,
  } from '@devexpress/dx-react-chart-material-ui';
 
import { Stack, Animation, EventTracker } from '@devexpress/dx-react-chart';
import Card from '@material-ui/core/Card';
import {RiskConf} from './config/RiskReportConf';
import RiskRegisterInfo from './reports/RiskRegisterInfo.js';
import RiskView from './reports/RiskView.js';
import { dataUIWrapper } from '../../Common/DataUIWrapper/dataUIWrapper.js';

const RiskDashboard = function(props){
    const [formData, setFormData] = React.useState({});
    const [apiConf, setApiRef] = React.useState({});
    const [configurationOpenData, setConfigurationOpenData] = React.useState(null);
    const [configurationCloseData, setConfigurationCloseData] = React.useState(null);
    const [configurationRetiredData, setConfigurationRetiredData] = React.useState(null);
    // const [divisionData, setDivisionData] = React.useState(null);
    const [divisionStatusData, setDivisionStatusData] = React.useState(null);
    const [riskRegistNames ,setRiskRegistersNames] = React.useState(null);
    const [selectedReport , setSelectedReport] = React.useState(null);
    var _configurationOpenData = [];
    var _configurationCloseData = [];
    var _configurationRetiredData = [];

    const handleScreenUpdate = function(key, value){
        setSelectedReport(value);
        setConfigurationOpenData(null);
        setConfigurationCloseData(null);
        setConfigurationRetiredData(null);
        setDivisionStatusData(null);
        setRiskRegistersNames(null);
        if(value === 'Configuration Wise Report'){
            

            props.dispatchRequest('getConfigurationwiseReport').then(function(data){
                // var _configurationData = [];
                data.data.forEach(function(_reports){
                    _reports.forEach(function(_r,index){
                        // if(_r[4]==="Open"){
                        //     _configurationOpenData.push({
                        //         id:index,
                        //         conf_name:_r[0],
                        //         division:_r[1],
                        //         department:_r[2],
                        //         risk_category:_r[7],
                        //         risk_factor:_r[5]
                        //         // value: Number(_r[3])
                        //     });
                        // }
                        if(_r[4]==="Ongoing"){
                            _configurationOpenData.push({
                                id:index,
                                conf_name:_r[0],
                                division:_r[1],
                                department:_r[2],
                                risk_category:_r[7],
                                risk_factor:_r[5]
                                // value: Number(_r[3])
                            });
                        }
                        console.log(_configurationOpenData);
                        if(_r[4]==="Closed"){
                            _configurationCloseData.push({
                                id:index,
                                conf_name:_r[0],
                                division:_r[1],
                                department:_r[2],
                                risk_category:_r[7],
                                risk_factor:_r[5]
                                // value: Number(_r[3])
                            });
                        }
                        if(_r[4]==="Retired"){
                            _configurationRetiredData.push({
                                id:index,
                                conf_name:_r[0],
                                division:_r[1],
                                department:_r[2],
                                risk_category:_r[7],
                                risk_factor:_r[5]
                                // value: Number(_r[3])
                            });
                        }
                    })
                });
                 
                setConfigurationOpenData(_configurationOpenData);
                setConfigurationCloseData(_configurationCloseData);
                setConfigurationRetiredData(_configurationRetiredData);
                console.log("****",_configurationOpenData);
            });

        }else if (value==="Division Risk Status"){
            
            setConfigurationOpenData(null);
            setRiskRegistersNames(null);
            // setDivisionData(null);

            props.dispatchRequest('getDivisionWIseStatusReport').then(function(data){
                var _data = [], dataKeys={};
                
                Object.keys(data.data).forEach(function(_key){
                    Object.keys(data.data[_key]).forEach(function(_division){
                        if(!dataKeys[_division]){
                            dataKeys[_division]={}
                        }
                        dataKeys[_division][_key]=data.data[_key][_division];
                    });
                });
                _data = Object.keys(dataKeys).map(function(_division){
                    return {
                        ...dataKeys[_division],
                        text: _division
                    }
                });
                
                 

                setDivisionStatusData(_data);
                console.log(data);
            });
        }else{
            
            setConfigurationOpenData(null);
            setDivisionStatusData(null);
          
        }
        
    }

    var Opencolumns =  [
			  { field: "conf_name", headerName: 'Configuration Name', width: 350, headerClassName: 'confreportopen-header',},
              { field: "risk_factor", headerName: 'Risk Factor', width: 350,headerClassName: 'confreportopen-header',
                    renderCell: function(params){
                        if(params.row.risk_factor === 'Medium'){
                            return (<div className="ecl-status-pending ecl-status-div">Medium</div>)
                        }
                        if(params.row.risk_factor === 'High'){
                            return (<div className="ecl-status-rejected ecl-status-div">High</div>)
                        }				
                        if(params.row.risk_factor === 'Low'){
                            return (<div className="ecl-status-approved ecl-status-div">Low</div>)
                        }
                    }
            },
            { field: "division", headerName: 'Division', width: 350,headerClassName: 'confreportopen-header'},
            { field: "department", headerName: 'Department', width: 350,headerClassName: 'confreportopen-header'},
            { field: "risk_category", headerName: 'Risk Category', width: 350,headerClassName: 'confreportopen-header'},
              
    ];

    var Closedcolumns =  [
        { field: "conf_name", headerName: 'Configuration Name', width: 350, headerClassName: 'confreportclose-header',},
        { field: "risk_factor", headerName: 'Risk Factor', width: 350,headerClassName: 'confreportclose-header',
                renderCell: function(params){
                    if(params.row.risk_factor === 'Medium'){
                        return (<div className="ecl-status-pending ecl-status-div">Medium</div>)
                    }
                    if(params.row.risk_factor === 'High'){
                        return (<div className="ecl-status-rejected ecl-status-div">High</div>)
                    }				
                    if(params.row.risk_factor === 'Low'){
                        return (<div className="ecl-status-approved ecl-status-div">Low</div>)
                    }
                }
        },
        { field: "division", headerName: 'Division', width: 350,headerClassName: 'confreportclose-header'},
        { field: "department", headerName: 'Department', width: 350,headerClassName: 'confreportclose-header'},
        { field: "risk_category", headerName: 'Risk Category', width: 350,headerClassName: 'confreportclose-header'},
       
    ];

    var Retiredcolumns =  [
        { field: "conf_name", headerName: 'Configuration Name', width: 350, headerClassName: 'confreportretire-header',},
        { field: "risk_factor", headerName: 'Risk Factor', width: 350,headerClassName: 'confreportretire-header',
                renderCell: function(params){
                    if(params.row.risk_factor === 'Medium'){
                        return (<div className="ecl-status-pending ecl-status-div">Medium</div>)
                    }
                    if(params.row.risk_factor === 'High'){
                        return (<div className="ecl-status-rejected ecl-status-div">High</div>)
                    }				
                    if(params.row.risk_factor === 'Low'){
                        return (<div className="ecl-status-approved ecl-status-div">Low</div>)
                    }
                }
        },
        { field: "division", headerName: 'Division', width: 350,headerClassName: 'confreportretire-header'},
        { field: "department", headerName: 'Department', width: 350,headerClassName: 'confreportretire-header'},
        { field: "risk_category", headerName: 'Risk Category', width: 350,headerClassName: 'confreportretire-header'},
       
];
   

    return (<div className="right-section-contents">
           
            <Typography variant="h4" className="app-header-title  ecl-header-title">
                Reports
            </Typography>
            <Card className="main-ui-card">
                {/* <Typography variant="h6" className="app-sub-title ecl-header-title">
                    Reports
                </Typography> */}
                <FormBuilder apiConf={apiConf} {...RiskConf}  data = { formData } onUpdate={handleScreenUpdate}  />
            </Card>

            {configurationOpenData && (<Card className="main-ui-card">
                <Typography variant="h6" className="app-sub-title ecl-header-title report-heading">
                    Configuration Wise Risk Rating Report (Risk Status='Ongoing')
                </Typography>
                <div className="ecl-reportlist-grid-container">
					 <DataGrid rows={(configurationOpenData || [])} columns={Opencolumns} />
				</div>
            </Card>)}

            {configurationCloseData && (<Card className="main-ui-card">
                <Typography variant="h6" className="app-sub-title ecl-header-title report-heading">
                    Configuration Wise Risk Rating Report (Risk Status='Closed')
                </Typography>
                <div className="ecl-reportlist-grid-container">
					 <DataGrid rows={(configurationCloseData || [])} columns={Closedcolumns} />
				</div>
            </Card>)}

            {configurationRetiredData && (<Card className="main-ui-card">
                <Typography variant="h6" className="app-sub-title ecl-header-title report-heading">
                    Configuration Wise Risk Rating Report (Risk Status='Retired')
                </Typography>
                <div className="ecl-reportlist-grid-container">
					 <DataGrid rows={(configurationRetiredData || [])} columns={Retiredcolumns} />
				</div>
            </Card>)}
            {/* {configurationOpenData && (<Card className="main-ui-card">
                <Typography variant="h6" className="app-sub-title ecl-header-title report-heading">
                    Configuration Wise Risk Rating Report (Risk Status='Open')
                </Typography>
                <div className="ecl-reportlist-grid-container">
					 <DataGrid rows={(configurationOpenData || [])} columns={Opencolumns} />
				</div>
            </Card>)} */}
           
            {/* {divisionData && (<Card className="main-ui-card">
                <Typography variant="h6" className="app-sub-title ecl-header-title">
                 Average Risk Per Division Report (Risk Status='Open')
                </Typography>
                <Chart
                    data={divisionData}
                >
                    <ValueAxis />
                    <ArgumentAxis />
                     <BarSeries
                        valueField='value'
                        argumentField='name'
                    />    
                    <EventTracker />
                    <Animation />
                     <Tooltip />
                </Chart>
            </Card>)} */}

            {divisionStatusData && (<Card className="main-ui-card">
                <Typography variant="h6" className="app-sub-title ecl-header-title report-heading">
                 Division Wise Risk Status Report
                </Typography>
                <Chart
                    data={divisionStatusData}
                    rotated
                >
                    <ValueAxis />
                    <ArgumentAxis />
                    <Legend/>
                    <BarSeries
                        name="Closed"
                        valueField='Closed'
                        argumentField='text'
                    />
                    <BarSeries
                        name="Ongoing"
                        valueField='Open'
                        argumentField='text'
                    />
                    <BarSeries
                        name="Retired"
                        valueField='Retired'
                        argumentField='text'
                    />  
                    <Stack/>
                    <EventTracker />
                    <Animation />
                     <Tooltip />
                </Chart>
            </Card>)}

            {(selectedReport==='Risk Configurations Info')&&(<RiskRegisterInfo />)}
            {(selectedReport==='Risk View')&&(<RiskView />)}
            
        </div>) ;

}


var createDispatchers = {
    getDivisionWiseReport: {
        url: APIConfig.erm.getDivisionWiseReport,
        method: 'get'
    },
    getConfigurationwiseReport: {
        url: APIConfig.erm.getConfigurationwiseReport,
        method: 'get'
    },
    getDivisionWIseStatusReport: {
        url: APIConfig.erm.getDivisionWIseStatusReport,
        method: 'get'
    },
    getReportRiskRegistersNames:{
        url: APIConfig.erm.getReportRiskRegistersNames,
        method: 'get',
        key: 'riskRegistersNames'
    }
};

var RiskDashboardModule = dataUIWrapper(RiskDashboard);

const ExportModule = function(){
    return (<RiskDashboardModule dispatcher={createDispatchers} />)
}

export default ExportModule;