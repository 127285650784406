import React from 'react';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';


const RMCard = function(props){

    const {header, children, variant="h6", className=''} = props

    return (<Card className={"app-form-card "+className}>
                {header && (<Typography variant={variant} className="app-sub-title ecl-header-title report-heading">
                    {header}
                </Typography>)}
                {children}
            </Card>)
}

export {RMCard}
