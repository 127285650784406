import React from 'react';
import FileListGrid from '../../Components/FileListComponent/FileListGrid';
import { newAgeFormConfig } from '../Configs/NewAgeFormConfig';

const FormDocument = function(props){

    const {
                moduleName='', 
                modelName='',
                downloadURL= window.riskMac.APIConfig.downloadFile,
                deleteURL = window.riskMac.APIConfig.deleteFile,
                fetchURL = window.riskMac.APIConfig.getFiles,
                viewOnlyMode,
            } = props;


    const fetchers = React.useMemo(function(){

        if(!(moduleName && modelName)){
            return []
        }

        return [{
                    url: fetchURL,
                    method: 'get',
                    key: 'documents',
                    preprocessor: function(obj){
                        obj.url = obj.url + modelName +'/'+moduleName+'/';
                        return obj;
                    }
                }]
    },[moduleName, modelName, fetchURL])


    const dispatcher = React.useMemo(function(){
        return {
                download: {
                    method: 'put',
                    url: downloadURL
                },
                delete: {
                    method: 'put',
                    url: deleteURL
                },
            }
    },[downloadURL, deleteURL])

    if(!(moduleName && modelName)){
        return null;
    }

    return (<FileListGrid 
                dispatcher={dispatcher}
                fetchingData={fetchers}
                deletable={!viewOnlyMode}
                {...props}
                downloadURL={downloadURL}
                deleteURL={deleteURL}
            />)

}


newAgeFormConfig.pluginComponent('FormDocument', FormDocument);