import missionGoalsConfig from "./missionGoalsConfig";
import missionVsStrategy from "./missionVsStrategy";
import rarocConfig from "./rarocConfig";
import strategyConf from "./strategyConf";
import stratrgyVsVOR from "./stratrgyVsVOR";
import {getHistoryObjectPerMode} from "../../../../../Common/FormConfig/ApprovalHistoryObject.js";

const NewAgeFormConfig = {
	layoutItems: [
		{
			stepperName: 'Mission Goals',
			itemsLayout : [{
					items : [
						{
							header: 'Mission Weights',
							componentConfig: missionGoalsConfig
						}
					
					]
				},
				...getHistoryObjectPerMode(['edit'])
			],
            stepValidation: function(value){

            }

		},
		{
			stepperName: 'Strategy Configurations',
			itemsLayout : [{
				items : [
					{
						header: 'Strategy Configuration',
						componentConfig: strategyConf
					}
				
				]
			}],
            stepValidation: function(value){

            }

		},
		{
			stepperName: 'Mission Goals v/s Strategy Statement',
			itemsLayout : [{
				items : [
					{
						header: 'Mission Goals vs Strategy Statement',
						componentConfig: missionVsStrategy
					}
				
				]
			}],
            stepValidation: function(value){

            }

		},
		{
			stepperName: 'Strategy Statement v/s Value of Return',
			itemsLayout : [{
				items : [
					{
						header: 'Strategy Statement vs Value of Return',
						componentConfig: stratrgyVsVOR
					}
				
				]
			}],
            stepValidation: function(value){

            }

		},
		{
			stepperName: 'RAROC',
			itemsLayout : [	{
								items : [
									{
										header: 'Results for Risk Adjusted Return on Capital',
										componentConfig: rarocConfig
									}
								
								]
							},
							{
								viewModes: ['view','approve'],
								items: [
									{
										header: 'Uploaded Documents',
										componentConfig : [
											[
												{
													editableModes: [],
													objectKey: 'form_documents',
													widget: 'FormDocument',
													widgetProps: {
														moduleName: 'dss',
														columns: [
															{
																field: 'iteminfo',
																headerName: 'Description',
																width: 700
															}
														]
													}
												}
											]
										]
									}
								]

							},
							...getHistoryObjectPerMode(['view','approve'])
			],
            stepValidation: function(value){

            }

		},
	],
	finalSaveParsing: function(value, options){
		
		var _value = window.structuredClone(value);

		var _risks ={};
		options.componentExts.Strategies.risksList.forEach(function(_rk){
			_risks[_rk.category_name] ={
				"RiskCategory": _rk.risk_category,
				"RiskSubCategory": _rk.risk_subcategory
			}
		});

		var strategies = [];

		_value.Strategies.forEach(function(_s){
			var obj = {
				Strategy: _s.statement
			};

			delete _s.statement;

			obj.Risks = Object.keys(_s).map(function(key){
				var value = _s[key];
				return _risks[value];
			});
			strategies.push(obj);

		})

		_value.Strategies=strategies;

		_value.RASObjective = _value.strategy_vor.map(function(_strateg){
			
			var obj ={
				Strategy: _strateg.statement,
				ROI: Number(_strateg.roi),
				goals: _value.RASObjective.map(function(_obj){
					return {
						missiongoal: _obj.missiongoal,
						value: Number(_obj[_strateg.statement])
					}
				})
			};
			return obj;
		});
		
		delete _value.strategy_vor;

		return _value;
	},
	defaultValue: {
		"send_for_approval": false
	}

}


export {NewAgeFormConfig}