import React from 'react';

const List = function(props){

    const renderedItem = React.useMemo(function(){
        
            if(props.data){
                return props.data.map(function(e, idx){
                    var value = e;
                    if(props.dataText){
                        value=value[props.dataText];
                    }
                    return (<div className="erm-list-item"><span className="report-heading list-index">{idx+1} - </span>{value}</div>)
                });
            }
            return null;
    },[props]);


    return (<div className="erm-list">
                {renderedItem}
            </div>)
};

export default List;