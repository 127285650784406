import React from 'react';
import Switch from '@material-ui/core/Switch';
import { newAgeFormConfig } from '../Configs/NewAgeFormConfig';

const FormSwitch = function(props){
    
    const {id, value=false, onChange, ...extProps} = props;

    const _handleChange = React.useCallback(function(event){
        
        onChange({
            value: !value
        });

    },[onChange, value]);

    return (
		<Switch
			id={(id)}
			checked = {value}
			onChange = {_handleChange}
			{...extProps}
		></Switch>
	);
}



newAgeFormConfig.pluginComponent('FormSwitch', FormSwitch);