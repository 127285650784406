import { dateValueParser } from "../../../../../../Common/ApplicationFormBuilder/Parsers/dateValueParser";
import { downloadText } from "../../../../../../Common/Utils/downloadText";

export default function(data, fileName){

	var _csvData = 'Reporting Time, '+ dateValueParser(data.reporttime,{}) +'\n\n'+
	
	'Portfolio Level ECL Aggregates\n'+
	
	'Portfolio Value, 12-Month ECL, Lifetime ECL\n'+

	data.eclAggregate.portfolio_value+','+data.eclAggregate.ecl_twelve_month_amount+','+data.eclAggregate.lifetime_ecl_amount+'\n\n'+
	
	'ECL Details\n'+

	'Account ID 1,Account ID 2,12-Month ECL,Lifetime ECL,Outstanding Amount\n'+

	data.eclPortfolio.map(function(_pf){
		return _pf.account_id_1+','+_pf.account_id_2+','+_pf.ecl_twelve_month_amount+','+_pf.lifetime_ecl_amount+','+_pf.outstanding_amount
	}).join('\n');
	
	downloadText(fileName+'.csv', _csvData );
}