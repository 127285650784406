import parseHistoryData from "../../../../../Common/Utils/parseHistoryData";

var parseServerObject = function(result){

    return {
        ...result[0][0],
        variables: result[1],
        approval_history: parseHistoryData(result[0][0].history) 
    };
}


export {parseServerObject}