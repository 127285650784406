import ApprovalHistoryObject from "../../../../../Common/FormConfig/ApprovalHistoryObject";
import SendForApprovalSwitch from "../../../../../Common/FormConfig/SendForApprovalSwitch";

var MasterModalConfig = [
	[
		{
			objectKey: 'model_name',
			widget: 'FormTextInput',
			text: 'Model Name',
			editableModes: ['create'],
			validation: [
				{
					type: 'required',
					message: 'Enter model definition'
				}
			]
		}
	],
	[
		SendForApprovalSwitch
	]
]


var ModalVariableConfig = [
	[
		{
			objectKey: 'variables',
			widget: 'FormGridComponent',
			widgetProps: {
				className: 'variable-grid',
				columns: [
					{ 	
						field: 'variable_id', 
						headerName: 'Variable ID', 
						width: 300
					},
					{ 
						field: 'variable_name', 
						headerName: 'Variable Name', 
						width: 300,
						editable: {
							widget: 'FormTextInput'
						}
					},
					{ 
						field: 'variable_type', 
						headerName: 'Variable Type (Numeric - Positive/negative value, Categorical - MIN = 0, MAX = 20)', 
						minWidth: 300,
						flex: 1,
						editable: {
							widget: 'FormRadioGroup',
							widgetProps: {
								orientation: 'horizontal',
								items: [
									{
										value: 'numeric',
										text: 'Numeric'
									},
									{
										value: 'categorical',
										text: 'Categorical'										
									}
				
								]
							},
						}
					}
				]
			}
		}
	]
]

var gridData = [];

for(var i=1;i<29;++i){
	gridData.push({
		id: "V"+i,
		variable_id: "V"+i,
		variable_name: "",
		variable_type: ""
	})
}

const NewAgeFormConfig = {
	layoutItems: [
		{
			stepperName: 'Model Master',
			itemsLayout : [
				{
					items : [
						{
							header: 'Master Model',
							componentConfig: MasterModalConfig
						}
					
					]
				},
				{
					items : [
						{
							header: 'Model Variables',
							componentConfig: ModalVariableConfig
						}
					
					]
				},
				...ApprovalHistoryObject
			],
            stepValidation: function(value){
				var _variables = value.variables,
					errorMessage, count=0;
	
				_variables.forEach(function(_v){
					if(_v.variable_name || _v.variable_type){
						count++;
					}
					if(!errorMessage){
						if((_v.variable_name && !_v.variable_type)){
							errorMessage = 'Enter variable type for defined variable name';
						}else if((!_v.variable_name && _v.variable_type)){
							errorMessage = 'Enter variable name for defined variable type';						
						}
					}
				});

				if(!count){
					errorMessage = 'Atleast one Variable should be defined';
				}

				return errorMessage;
            }

		},
        
	],
	finalSaveParsing: function(_value){
		
		return _value;
	},
	defaultValue: {
		"send_for_approval": false,
		variables: gridData
	}
}


export {NewAgeFormConfig}